import React from "react";
import styled from "styled-components";
import TableTitle from "../../component/atoms/text/table/tableTitle";
import DetailPageForm from "./../../component/atoms/layout/detail/detailPageForm";
import DetailFormTitle from "./../../component/atoms/text/detail/detailFormTitle";
import DetailFormText from "./../../component/atoms/text/detail/detailFormText";
import moment from "moment";

function DeleteFaqModal({ data, setModalOpen }) {
  const faqData = data;

  return (
    <>
      <ModalWrapper>
        <ModalBox>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "30px",
            }}
          >
            <TableTitle style={{ marginBottom: "0px" }}>
              자주 묻는 질문 상세
            </TableTitle>
            <img
              style={{
                cursor: "pointer",
              }}
              src="/assets/admin/icons/ico_popup_close.svg"
              alt=""
              onClick={() => setModalOpen(false)}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              minHeight: "100px",
              overflowY: "auto",
            }}
          >
            <>
              <DetailPageForm>
                <DetailFormTitle>제목</DetailFormTitle>
                <DetailFormText>{faqData.title}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>작성일</DetailFormTitle>
                <DetailFormText>
                  {moment.utc(faqData.createAt).format("YYYY/MM/DD")}
                </DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>내용</DetailFormTitle>
                <DetailFormText>{faqData.content}</DetailFormText>
              </DetailPageForm>
            </>
          </div>
        </ModalBox>
      </ModalWrapper>
    </>
  );
}

export default DeleteFaqModal;

const ModalWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00000060;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 30;
`;

const ModalBox = styled.div.attrs((props) => {})`
  width: 1064px;
  min-height: 100px;
  padding: 50px;
  border: 1px solid #d7dbe0;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
  position: relative;
`;
