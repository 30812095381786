import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Header from "../../../component/organisms/header";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Sidebar from "../../../component/organisms/sidebar";
import DetailPageLayout from "../../../component/atoms/layout/detail/detailPageLayout";
import DetailTop from "../../../component/atoms/layout/detail/detailTop";
import GoBack from "../../../component/molecules/goBack";
import DelBtn from "../../../component/atoms/button/delBtn";
import DetailPageTitle from "../../../component/atoms/text/detail/detailPageTitle";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import DetailFormText from "../../../component/atoms/text/detail/detailFormText";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import { errorHandler } from "../../../util/errorHandler";
import CalculateApi from "../../../api/calculate.api";
import {
  formatBusinessNumber,
  formatNumberWithCommas,
} from "../../../util/formatNumber";
import moment from "moment";
import Modal from "./../../../component/templates/Modal";
import { modalMsg } from "./../../../util/modalMsg";
import { toast } from "react-toastify";
import { toastMsg } from "../../../util/toastMsg";

const PaymentRequestDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let { id } = useParams();

  useEffect(() => {
    getPaymentInfo();
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  // ------------------------------------------- //
  // ---------- 서비스 정보 가져오기 ---------- //
  // ----------------------------------------- //
  const [calculateInfo, setCalculateInfo] = useState({});

  const getPaymentInfo = async () => {
    try {
      const response = (await CalculateApi.getById(id)).data.data.content;
      setCalculateInfo(response);
    } catch (error) {
      errorHandler(error);
    }
  };

  // 삭제하기
  const [paymentDeleteModalOpen, setPaymentDeleteModalOpen] = useState(false);
  const [delPaymentId, setDelPaymentId] = useState([]);

  const deletePaymentFunc = async () => {
    try {
      let data = {
        ids: delPaymentId,
      };
      await CalculateApi.delete(data);
      toast(toastMsg.delete);
      setPaymentDeleteModalOpen(false);
      setDelPaymentId([]);
      navigate(-1);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
            <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
              <DelBtn
                onClick={() => {
                  setDelPaymentId([id]);
                  setPaymentDeleteModalOpen(true);
                }}
              />
            </div>
          </DetailTop>

          <DetailPage style={{ minHeight: "379px" }}>
            <DetailPageTitle>기본정보</DetailPageTitle>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "20px" }}
            >
              <DetailPageForm>
                <DetailFormTitle>이메일</DetailFormTitle>
                <DetailFormText>{calculateInfo.email}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>사업자등록번호</DetailFormTitle>
                <DetailFormText>
                  {formatBusinessNumber(calculateInfo.companyNumber)}
                </DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>기업명</DetailFormTitle>
                <DetailFormText>{calculateInfo.companyName}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>고객명</DetailFormTitle>
                <DetailFormText>{calculateInfo.customerName}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>주문번호</DetailFormTitle>
                <DetailFormText>{calculateInfo.paymentNumber}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>상품명</DetailFormTitle>
                <DetailFormText>{calculateInfo.merchTitle}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>상품금액</DetailFormTitle>
                <DetailFormText>
                  {formatNumberWithCommas(calculateInfo.price)}
                </DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>수수료</DetailFormTitle>
                <DetailFormText>
                  {formatNumberWithCommas(calculateInfo.charge)}
                </DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>고객 결제 금액</DetailFormTitle>
                <DetailFormText>
                  {formatNumberWithCommas(calculateInfo.price)}
                </DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>고객 결제 일시</DetailFormTitle>
                <DetailFormText>
                  {moment.utc(calculateInfo.payDate).format("YYYY/MM/DD")}
                </DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>기업 정산 일시</DetailFormTitle>
                <DetailFormText>
                  {calculateInfo.calculateDate
                    ? moment
                        .utc(calculateInfo.calculateDate)
                        .format("YYYY/MM/DD")
                    : "-"}
                </DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>정산 진행</DetailFormTitle>
                <DetailFormText>
                  {calculateInfo.calculateStatusTitle}
                </DetailFormText>
              </DetailPageForm>
            </div>
          </DetailPage>

          <DetailPage style={{ minHeight: "250px" }}>
            <DetailPageTitle>정산 계좌 정보</DetailPageTitle>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "20px" }}
            >
              <DetailPageForm>
                <DetailFormTitle>예금주</DetailFormTitle>
                <DetailFormText>{calculateInfo.accountName}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>은행명</DetailFormTitle>
                <DetailFormText>{calculateInfo.accountBank}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>계좌번호</DetailFormTitle>
                <DetailFormText>{calculateInfo.accountNumber}</DetailFormText>
              </DetailPageForm>
            </div>
          </DetailPage>
        </DetailPageLayout>
      </MainLayout>
      {paymentDeleteModalOpen ? (
        <Modal
          title={modalMsg.delete?.title}
          text={modalMsg.delete?.content}
          btnTitle={"삭제하기"}
          setModalOpen={setPaymentDeleteModalOpen}
          onClick={() => {
            deletePaymentFunc();
          }}
        />
      ) : null}
    </>
  );
};

export default PaymentRequestDetail;
