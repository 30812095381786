import api from "./api.js";

export default class ExcelApi {
  static async GetCommonMemberExcel(data) {
    return await api.post(`admin/common/members/excel`, data);
  }

  static async GetCompanyMemberExcel(data) {
    return await api.post(`admin/company/members/excel`, data);
  }

  static async GetWithdrawExcel(data) {
    return await api.post(`admin/withdraws/excel`, data);
  }

  static async GetMerchExcel(data) {
    return await api.post(`admin/company/members/merch/excel`, data);
  }

  static async GetMerchRemoveExcel(data) {
    return await api.post(`admin/company/members/merch/remove/excel`, data);
  }

  static async GetPaymentExcel(data) {
    return await api.post(`admin/payments/excel`, data);
  }

  static async GetPaymentRefundExcel(data) {
    return await api.post(`admin/payments/refund/excel`, data);
  }

  static async GetCalculateExcel(data) {
    return await api.post(`admin/calculates/excel`, data);
  }
}
