import api from "./api.js";

export default class WithdrawApi {
  static async withdraw(data) {
    return await api.post(`member/withdraw`, data);
  }
  static async getWithdrawList(params) {
    return await api.get(`admin/withdraws`, params);
  }
  static async getById(memberId, params) {
    return await api.get(`admin/withdraw/${memberId}`, params);
  }
  static async delete(data) {
    return await api.del(`admin/withdraws`, data);
  }
}
