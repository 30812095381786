import React, { useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import PretendardText from "../atoms/text/pretendardText";
import { isNull } from "../../util/check";

function TableTab(props) {
  // import
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <>
      <div
        style={{
          display: "flex",
          marginBottom: "20px",
          gap: "10px",
        }}
      >
        <PretendardText
          style={{
            width: "125px",
            height: "63px",
            color: props.isExpert ? "#27BEFF" : "#ADB4BA",
            fontWeight: "700",
            fontSize: "18px",
            lineHeight: "25px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "19px 10px",
            borderBottom: props.isExpert ? "2px solid #27BEFF" : "",
            cursor: "pointer",
          }}
          onClick={() => {
            navigate(props.route + `/${props.id}`, {
              replace: true,
            });
          }}
        >
          {props.leftTitle}
        </PretendardText>
        <PretendardText
          style={{
            width: "125px",
            height: "63px",
            color: !props.isExpert ? "#27BEFF" : "#ADB4BA",
            fontWeight: "700",
            fontSize: "18px",
            lineHeight: "25px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "19px 10px",
            borderBottom: !props.isExpert ? "2px solid #27BEFF" : "",
            cursor: "pointer",
          }}
          onClick={() => {
            navigate(props.anotherRoute + `/${props.id}`, {
              replace: true,
            });
          }}
        >
          {props.rightTitle}
        </PretendardText>
      </div>
    </>
  );
}

export default TableTab;
