import api from "./api.js";

export default class MarketingApi {
  static async create(data) {
    return await api.post(`admin/marketing-type`, data);
  }
  static async getList(params) {
    return await api.get(`admin/marketing-types`, params);
  }
  static async getById(marketingId, params) {
    return await api.get(`admin/marketing-type/${marketingId}`, params);
  }
  static async update(marketingId, data) {
    return await api.patch(`admin/marketing-type/${marketingId}`, data);
  }
  static async delete(data) {
    return await api.del(`admin/marketing-types`, data);
  }
  static async updateActivation(marketingId, data) {
    return await api.post(
      `admin/marketing-type/activation/${marketingId}`,
      data
    );
  }
}
