import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../../component/organisms/header";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Sidebar from "../../../component/organisms/sidebar";
import DetailPageLayout from "../../../component/atoms/layout/detail/detailPageLayout";
import DetailTop from "../../../component/atoms/layout/detail/detailTop";
import GoBack from "../../../component/molecules/goBack";
import DelBtn from "../../../component/atoms/button/delBtn";
import DetailPageTitle from "../../../component/atoms/text/detail/detailPageTitle";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import DetailFormText from "../../../component/atoms/text/detail/detailFormText";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import EditBtn from "./../../../component/atoms/button/editBtn";
import { route } from "./../../../router/route";
import TermsApi from "../../../api/terms.api";
import { errorHandler } from "../../../util/errorHandler";

const GeneralTermsDetail = () => {
  const navigate = useNavigate();
  let { id } = useParams();

  // mounted
  useEffect(() => {
    getTermsInfo();
  }, []);

  // ----- 정보 가져오기
  const [termsInfo, setTermsInfo] = useState({
    title: "",
    content: "",
  });

  const getTermsInfo = async () => {
    try {
      const response = (await TermsApi.getById(id)).data.data.content;
      setTermsInfo(response);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
            <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
              <EditBtn
                onClick={() => {
                  navigate(route.general_terms_edit + `/${id}`);
                }}
              />
            </div>
          </DetailTop>

          <DetailPage style={{ minHeight: "295px" }}>
            <DetailPageTitle>상세보기</DetailPageTitle>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "20px" }}
            >
              <DetailPageForm>
                <DetailFormTitle>약관명</DetailFormTitle>
                <DetailFormText>{termsInfo.title}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm
                style={{
                  alignItems: "flex-start",
                }}
              >
                <DetailFormTitle>약관내용</DetailFormTitle>
                <DetailFormText>{termsInfo.content}</DetailFormText>
              </DetailPageForm>
            </div>
          </DetailPage>
        </DetailPageLayout>
      </MainLayout>
    </>
  );
};

export default GeneralTermsDetail;
