import React from "react";
import styled from "styled-components";
import Checkbox from "../../../component/atoms/input/checkbox";
import DetailFormText from "../../../component/atoms/text/detail/detailFormText";

function AuthorityCheckbox(props) {
  return (
    <>
      <CheckboxWrapper>
        <Checkbox
          style={{
            width: "18px",
            height: "18px",
          }}
          checked={props.checked}
          onChange={(e) => {
            props.onChange(e.target.checked);
          }}
        />
        <DetailFormText style={{ color: "#262C31", fontWeight: "500" }}>
          {props.text}
        </DetailFormText>
      </CheckboxWrapper>
    </>
  );
}

export default AuthorityCheckbox;

const CheckboxWrapper = styled.div.attrs((props) => {})`
  display: flex;
  align-items: center;
  gap: 8px;
`;
