import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Header from "../../../component/organisms/header";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Sidebar from "../../../component/organisms/sidebar";
import DetailPageLayout from "../../../component/atoms/layout/detail/detailPageLayout";
import DetailTop from "../../../component/atoms/layout/detail/detailTop";
import GoBack from "../../../component/molecules/goBack";
import DelBtn from "../../../component/atoms/button/delBtn";
import DetailPageTitle from "../../../component/atoms/text/detail/detailPageTitle";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import DetailFormText from "../../../component/atoms/text/detail/detailFormText";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import PaymentApi from "../../../api/payment.api";
import { errorHandler } from "../../../util/errorHandler";
import { toast } from "react-toastify";
import { toastMsg } from "../../../util/toastMsg";
import { formatNumberWithCommas } from "./../../../util/formatNumber";
import moment from "moment";
import Modal from "../../../component/templates/Modal";
import { modalMsg } from "../../../util/modalMsg";

const PaymentCancelDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let { id } = useParams();

  useEffect(() => {
    getPaymentInfo();
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  // ------------------------------------------- //
  // ---------- 서비스 정보 가져오기 ---------- //
  // ----------------------------------------- //
  const [paymentInfo, setPaymentInfo] = useState({});

  const getPaymentInfo = async () => {
    try {
      const response = (await PaymentApi.getById(id)).data.data.content;

      setPaymentInfo(response);
    } catch (error) {
      errorHandler(error);
    }
  };

  // 삭제하기
  const [paymentDeleteModalOpen, setPaymentDeleteModalOpen] = useState(false);
  const [delPaymentId, setDelPaymentId] = useState([]);

  const deletePaymentFunc = async () => {
    try {
      let data = {
        ids: delPaymentId,
      };
      await PaymentApi.delete(data);
      toast(toastMsg.delete);
      setPaymentDeleteModalOpen(false);
      setDelPaymentId([]);
      navigate(-1);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
            <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
              <DelBtn
                onClick={() => {
                  setDelPaymentId([id]);
                  setPaymentDeleteModalOpen(true);
                }}
              />
            </div>
          </DetailTop>

          <DetailPage style={{ minHeight: "379px" }}>
            <DetailPageTitle>기본정보</DetailPageTitle>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "20px" }}
            >
              <DetailPageForm>
                <DetailFormTitle>이메일</DetailFormTitle>
                <DetailFormText>{paymentInfo.email}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>상품명</DetailFormTitle>
                <DetailFormText>{paymentInfo.merchTitle}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>주문번호</DetailFormTitle>
                <DetailFormText>{paymentInfo.paymentNumber}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>상품 금액</DetailFormTitle>
                <DetailFormText>
                  {formatNumberWithCommas(paymentInfo.price)}
                </DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>수수료</DetailFormTitle>
                <DetailFormText>
                  {formatNumberWithCommas(paymentInfo.charge)}
                </DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>환불/취소 금액</DetailFormTitle>
                <DetailFormText>
                  {formatNumberWithCommas(paymentInfo.price)}
                </DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>결제수단</DetailFormTitle>
                <DetailFormText>{paymentInfo.payMethodTitle}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>환불 요청일</DetailFormTitle>
                <DetailFormText>
                  {moment.utc(paymentInfo.updatedAt).format("YYYY/MM/DD")}
                </DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>환불요청</DetailFormTitle>
                <DetailFormText>
                  {paymentInfo.refundStatus === "NONE"
                    ? "해당없음"
                    : paymentInfo.refundStatus === "REQUEST"
                    ? "환불요청"
                    : "환불완료"}
                </DetailFormText>
              </DetailPageForm>
            </div>
          </DetailPage>
        </DetailPageLayout>
      </MainLayout>
      {paymentDeleteModalOpen ? (
        <Modal
          title={modalMsg.delete?.title}
          text={modalMsg.delete?.content}
          btnTitle={"삭제하기"}
          setModalOpen={setPaymentDeleteModalOpen}
          onClick={() => {
            deletePaymentFunc();
          }}
        />
      ) : null}
    </>
  );
};

export default PaymentCancelDetail;
