import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Header from "../../../component/organisms/header";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Sidebar from "../../../component/organisms/sidebar";
import DetailPageLayout from "../../../component/atoms/layout/detail/detailPageLayout";
import { route } from "../../../router/route";
import TableTitle from "../../../component/atoms/text/table/tableTitle";
import TableTop from "../../../component/atoms/layout/table/tableTop";
import TableCount from "../../../component/molecules/tableCount";
import TableSearchBtn from "../../../component/atoms/layout/table/tableSearchBtn";
import SearchBar from "../../../component/atoms/input/searchBar";
import Table from "../../../component/atoms/layout/table/table";
import Tr from "../../../component/atoms/layout/table/tr";
import Th from "../../../component/atoms/layout/table/th";
import TableText from "../../../component/atoms/text/table/tableText";
import Td from "../../../component/atoms/layout/table/td";
import DetailBtn from "../../../component/atoms/button/detailBtn";
import Pagination from "../../../component/molecules/pagination";
import { errorHandler } from "../../../util/errorHandler";
import ChatApi from "../../../api/chat.api";
import moment from "moment";
import DelBtn from "../../../component/atoms/button/delBtn";
import Checkbox from "./../../../component/atoms/input/checkbox";
import { toast } from "react-toastify";
import { toastMsg } from "../../../util/toastMsg";
import Modal from "../../../component/templates/Modal";
import { modalMsg } from "../../../util/modalMsg";

const ChatHistory = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams(); // eslint-disable-line no-unused-vars

  // ------------------------------------ //
  // ---------- 마운트가 되면 ---------- //
  // ---------------------------------- //
  useEffect(() => {
    setKeyword(searchParams.get("keyword") ? searchParams.get("keyword") : "");
    getChatHistoryList();
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  // ----- 목록 가져오기
  const page = useMemo(() => {
    return searchParams.get("page") ? searchParams.get("page") : 1;
  }, [searchParams]);

  const [pagination, setPagination] = useState({
    size: 10, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈

    totalCount: 0,
    totalPages: 0,
    first: false,
    last: false,
  });

  const [chatHistoryList, setChatHistoryList] = useState([]);
  const [chatHistoryCount, setChatHistoryCount] = useState(0);
  const [keyword, setKeyword] = useState("");

  const getChatHistoryList = async () => {
    try {
      const data = {
        keyword: searchParams.get("keyword"),
        page: searchParams.get("page"),
        size: pagination.size,
      };
      let response = (await ChatApi.getList(data)).data.data.content;
      setChatHistoryList(response);
      setChatHistoryCount(response.length);

      setPagination({
        ...pagination,
        totalCount: response.totalCount ? response.totalCount : 0,
        totalPages: response.totalPage
          ? response.totalPage
          : Math.ceil(response.length / 10),
        first: response.isFirst ? response.isFirst : false,
        last: response.isLast ? response.isLast : false,
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  // ---------------------------------------- //
  // ---------- 삭제 및 체크 관련 ---------- //
  // -------------------------------------- //
  const isAllCheck = useMemo(() => {
    const checkedList = chatHistoryList.filter((item) => item.checked);
    return (
      checkedList.length !== 0 && checkedList.length === chatHistoryList.length
    );
  }, [chatHistoryList]);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [delIdList, setDelIdList] = useState([]);

  const deleteFunc = async () => {
    try {
      let data = {
        ids: delIdList,
      };
      await ChatApi.delete(data);
      toast(toastMsg.delete);
      setDeleteModalOpen(false);
      setDelIdList([]);
      getChatHistoryList();
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <div style={{ marginTop: "40px" }}>
            <TableTitle>채팅 내역 관리</TableTitle>
            <TableTop>
              <TableCount text={"항목"} count={chatHistoryCount} />
              <TableSearchBtn>
                <SearchBar
                  value={keyword}
                  onChange={(e) => {
                    setKeyword(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      navigate(
                        `${route.chat_history}?page=1&keyword=${keyword}`,
                        {
                          replace: true,
                        }
                      );
                    }
                  }}
                  onClick={() => {
                    navigate(
                      `${route.chat_history}?page=1&keyword=${keyword}`,
                      {
                        replace: true,
                      }
                    );
                  }}
                />
                <DelBtn
                  text={"선택항목 삭제"}
                  onClick={() => {
                    const delList = chatHistoryList.filter(
                      (item) => item.checked
                    );
                    if (delList.length <= 0) {
                      return;
                    }
                    setDelIdList(delList.map((item) => item.id));
                    setDeleteModalOpen(true);
                  }}
                />
              </TableSearchBtn>
            </TableTop>
            <Table>
              <colgroup>
                {/* 체크박스 */}
                <col width="70px" />
                {/* 전문가 회원명 */}
                <col width="227px" />
                {/* 일반 회원명 */}
                <col width="542px" />
                {/* 개설일 */}
                <col width="130px" />
                {/* 상세보기 */}
                <col width="75px" />
              </colgroup>
              <thead>
                <Tr style={{ height: "41px", background: "#f2f4f5" }}>
                  <Th style={{ paddingLeft: "20px" }}>
                    <Checkbox
                      checked={isAllCheck}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setChatHistoryList(
                            chatHistoryList.map((item) => {
                              return {
                                ...item,
                                checked: true,
                              };
                            })
                          );
                        } else {
                          setChatHistoryList(
                            chatHistoryList.map((item) => {
                              return {
                                ...item,
                                checked: false,
                              };
                            })
                          );
                        }
                      }}
                    />
                  </Th>
                  <Th>
                    <TableText>전문가 회원명</TableText>
                  </Th>
                  <Th>
                    <TableText>일반 회원명</TableText>
                  </Th>
                  <Th>
                    <TableText>개설일</TableText>
                  </Th>
                  <Th>
                    <TableText>상세보기</TableText>
                  </Th>
                </Tr>
              </thead>
              <tbody>
                {chatHistoryList.map((item, index) => {
                  return (
                    <Tr
                      key={index}
                      style={{ height: "59px", background: "#fff" }}
                    >
                      <Td style={{ paddingLeft: "20px" }}>
                        <Checkbox
                          checked={item.checked}
                          onChange={() => {
                            item.checked = !item.checked;
                            setChatHistoryList([...chatHistoryList]);
                          }}
                        />
                      </Td>
                      <Td>
                        <TableText>{item.companyMemberName.name}</TableText>
                      </Td>
                      <Td>
                        <TableText>{item.commonMemberName.name}</TableText>
                      </Td>
                      <Td>
                        <TableText>
                          {moment.utc(item.createdAt).format("YYYY/MM/DD")}
                        </TableText>
                      </Td>
                      <Td>
                        <DetailBtn
                          onClick={() => {
                            navigate(route.chat_history_detail + `/${item.id}`);
                          }}
                        />
                      </Td>
                    </Tr>
                  );
                })}
              </tbody>
            </Table>
            <Pagination
              route={route.chat_history}
              queryString={`keyword=${searchParams.get("keyword") || ""}`}
              currentPage={page}
              totalPages={pagination.totalPages}
              rangeSize={pagination.rangeSize}
              first={pagination.first}
              last={pagination.last}
            />
          </div>
        </DetailPageLayout>
      </MainLayout>

      {deleteModalOpen ? (
        <Modal
          title={modalMsg.delete?.title}
          text={modalMsg.delete?.content}
          btnTitle={"삭제하기"}
          setModalOpen={setDeleteModalOpen}
          onClick={() => {
            deleteFunc();
          }}
        />
      ) : null}
    </>
  );
};

export default ChatHistory;
