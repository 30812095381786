import api from "./api.js";

export default class OpenSourceApi {
  static async create(data) {
    return await api.post(`admin/open-source`, data);
  }
  static async getList(params) {
    return await api.get(`admin/open-sources`, params);
  }
  static async getById(openSourceId, params) {
    return await api.get(`admin/open-source/${openSourceId}`, params);
  }
  static async update(openSourceId, data) {
    return await api.patch(`admin/open-source/${openSourceId}`, data);
  }
  static async delete(id) {
    return await api.del(`admin/open-source?${id}`);
  }
}
