import api from "./api.js";

export default class ManagerApi {
  static async getList(params) {
    // return await api.get(`admin/manager`, params);
    return await api.get(`admin/managers`, params);
  }
  static async getById(managerId, params) {
    // return await api.get(`admin/manager/info/${managerId}`, params);
    return await api.get(`admin/manager/${managerId}`, params);
  }
  static async update(managerId, data) {
    return await api.patch(`admin/manager/${managerId}`, data);
  }
  static async delete(data) {
    // return await api.del(`admin/manager?${queryString}`);
    return await api.del(`admin/managers`, data);
  }

  static async approve(managerId, data) {
    // return await api.put(`admin/manager/approve/${managerId}`, data);
    return await api.post(`admin/manager/approval/${managerId}`, data);
  }
  static async activation(managerId, data) {
    // return await api.put(`admin/manager/activate/${managerId}`, data);
    return await api.post(`admin/manager/activation/${managerId}`, data);
  }

  static async join(data) {
    // return await api.post(`register-manager`, data);
    return await api.post(`auth/sign/manager`, data);
  }
  static async login(data) {
    // return await api.post(`login-manager`, data);
    return await api.post(`auth/login/manager`, data);
  }
  static async getMyInfo(params) {
    // return await api.get(`admin/manager/my`, params);
    return await api.get(`admin/manager/my/info`, params);
  }
}
