import api from "./api.js";

export default class PaymentApi {
  static async getList(params) {
    return await api.get(`admin/payments`, params);
  }
  static async getRefundList(params) {
    return await api.get(`admin/payments/refund`, params);
  }
  static async getById(paymentId, params) {
    return await api.get(`admin/payment/${paymentId}`, params);
  }
  static async delete(data) {
    return await api.del(`admin/payments`, data);
  }
  static async updateComplete(paymentId, data) {
    return await api.post(`admin/payment/complete/${paymentId}`, data);
  }
  static async updateRefund(paymentId, data) {
    return await api.post(`admin/payment/refund/${paymentId}`, data);
  }
  static async getMethod(params) {
    return await api.get(`admin/account`, params);
  }
  static async updateMethod(data) {
    return await api.patch(`admin/account`, data);
  }
}
