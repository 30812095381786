import api from "./api.js";

export default class AskApi {
  static async getList(params) {
    return await api.get(`admin/asks`, params);
  }
  static async getById(askId, params) {
    return await api.get(`admin/ask/${askId}`, params);
  }
  static async update(askId, data) {
    return await api.patch(`admin/ask/${askId}`, data);
  }
}
