import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Header from "./../../../component/organisms/header";
import { errorHandler } from "../../../util/errorHandler";
import { toastMsg } from "../../../util/toastMsg";
import { toast } from "react-toastify";
import MainLayout from "./../../../component/atoms/layout/mainLayout";
import Sidebar from "./../../../component/organisms/sidebar";
import TableLayout from "./../../../component/atoms/layout/table/tableLayout";
import TableTitle from "./../../../component/atoms/text/table/tableTitle";
import TableTop from "./../../../component/atoms/layout/table/tableTop";
import TableCount from "./../../../component/molecules/tableCount";
import TableSearchBtn from "./../../../component/atoms/layout/table/tableSearchBtn";
import SearchBar from "./../../../component/atoms/input/searchBar";
import { route } from "./../../../router/route";
import Table from "../../../component/atoms/layout/table/table";
import Tr from "./../../../component/atoms/layout/table/tr";
import Th from "./../../../component/atoms/layout/table/th";
import Checkbox from "./../../../component/atoms/input/checkbox";
import TableText from "./../../../component/atoms/text/table/tableText";
import Td from "./../../../component/atoms/layout/table/td";
import moment from "moment";
import TableDelBtn from "./../../../component/atoms/button/tableDelBtn";
import DetailBtn from "./../../../component/atoms/button/detailBtn";
import Pagination from "./../../../component/molecules/pagination";
import Modal from "../../../component/templates/Modal";
import { modalMsg } from "./../../../util/modalMsg";
import ExcelBtn from "./../../../component/atoms/button/excelBtn";
import CompanyServiceApi from "../../../api/companyService.api";
import { saveAs } from "file-saver";
import ExcelApi from "../../../api/excel.api";

const DeleteService = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams(); // eslint-disable-line no-unused-vars

  // ----------------------------------- //
  // ---------- 마운트가 되면 ---------- //
  // --------------------------------- //
  useEffect(() => {
    setKeyword(searchParams.get("keyword") ? searchParams.get("keyword") : "");
    getDeleteServiceList();
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  const page = useMemo(() => {
    return searchParams.get("page") ? searchParams.get("page") : 1;
  }, [searchParams]);

  const [pagination, setPagination] = useState({
    size: 10, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈

    totalCount: 0,
    totalPages: 0,
    first: false,
    last: false,
  });

  const [deleteServiceList, setDeleteServiceList] = useState([]);
  const [deleteServiceCount, setDeleteServiceCount] = useState(0);
  const [keyword, setKeyword] = useState("");

  const getDeleteServiceList = async () => {
    try {
      const data = {
        keyword: searchParams.get("keyword"),
        page: searchParams.get("page"),
        size: pagination.size,
      };
      let responseData = (await CompanyServiceApi.getRemoveList(data)).data
        .data;
      // let responseData = (await CompanyServiceApi.getRemoveListById(18, data))
      //   .data.data;
      setDeleteServiceCount(responseData.totalCount);
      setDeleteServiceList(
        responseData.content.map((item) => {
          return {
            ...item,
            checked: false,
          };
        })
      );
      setPagination({
        ...pagination,
        totalCount: responseData.totalCount ? responseData.totalCount : 0,
        totalPages: responseData.totalPage
          ? responseData.totalPage
          : Math.ceil(responseData.totalCount / 10),
        first: responseData.isFirst ? responseData.isFirst : false,
        last: responseData.isLast ? responseData.isLast : false,
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  // ----- 삭제
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [delId, setDelId] = useState("");

  const isAllCheck = useMemo(() => {
    const checkedList = deleteServiceList.filter((item) => item.checked);
    return (
      checkedList.length !== 0 &&
      checkedList.length === deleteServiceList.length
    );
  }, [deleteServiceList]);

  const deleteFunc = async () => {
    try {
      await CompanyServiceApi.deleteHardAll(delId);
      toast(toastMsg.delete);
      setDeleteModalOpen(false);
      setDelId("");
      getDeleteServiceList();
    } catch (error) {
      errorHandler(error);
    }
  };

  const [title, setTitle] = useState("삭제된 서비스 관리");
  const [subTitle, setSubTitle] = useState("항목");

  //------------엑셀 생성
  const downloadExcel = async (excelData) => {
    const Excel = require("exceljs");
    const workbook = new Excel.Workbook();
    workbook.addWorksheet("Sheet One");
    const sheetOne = workbook.getWorksheet("Sheet One");

    sheetOne.columns = [
      { header: "기업명", key: "companyName", width: 40 },
      { header: "대표자명", key: "ceo", width: 40 },
      { header: "서비스 수", key: "merchCount", width: 40 },
      { header: "최초 등록일", key: "createdAt", width: 40 },
    ];

    excelData.map((item) => {
      // 행 추가
      sheetOne.addRow(item);
    });
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), "삭제된 서비스 관리.xlsx");
  };

  const getExcelAll = async () => {
    try {
      const excelArr = [];
      const response = (
        await ExcelApi.GetMerchRemoveExcel({
          ids: [],
          allFlag: true,
        })
      ).data.data.content;

      response.map((item) => {
        const createDate = moment(item.createdAt).format("YYYY/MM/DD");
        excelArr.unshift({
          ...item,
          companyName: item.profile?.companyName,
          ceo: item.profile?.ceo,
          createdAt: createDate,
        });
      });

      downloadExcel(excelArr);
    } catch (error) {
      errorHandler(error);
    }
  };

  const getExcelChecked = async () => {
    const filter = deleteServiceList.filter((item) => item.checked);
    if (filter.length <= 0) {
      return toast("항목을 선택해주세요");
    }

    try {
      const excelArr = [];
      const response = (
        await ExcelApi.GetMerchRemoveExcel({
          ids: filter.map((item) => item.id),
          allFlag: false,
        })
      ).data.data.content;

      response.map((item) => {
        const createDate = moment(item.createdAt).format("YYYY/MM/DD");
        excelArr.unshift({
          ...item,
          companyName: item.profile?.companyName,
          ceo: item.profile?.ceo,
          createdAt: createDate,
        });
      });

      downloadExcel(excelArr);
    } catch (error) {
      errorHandler(error);
    }
  };
  //------------액셀 부분 끝

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <TableLayout>
          <TableTitle>{title}</TableTitle>
          <TableTop>
            <TableCount
              text={subTitle}
              count={
                deleteServiceCount
                  ? deleteServiceCount
                  : deleteServiceList.length
              }
            />
            <TableSearchBtn>
              <SearchBar
                value={keyword}
                onChange={(e) => {
                  setKeyword(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    navigate(
                      `${route.service_delete}?page=1&keyword=${keyword}`,
                      {
                        replace: true,
                      }
                    );
                    setTitle("검색 결과");
                    setSubTitle("검색 결과");
                  }
                }}
                onClick={() => {
                  navigate(
                    `${route.service_delete}?page=1&keyword=${keyword}`,
                    {
                      replace: true,
                    }
                  );
                  setTitle("검색 결과");
                  setSubTitle("검색 결과");
                }}
              />
              <ExcelBtn
                onSelectClick={getExcelChecked}
                onAllClick={getExcelAll}
              />
            </TableSearchBtn>
          </TableTop>
          <Table>
            <colgroup>
              {/* 체크박스 영역 */}
              <col width="90px" />
              {/* 기업명 */}
              <col width="272px" />
              {/* 대표자명 */}
              <col width="236px" />
              {/* 서비스 수 */}
              <col width="109px" />
              {/* 최초 등록일 */}
              <col width="177px" />
              {/* 삭제하기 */}
              <col width="86px" />
              {/* 상세보기 */}
              <col width="74px" />
            </colgroup>
            <thead>
              <Tr style={{ height: "40px", background: "#f2f4f5" }}>
                <Th style={{ paddingLeft: "20px" }}>
                  <Checkbox
                    checked={isAllCheck}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setDeleteServiceList(
                          deleteServiceList.map((item) => {
                            return {
                              ...item,
                              checked: true,
                            };
                          })
                        );
                      } else {
                        setDeleteServiceList(
                          deleteServiceList.map((item) => {
                            return {
                              ...item,
                              checked: false,
                            };
                          })
                        );
                      }
                    }}
                  />
                </Th>
                <Th>
                  <TableText>기업명</TableText>
                </Th>
                <Th>
                  <TableText>대표자명</TableText>
                </Th>
                <Th>
                  <TableText>서비스 수</TableText>
                </Th>
                <Th>
                  <TableText>최초 등록일</TableText>
                </Th>
                <Th>
                  <TableText>완전 삭제</TableText>
                </Th>
                <Th>
                  <TableText>상세보기</TableText>
                </Th>
              </Tr>
            </thead>
            <tbody>
              {deleteServiceList.map((item, index) => {
                return (
                  <Tr
                    key={index}
                    style={{ height: "59px", background: "#fff" }}
                  >
                    <Td style={{ paddingLeft: "20px" }}>
                      <Checkbox
                        checked={item.checked}
                        onChange={() => {
                          item.checked = !item.checked;
                          setDeleteServiceList([...deleteServiceList]);
                        }}
                      />
                    </Td>
                    <Td>
                      <TableText>{item.profile.companyName}</TableText>
                    </Td>
                    <Td>
                      <TableText>{item.profile.ceo}</TableText>
                    </Td>
                    <Td>
                      <TableText>{item.merchCount}</TableText>
                    </Td>
                    <Td>
                      <TableText>
                        {moment
                          .utc(item.firstRegistrationDate)
                          .format("YYYY/MM/DD")}
                      </TableText>
                    </Td>
                    <Td>
                      <TableDelBtn
                        style={{ marginLeft: "6px" }}
                        onClick={() => {
                          setDelId(item.id);
                          setDeleteModalOpen(true);
                        }}
                      />
                    </Td>
                    <Td>
                      <DetailBtn
                        onClick={() => {
                          navigate(route.service_delete_detail + `/${item.id}`);
                        }}
                      />
                    </Td>
                  </Tr>
                );
              })}
            </tbody>
          </Table>
          <Pagination
            route={route.service_delete}
            queryString={
              searchParams.get("keyword")
                ? `keyword=${searchParams.get("keyword")}`
                : ""
            }
            currentPage={page}
            totalPages={pagination.totalPages}
            rangeSize={pagination.rangeSize}
            first={pagination.first}
            last={pagination.last}
          />
        </TableLayout>
      </MainLayout>
      {deleteModalOpen ? (
        <Modal
          title={modalMsg.deleteAllCompanyService?.title}
          text={modalMsg.deleteAllCompanyService?.content}
          btnTitle={"삭제하기"}
          setModalOpen={setDeleteModalOpen}
          onClick={() => {
            deleteFunc();
          }}
        />
      ) : null}
    </>
  );
};

export default DeleteService;
