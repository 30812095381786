import api from "./api.js";

export default class NoticeApi {
  static async create(data) {
    return await api.post(`admin/notice`, data);
  }
  static async getList(params) {
    return await api.get(`admin/notices`, params);
  }
  static async getById(noticeId, params) {
    return await api.get(`admin/notice/${noticeId}`, params);
  }
  static async update(noticeId, data) {
    return await api.put(`admin/notice/${noticeId}`, data);
  }
  static async delete(data) {
    return await api.del(`admin/notices`, data);
  }
}
