import api from "./api.js";

export default class ServiceFaqApi {
  static async getList(memberFaqId, params) {
    return await api.get(`admin/merch-faqs/${memberFaqId}`, params); // 서비스 FAQ 목록 조회
  }
  static async getById(serviceFaqId, params) {
    return await api.get(`admin/merch-faq/${serviceFaqId}`, params); // 서비스 FAQ 단일 조회
  }
  static async update(serviceFaqId, data) {
    return await api.patch(`admin/merch-faq/${serviceFaqId}`, data); // 서비스 FAQ 수정
  }
  static async delete(data) {
    return await api.del(`admin/merch-faqs`, data); // 서비스 FAQ 삭제
  }
  static async updateActivation(serviceFaqId, data) {
    return await api.post(`admin/merch-faq/activation/${serviceFaqId}`, data); // 서비스 FAQ 활성/비활성
  }
}
