import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { errorHandler } from "../../../util/errorHandler";
import { toast } from "react-toastify";
import { toastMsg } from "../../../util/toastMsg";
import Header from "./../../../component/organisms/header";
import MainLayout from "./../../../component/atoms/layout/mainLayout";
import Sidebar from "./../../../component/organisms/sidebar";
import DetailPageLayout from "./../../../component/atoms/layout/detail/detailPageLayout";
import DetailTop from "./../../../component/atoms/layout/detail/detailTop";
import GoBack from "./../../../component/molecules/goBack";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import DetailPageTitle from "./../../../component/atoms/text/detail/detailPageTitle";
import DetailPageForm from "./../../../component/atoms/layout/detail/detailPageForm";
import DetailFormTitle from "./../../../component/atoms/text/detail/detailFormTitle";
import Modal from "../../../component/templates/Modal";
import { modalMsg } from "../../../util/modalMsg";
import MainInput from "./../../../component/atoms/input/mainInput";
import SaveBtn from "./../../../component/atoms/button/saveBtn";
import Radio from "./../../../component/atoms/input/radio";
import MarketingApi from "../../../api/marketing.api";
import { isNull } from "../../../util/check";

const MarketingTypeEdit = () => {
  const navigate = useNavigate();
  let { id } = useParams();

  // mounted
  useEffect(() => {
    getMarketingTypeInfo();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // ----- 정보 가져오기
  const [marketingTypeInfo, setMarketingTypeInfo] = useState({});

  const getMarketingTypeInfo = async () => {
    try {
      const response = (await MarketingApi.getById(id)).data.data.content;
      setMarketingTypeInfo(response);
    } catch (error) {
      errorHandler(error);
    }
  };

  //저장하기
  const [saveModalOpen, setSaveModalOpen] = useState(false);

  const saveBtnHandler = () => {
    if (isNull(marketingTypeInfo.title)) {
      return toast("필수사항을 모두 입력해주세요.");
    }

    setSaveModalOpen(true);
  };

  const saveFunc = async () => {
    try {
      const data = {
        title: marketingTypeInfo.title,
        activation: marketingTypeInfo.activation,
      };

      await MarketingApi.update(id, data);
      setSaveModalOpen(false);
      navigate(-1);
      toast(toastMsg.update);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
          </DetailTop>
          <DetailPage style={{ minHeight: "307px" }}>
            <DetailPageTitle>기본정보</DetailPageTitle>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "20px" }}
            >
              {/* 마케팅명 */}
              <DetailPageForm>
                <DetailFormTitle>마케팅명*</DetailFormTitle>
                <MainInput
                  style={{
                    width: 338,
                  }}
                  type={"text"}
                  placeholder={"마케팅명을 입력해주세요"}
                  value={marketingTypeInfo.title}
                  onChange={(e) =>
                    setMarketingTypeInfo({
                      ...marketingTypeInfo,
                      title: e.target.value,
                    })
                  }
                />
              </DetailPageForm>

              {/* 게시 상태 */}
              <DetailPageForm>
                <DetailFormTitle>게시 상태*</DetailFormTitle>
                <div style={{ display: "flex", gap: "20px" }}>
                  <Radio
                    id={""}
                    valueText={"비활성"}
                    checked={!marketingTypeInfo.activation}
                    onChange={() => {
                      setMarketingTypeInfo({
                        ...marketingTypeInfo,
                        activation: false,
                      });
                    }}
                  />
                  <Radio
                    id={""}
                    valueText={"활성"}
                    checked={marketingTypeInfo.activation}
                    onChange={() => {
                      setMarketingTypeInfo({
                        ...marketingTypeInfo,
                        activation: true,
                      });
                    }}
                  />
                </div>
              </DetailPageForm>
            </div>

            <SaveBtn
              style={{ bottom: "40px", right: "40px" }}
              onClick={() => {
                saveBtnHandler();
              }}
            />
          </DetailPage>
        </DetailPageLayout>
      </MainLayout>

      {saveModalOpen ? (
        <Modal
          title={modalMsg.update?.title}
          text={modalMsg.update?.content}
          setModalOpen={setSaveModalOpen}
          onClick={() => {
            saveFunc();
          }}
        />
      ) : null}
    </>
  );
};

export default MarketingTypeEdit;
