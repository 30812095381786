import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../../component/organisms/header";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Sidebar from "../../../component/organisms/sidebar";
import DetailPageLayout from "../../../component/atoms/layout/detail/detailPageLayout";
import DetailTop from "../../../component/atoms/layout/detail/detailTop";
import GoBack from "../../../component/molecules/goBack";
import DelBtn from "../../../component/atoms/button/delBtn";
import DetailPageTitle from "../../../component/atoms/text/detail/detailPageTitle";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import DetailFormText from "../../../component/atoms/text/detail/detailFormText";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import ChatApi from "../../../api/chat.api";
import { errorHandler } from "../../../util/errorHandler";
import { toast } from "react-toastify";
import { toastMsg } from "../../../util/toastMsg";
import moment from "moment";
import Modal from "../../../component/templates/Modal";
import { modalMsg } from "../../../util/modalMsg";

const ChatHistoryDetail = () => {
  const navigate = useNavigate();
  let { id } = useParams();

  // mounted
  useEffect(() => {
    getChatInfo();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [chatInfo, setChatInfo] = useState({});
  const [CommonMemberInfo, setCommonMemberInfo] = useState({});
  const [CompanyMemberInfo, setCompanyMemberInfo] = useState({});

  const getChatInfo = async () => {
    try {
      const response = (await ChatApi.getById(id)).data.data.content;
      setChatInfo(response);
      setCommonMemberInfo(response.commonMemberName);
      setCompanyMemberInfo(response.companyMemberName);
    } catch (error) {
      errorHandler(error);
    }
  };

  // -------------------------------- //
  // ---------- 채팅 삭제 ---------- //
  // ------------------------------ //
  const [delModalOpen, setDelModalOpen] = useState(false);

  const deleteFunc = async () => {
    try {
      const idData = {
        ids: [id],
      };
      await ChatApi.delete(idData);
      toast(toastMsg.delete);
      setDelModalOpen(false);
      navigate(-1);
    } catch (error) {
      errorHandler(error);
    }
  };

  // --------------------------------------------------- //
  // ---------- 마지막 채팅 시각 포맷팅 함수 ---------- //
  // ------------------------------------------------- //
  const formatDate = (date) => {
    const chatDate = new Date(date);

    const year = chatDate.getUTCFullYear();
    const month = String(chatDate.getUTCMonth() + 1).padStart(2, "0");
    const day = String(chatDate.getUTCDate()).padStart(2, "0");
    const hours = String(chatDate.getUTCHours()).padStart(2, "0");
    const minutes = String(chatDate.getUTCMinutes()).padStart(2, "0");
    const seconds = String(chatDate.getUTCSeconds()).padStart(2, "0");

    return `${year}.${month}.${day} ${hours}:${minutes}:${seconds}`;
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
            <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
              <DelBtn
                onClick={() => {
                  setDelModalOpen(true);
                }}
              />
            </div>
          </DetailTop>

          <DetailPage style={{ minHeight: "295px" }}>
            <DetailPageTitle>기본정보</DetailPageTitle>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "20px" }}
            >
              <DetailPageForm>
                <DetailFormTitle>전문가 회원명</DetailFormTitle>
                <DetailFormText>{CompanyMemberInfo.name}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>일반 회원명</DetailFormTitle>
                <DetailFormText>{CommonMemberInfo.name}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>개설일</DetailFormTitle>
                <DetailFormText>
                  {moment.utc(chatInfo.createdAt).format("YYYY/MM/DD")}
                </DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>마지막 채팅 시각</DetailFormTitle>
                <DetailFormText>
                  {chatInfo.lastMessageDate !== null
                    ? formatDate(chatInfo.lastMessageDate)
                    : "채팅 내역이 없습니다."}
                </DetailFormText>
              </DetailPageForm>
            </div>
          </DetailPage>
        </DetailPageLayout>
      </MainLayout>

      {delModalOpen ? (
        <Modal
          title={modalMsg.delete?.title}
          text={modalMsg.delete?.content}
          setModalOpen={setDelModalOpen}
          onClick={() => {
            deleteFunc();
          }}
        />
      ) : null}
    </>
  );
};

export default ChatHistoryDetail;
