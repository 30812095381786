import React, { useState } from "react";
import styled from "styled-components";
import TableTitle from "../../../component/atoms/text/table/tableTitle";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import DetailFormText from "../../../component/atoms/text/detail/detailFormText";
import moment from "moment";
import SaveBtn from "../../../component/atoms/button/saveBtn";
import { errorHandler } from "../../../util/errorHandler";
import AskApi from "../../../api/ask.api";
import { toast } from "react-toastify";
import { toastMsg } from "../../../util/toastMsg";
import { isNull } from "../../../util/check";
import MainInput from "../../../component/atoms/input/mainInput";
import Modal from "../../../component/templates/Modal";
import { modalMsg } from "../../../util/modalMsg";

function GeneralAskModal({ data, setModalOpen, listHandler }) {
  const [askData, setAskData] = useState(data);
  const [editAskData, setEditAskData] = useState(data);
  const [saveModalOpen, setSaveModalOpen] = useState(false);

  const saveBtnHandler = () => {
    if (isNull(editAskData.answer)) {
      return toast("필수사항을 모두 입력해주세요.");
    }

    setSaveModalOpen(true);
  };

  const saveFunc = async () => {
    try {
      const data = {
        answer: editAskData.answer,
      };

      await AskApi.update(askData.id, data);
      setSaveModalOpen(false);
      setAskData({ ...askData, answer: editAskData.answer });
      toast(toastMsg.update);
      listHandler();
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <ModalWrapper>
        <ModalBox>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "30px",
            }}
          >
            <TableTitle style={{ marginBottom: "0px" }}>문의 상세</TableTitle>
            <img
              style={{
                cursor: "pointer",
              }}
              src="/assets/admin/icons/ico_popup_close.svg"
              alt=""
              onClick={() => setModalOpen(false)}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              minHeight: "223px",
              overflowY: "auto",
            }}
          >
            <DetailPageForm>
              <DetailFormTitle>이메일</DetailFormTitle>
              <DetailFormText>{askData.member.email}</DetailFormText>
            </DetailPageForm>
            <DetailPageForm>
              <DetailFormTitle>제목</DetailFormTitle>
              <DetailFormText>{askData.title}</DetailFormText>
            </DetailPageForm>
            <DetailPageForm>
              <DetailFormTitle>작성일</DetailFormTitle>
              <DetailFormText>
                {moment.utc(askData.createAt).format("YYYY/MM/DD")}
              </DetailFormText>
            </DetailPageForm>
            <DetailPageForm>
              <DetailFormTitle>내용</DetailFormTitle>
              <DetailFormText>{askData.content}</DetailFormText>
            </DetailPageForm>
            <div
              style={{
                width: "100%",
                height: 5,
                backgroundColor: "#F8F8F8",
                margin: "10px 0",
              }}
            />
            <DetailPageForm>
              <DetailFormTitle>답변</DetailFormTitle>
              {!askData.answer && (
                <MainInput
                  style={{
                    width: "730px",
                  }}
                  type={"text"}
                  placeholder={"내용을 입력해주세요"}
                  value={editAskData.answer}
                  onChange={(e) =>
                    setEditAskData({ ...editAskData, answer: e.target.value })
                  }
                />
              )}
              {askData.answer && (
                <DetailFormText>{askData.answer}</DetailFormText>
              )}
            </DetailPageForm>
            <SaveBtn
              text={askData.answer ? "수정하기" : "답변하기"}
              style={{
                position: "static",
                marginTop: "20px",
                width: "fit-content",
                alignSelf: "flex-end",
              }}
              onClick={
                askData.answer === ""
                  ? saveBtnHandler
                  : () => {
                      setAskData({ ...askData, answer: "" });
                    }
              }
            />
          </div>
        </ModalBox>
      </ModalWrapper>
      {saveModalOpen ? (
        <Modal
          title={modalMsg.update?.title}
          text={modalMsg.update?.content}
          setModalOpen={setSaveModalOpen}
          onClick={() => {
            saveFunc();
          }}
        />
      ) : null}
    </>
  );
}

export default GeneralAskModal;

const ModalWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00000060;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 30;
`;

const ModalBox = styled.div.attrs((props) => {})`
  width: 1064px;
  min-height: 500px;
  padding: 50px;
  border: 1px solid #d7dbe0;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
  position: relative;
`;
