import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { errorHandler } from "../../../util/errorHandler";
import { toast } from "react-toastify";
import { toastMsg } from "../../../util/toastMsg";
import Header from "./../../../component/organisms/header";
import MainLayout from "./../../../component/atoms/layout/mainLayout";
import Sidebar from "./../../../component/organisms/sidebar";
import DetailPageLayout from "./../../../component/atoms/layout/detail/detailPageLayout";
import DetailTop from "./../../../component/atoms/layout/detail/detailTop";
import GoBack from "./../../../component/molecules/goBack";
import DelBtn from "./../../../component/atoms/button/delBtn";
import EditBtn from "./../../../component/atoms/button/editBtn";
import { route } from "./../../../router/route";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import DetailPageTitle from "./../../../component/atoms/text/detail/detailPageTitle";
import DetailPageForm from "./../../../component/atoms/layout/detail/detailPageForm";
import DetailFormText from "./../../../component/atoms/text/detail/detailFormText";
import DetailFormTitle from "./../../../component/atoms/text/detail/detailFormTitle";
import Checkbox from "./../../../component/atoms/input/checkbox";
import styled from "styled-components";
import MemberApi from "../../../api/member.api";
import Modal from "../../../component/templates/Modal";
import { modalMsg } from "../../../util/modalMsg";
import {
  formatBusinessNumber,
  formatPhoneNumber,
  formatTelNumber,
} from "../../../util/formatNumber";

const CompanyMemberDetail = () => {
  const navigate = useNavigate();
  let { id } = useParams();

  // --------------------------------------------------- //
  // ----------------- 데이터 가져오기 ----------------- //
  // ------------------------------------------------- //
  useEffect(() => {
    getCompanyMember();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // ----- 정보 가져오기
  const [companyMemberInfo, setCompanyMemberInfo] = useState({});
  const [companyProfileInfo, setCompanyProfileInfo] = useState({});

  const getCompanyMember = async () => {
    try {
      const response = (await MemberApi.getById(id)).data.data.content;
      let introFormat = response.profile.intro.replace(/\\/g, "");
      setCompanyMemberInfo(response);
      setCompanyProfileInfo({
        ...response.profile,
        intro: introFormat,
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  // ----- 삭제
  const [delModalOpen, setDelModalOpen] = useState(false);

  const deleteFunc = async () => {
    try {
      const idData = {
        ids: [id],
      };
      await MemberApi.delete(idData);
      toast(toastMsg.delete);
      setDelModalOpen(false);
      navigate(-1);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
            <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
              <DelBtn onClick={() => setDelModalOpen(true)} />
              <EditBtn
                onClick={() => {
                  navigate(route.user_company_member_edit + `/${id}`);
                }}
              />
            </div>
          </DetailTop>

          {/* ----------------------------- */}
          {/* ---------- 기본정보 ---------- */}
          {/* ----------------------------- */}
          <DetailPage style={{ minHeight: "556px" }}>
            <DetailPageTitle>기본정보</DetailPageTitle>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "20px" }}
            >
              {/* 이메일 */}
              <DetailPageForm>
                <DetailFormTitle>이메일</DetailFormTitle>
                <DetailFormText>{companyMemberInfo.email}</DetailFormText>
              </DetailPageForm>

              {/* 이름 */}
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>이름</DetailFormTitle>
                <DetailFormText>{companyMemberInfo.name}</DetailFormText>
              </DetailPageForm>

              {/* 전화번호 */}
              <DetailPageForm>
                <DetailFormTitle>전화번호</DetailFormTitle>
                <DetailFormText>
                  {formatPhoneNumber(companyMemberInfo.phoneNumber)}
                </DetailFormText>
              </DetailPageForm>

              {/* 나이 */}
              <DetailPageForm>
                <DetailFormTitle>나이</DetailFormTitle>
                <DetailFormText>{companyMemberInfo.age}</DetailFormText>
              </DetailPageForm>

              {/* 업종 */}
              <DetailPageForm>
                <DetailFormTitle>업종</DetailFormTitle>
                <DetailFormText>
                  {companyMemberInfo.sectorTitle
                    ? companyMemberInfo.sectorTitle
                    : "없음"}
                </DetailFormText>
              </DetailPageForm>

              {/* 약관 동의 */}
              <DetailPageForm
                style={{
                  alignItems: "flex-start",
                }}
              >
                <DetailFormTitle>약관 동의</DetailFormTitle>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                  }}
                >
                  <CheckboxWrapper>
                    <DetailFormText>이용약관 동의 (필수)</DetailFormText>
                    <Checkbox
                      id="agreement"
                      checked={companyMemberInfo.agreement}
                    />
                  </CheckboxWrapper>
                  <CheckboxWrapper>
                    <DetailFormText>
                      개인정보 수집 및 이용동의 (필수)
                    </DetailFormText>
                    <Checkbox
                      id="privacy"
                      checked={companyMemberInfo.privacy}
                    />
                  </CheckboxWrapper>
                  <CheckboxWrapper>
                    <DetailFormText>
                      개인정보 제 3자 제공 동의 (필수)
                    </DetailFormText>
                    <Checkbox
                      id="providePrivacy"
                      checked={companyMemberInfo.providePrivacy}
                    />
                  </CheckboxWrapper>
                </div>
              </DetailPageForm>

              {/* 알림 수신 동의 */}
              <DetailPageForm>
                <DetailFormTitle>알림 수신 동의</DetailFormTitle>
                <CheckboxWrapper>
                  <DetailFormText>광고성 정보 수신 동의 (선택)</DetailFormText>
                  <Checkbox
                    id="advertisement"
                    checked={companyMemberInfo.advertisement}
                  />
                </CheckboxWrapper>
              </DetailPageForm>

              {/* 승인 상태 */}
              <DetailPageForm>
                <DetailFormTitle>승인 상태</DetailFormTitle>
                <DetailFormText>
                  {companyMemberInfo.approval ? "승인" : "미승인"}
                </DetailFormText>
              </DetailPageForm>
            </div>
          </DetailPage>

          {/* ----------------------------- */}
          {/* ---------- 기업정보 ---------- */}
          {/* ----------------------------- */}
          <DetailPage style={{ minHeight: "469px" }}>
            <DetailPageTitle>기업정보</DetailPageTitle>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "20px" }}
            >
              {/* 기업명 */}
              <DetailPageForm>
                <DetailFormTitle>기업명</DetailFormTitle>
                <DetailFormText>
                  {companyProfileInfo.companyName}
                </DetailFormText>
              </DetailPageForm>

              {/* 지역 */}
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>지역</DetailFormTitle>
                <DetailFormText>
                  {companyProfileInfo.regionTitle}
                </DetailFormText>
              </DetailPageForm>

              {/* 사업장 소재지 */}
              <DetailPageForm>
                <DetailFormTitle>사업장 소재지</DetailFormTitle>
                <DetailFormText>
                  {companyProfileInfo.companyRegion}
                </DetailFormText>
              </DetailPageForm>

              {/* 사업자번호 */}
              <DetailPageForm>
                <DetailFormTitle>사업자번호</DetailFormTitle>
                <DetailFormText>
                  {formatBusinessNumber(companyProfileInfo.companyNumber)}
                </DetailFormText>
              </DetailPageForm>

              {/* 대표자명 */}
              <DetailPageForm>
                <DetailFormTitle>대표자명</DetailFormTitle>
                <DetailFormText>{companyProfileInfo.ceo}</DetailFormText>
              </DetailPageForm>

              {/* 전화번호 */}
              <DetailPageForm>
                <DetailFormTitle>전화번호</DetailFormTitle>
                <DetailFormText>
                  {formatTelNumber(companyProfileInfo.tel)}
                </DetailFormText>
              </DetailPageForm>

              {/* 기업 소개 */}
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>기업 소개</DetailFormTitle>
                {/* <DetailFormText>{companyProfileInfo.intro}</DetailFormText> */}
                <div
                  className="ql-editor"
                  dangerouslySetInnerHTML={{
                    __html: companyProfileInfo.intro,
                  }}
                />
              </DetailPageForm>
            </div>
          </DetailPage>
        </DetailPageLayout>
      </MainLayout>

      {delModalOpen ? (
        <Modal
          title={modalMsg.delete?.title}
          text={modalMsg.delete?.content}
          setModalOpen={setDelModalOpen}
          onClick={() => {
            deleteFunc();
          }}
        />
      ) : null}
    </>
  );
};

export default CompanyMemberDetail;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;
