import { errorHandler } from "./errorHandler";
import FileApi from "./../api/file.api";

// 파일 다운로드
export const fileDownload = async (serverFileName, originalFileName) => {
  try {
    const data = {
      serverFileName: serverFileName,
    };
    const response = (await FileApi.fileDownload(data)).data;

    // 확장자 추출
    const fileExtension = serverFileName.split(".").pop();

    // 모든 파일에 대해 '첨부파일'이라는 이름으로 다운로드
    const fileName = `${originalFileName}`;

    downloadBase64File(response, fileName);
  } catch (error) {
    console.log(error);
  }
};

const downloadBase64File = (base64Data, fileName) => {
  // MIME 타입을 고정하여 지정
  const mimeType = "application/octet-stream";

  // base64 데이터를 디코딩하여 Blob 객체 생성
  const byteCharacters = atob(base64Data);
  const byteNumbers = new Array(byteCharacters.length)
    .fill(null)
    .map((_, i) => byteCharacters.charCodeAt(i));
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: mimeType });

  // Blob 객체를 사용하여 다운로드 링크 생성 및 클릭 이벤트 트리거
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
