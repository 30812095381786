import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { isNull } from "../../util/check";
import { toast } from "react-toastify";
import { toastMsg } from "../../util/toastMsg";
import Header from "./../../component/organisms/header";
import MainLayout from "./../../component/atoms/layout/mainLayout";
import Sidebar from "./../../component/organisms/sidebar";
import DetailPageForm from "./../../component/atoms/layout/detail/detailPageForm";
import DetailPageLayout from "./../../component/atoms/layout/detail/detailPageLayout";
import DetailTop from "./../../component/atoms/layout/detail/detailTop";
import GoBack from "./../../component/molecules/goBack";
import DetailPageTitle from "../../component/atoms/text/detail/detailPageTitle";
import DetailFormTitle from "../../component/atoms/text/detail/detailFormTitle";
import MainInput from "../../component/atoms/input/mainInput";
import PretendardText from "./../../component/atoms/text/mainText";
import SaveBtn from "./../../component/atoms/button/saveBtn";
import Modal from "../../component/templates/Modal";
import { modalMsg } from "../../util/modalMsg";
import styled from "styled-components";
import DetailPage from "../../component/atoms/layout/detail/detailPage";
import DateInput from "./../../component/atoms/input/dateInput";
import ImgPreview from "../../component/molecules/imgPreview";
import ImgInput from "../../component/atoms/input/imgInput";
import Radio from "./../../component/atoms/input/radio";
import CurationApi from "../../api/curation.api";
import { errorHandler } from "../../util/errorHandler";

const CurationPostCreate = () => {
  const navigate = useNavigate();

  const [curationInfo, setCurationInfo] = useState({
    title: "",
    content: "",
    fileUrl: "",
    originFileName: "",
    serverFileName: "",
    startDate: "",
    endDate: "",
    sector: "",
    region: "",
    age: "",
    link: "",
    activation: true, // 게시 상태
  });

  //이미지 추가, 삭제
  const inputFile = (e) => {
    const file = e.target.files[0];

    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = (value) => {
      setCurationInfo({
        ...curationInfo,
        file: file,
        fileUrl: value.target.result,
      });
    };
    e.target.value = "";
  };

  const deleteFile = () => {
    setCurationInfo({ ...curationInfo, file: "", fileUrl: "" });
  };

  //저장하기
  const [saveModalOpen, setSaveModalOpen] = useState(false);

  const saveBtnHandler = () => {
    if (
      isNull(curationInfo.title) ||
      isNull(curationInfo.startDate) ||
      isNull(curationInfo.endDate) ||
      isNull(curationInfo.sector) ||
      isNull(curationInfo.age) ||
      isNull(curationInfo.content) ||
      isNull(curationInfo.link) ||
      isNull(curationInfo.file)
    ) {
      return toast("필수사항을 모두 입력해주세요.");
    }

    setSaveModalOpen(true);
  };

  const saveFunc = async () => {
    try {
      const formData = new FormData();
      formData.append("title", curationInfo.title);
      formData.append("image", curationInfo.file);
      formData.append("startDate", curationInfo.startDate);
      formData.append("endDate", curationInfo.endDate);
      formData.append("sector", curationInfo.sector);
      formData.append("region", curationInfo.region);
      formData.append("age", curationInfo.age);
      formData.append("content", curationInfo.content);
      formData.append("link", curationInfo.link);

      await CurationApi.create(formData);
      setSaveModalOpen(false);
      navigate(-1);
      toast(toastMsg.create);
    } catch (error) {
      errorHandler(error);
    }
  };

  // ----------------------------------------------- //
  // ----------------- 셀렉트박스 ----------------- //
  // --------------------------------------------- //
  const [sectorSelectBoxOpen, setSectorSelectBoxOpen] = useState(false);
  const [regionSelectBoxOpen, setRegionSelectBoxOpen] = useState(false);
  const [ageSelectBoxOpen, setAgeSelectBoxOpen] = useState(false);

  const sectorList = [
    { title: "없음", value: "NONE" },
    { title: "음식점업", value: "RESTAURANT" },
    { title: "부동산업", value: "REAL_ESTATE" },
    { title: "전문, 과학 및 기술 서비스업", value: "TECHNOLOGY" },
    { title: "통신판매업", value: "ONLINE" },
    { title: "예술, 스포츠 및 여가 관련 서비스업", value: "ART" },
    { title: "교육 서비스업", value: "EDUCATION" },
    { title: "수리 및 개인 서비스업", value: "PERSONAL" },
  ];

  const regionList = [
    { title: "서울", value: "SEOUL" },
    { title: "경기", value: "GYEONGGI" },
    { title: "인천", value: "INCHEON" },
    { title: "강원", value: "GANGWON" },
    { title: "충북", value: "CHUNGBUK" },
    { title: "충남", value: "CHUNGNAM" },
    { title: "대전", value: "DAEJEON" },
    { title: "세종", value: "SEJONG" },
    { title: "전북", value: "JEONBUK" },
    { title: "전남", value: "JEONNAM" },
    { title: "광주", value: "GWANGJU" },
    { title: "경북", value: "GYEONGBUK" },
    { title: "경남", value: "GYEONGNAM" },
    { title: "대구", value: "DAEGU" },
    { title: "울산", value: "ULSAN" },
    { title: "부산", value: "BUSAN" },
    { title: "제주", value: "JEJU" },
  ];

  const ageList = [
    { title: "20세 이상 ~ 29세 이하", value: "TWENTY" },
    { title: "30세 이상 ~ 39세 이하", value: "THIRTY" },
    { title: "40세 이상 ~ 49세 이하", value: "FORTY" },
    { title: "50대 이상", value: "FIFTY" },
  ];

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
          </DetailTop>
          <DetailPage style={{ minHeight: "825px" }}>
            <DetailPageTitle>추가하기</DetailPageTitle>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "20px" }}
            >
              <DetailPageForm>
                <DetailFormTitle>제목*</DetailFormTitle>
                <MainInput
                  style={{
                    width: "338px",
                  }}
                  type={"text"}
                  placeholder={"제목을 입력해주세요"}
                  value={curationInfo.title}
                  onChange={(e) =>
                    setCurationInfo({ ...curationInfo, title: e.target.value })
                  }
                />
              </DetailPageForm>

              <DetailPageForm>
                <DetailFormTitle>내용*</DetailFormTitle>
                <MainInput
                  style={{
                    width: "730px",
                  }}
                  type={"text"}
                  placeholder={"내용을 입력해주세요"}
                  value={curationInfo.content}
                  onChange={(e) =>
                    setCurationInfo({
                      ...curationInfo,
                      content: e.target.value,
                    })
                  }
                />
              </DetailPageForm>

              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>이미지*</DetailFormTitle>
                {curationInfo.fileUrl ? (
                  <ImgPreview
                    fileUrl={curationInfo.fileUrl}
                    onClick={() => {
                      deleteFile();
                    }}
                  />
                ) : (
                  <ImgInput
                    id={"file"}
                    accept={"image/*"}
                    onChange={(e) => {
                      inputFile(e);
                    }}
                  />
                )}
              </DetailPageForm>

              <DetailPageForm>
                <DetailFormTitle>모집기간*</DetailFormTitle>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <DateInput
                    value={curationInfo.startDate}
                    onChange={(e) =>
                      setCurationInfo({
                        ...curationInfo,
                        startDate: e.target.value,
                      })
                    }
                  />
                  <img src="/assets/admin/icons/ico_date_devider.svg" alt="" />
                  <DateInput
                    value={curationInfo.endDate}
                    onChange={(e) =>
                      setCurationInfo({
                        ...curationInfo,
                        endDate: e.target.value,
                      })
                    }
                  />
                </div>
              </DetailPageForm>

              <DetailPageForm>
                <DetailFormTitle>업종*</DetailFormTitle>
                <div>
                  <div style={{ position: "relative", width: "338px" }}>
                    <SelectBoxWrapper
                      onClick={() => {
                        setSectorSelectBoxOpen(!sectorSelectBoxOpen);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <PretendardText
                        style={{
                          color: "#262C31",
                          fontSize: "15px",
                          fontWeight: "400",
                          lineHeight: "21.25px",
                        }}
                      >
                        {curationInfo.sectorTitle
                          ? curationInfo.sectorTitle
                          : "업종을 선택해 주세요."}
                      </PretendardText>
                      <img
                        src="/assets/admin/icons/ico_select_arrow_down.svg"
                        alt=""
                      />
                    </SelectBoxWrapper>
                    {sectorSelectBoxOpen ? (
                      <SubMenuWrapper>
                        {sectorList.map((item, index) => {
                          return (
                            <SubMenuTextWrapper
                              key={index}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "318px",
                                height: "42px",
                                padding: "10px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setCurationInfo({
                                  ...curationInfo,
                                  sector: item.value,
                                  sectorTitle: item.title,
                                });
                                setSectorSelectBoxOpen(false);
                              }}
                            >
                              <PretendardText
                                style={{
                                  color: "#262C31",
                                  fontSize: "15px",
                                  fontWeight: "500",
                                  lineHeight: "21.5px",
                                }}
                              >
                                {item.title}
                              </PretendardText>
                              {curationInfo.sectorTitle === item.title ? (
                                <img
                                  src="/assets/admin/icons/ico_select_checked.svg"
                                  alt=""
                                />
                              ) : null}
                            </SubMenuTextWrapper>
                          );
                        })}
                      </SubMenuWrapper>
                    ) : null}
                  </div>
                </div>
              </DetailPageForm>

              <DetailPageForm>
                <DetailFormTitle>지역*</DetailFormTitle>
                <div>
                  <div style={{ position: "relative", width: "338px" }}>
                    <SelectBoxWrapper
                      onClick={() => {
                        setRegionSelectBoxOpen(!regionSelectBoxOpen);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <PretendardText
                        style={{
                          color: "#262C31",
                          fontSize: "15px",
                          fontWeight: "400",
                          lineHeight: "21.25px",
                        }}
                      >
                        {curationInfo.regionTitle
                          ? curationInfo.regionTitle
                          : "지역을 선택해 주세요."}
                      </PretendardText>
                      <img
                        src="/assets/admin/icons/ico_select_arrow_down.svg"
                        alt=""
                      />
                    </SelectBoxWrapper>
                    {regionSelectBoxOpen ? (
                      <SubMenuWrapper>
                        {regionList.map((item, index) => {
                          return (
                            <SubMenuTextWrapper
                              key={index}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "318px",
                                height: "42px",
                                padding: "10px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setCurationInfo({
                                  ...curationInfo,
                                  region: item.value,
                                  regionTitle: item.title,
                                });
                                setRegionSelectBoxOpen(false);
                              }}
                            >
                              <PretendardText
                                style={{
                                  color: "#262C31",
                                  fontSize: "15px",
                                  fontWeight: "500",
                                  lineHeight: "21.5px",
                                }}
                              >
                                {item.title}
                              </PretendardText>
                              {curationInfo.regionTitle === item.title ? (
                                <img
                                  src="/assets/admin/icons/ico_select_checked.svg"
                                  alt=""
                                />
                              ) : null}
                            </SubMenuTextWrapper>
                          );
                        })}
                      </SubMenuWrapper>
                    ) : null}
                  </div>
                </div>
              </DetailPageForm>

              <DetailPageForm>
                <DetailFormTitle>나이*</DetailFormTitle>
                <div>
                  <div style={{ position: "relative", width: "338px" }}>
                    <SelectBoxWrapper
                      onClick={() => {
                        setAgeSelectBoxOpen(!ageSelectBoxOpen);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <PretendardText
                        style={{
                          color: "#262C31",
                          fontSize: "15px",
                          fontWeight: "400",
                          lineHeight: "21.25px",
                        }}
                      >
                        {curationInfo.age === ""
                          ? "연령대를 선택해 주세요."
                          : curationInfo.ageTitle}
                      </PretendardText>
                      <img
                        src="/assets/admin/icons/ico_select_arrow_down.svg"
                        alt=""
                      />
                    </SelectBoxWrapper>
                    {ageSelectBoxOpen ? (
                      <SubMenuWrapper>
                        {ageList.map((item, index) => {
                          return (
                            <SubMenuTextWrapper
                              key={index}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "318px",
                                height: "42px",
                                padding: "10px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setCurationInfo({
                                  ...curationInfo,
                                  age: item.value,
                                  ageTitle: item.title,
                                });
                                setAgeSelectBoxOpen(false);
                              }}
                            >
                              <PretendardText
                                style={{
                                  color: "#262C31",
                                  fontSize: "15px",
                                  fontWeight: "500",
                                  lineHeight: "21.5px",
                                }}
                              >
                                {item.title}
                              </PretendardText>
                              {curationInfo.age === item.value ? (
                                <img
                                  src="/assets/admin/icons/ico_select_checked.svg"
                                  alt=""
                                />
                              ) : null}
                            </SubMenuTextWrapper>
                          );
                        })}
                      </SubMenuWrapper>
                    ) : null}
                  </div>
                </div>
              </DetailPageForm>

              <DetailPageForm>
                <DetailFormTitle>링크*</DetailFormTitle>
                <MainInput
                  style={{
                    width: "338px",
                  }}
                  type={"text"}
                  placeholder={"링크를 입력해 주세요"}
                  value={curationInfo.link}
                  onChange={(e) =>
                    setCurationInfo({ ...curationInfo, link: e.target.value })
                  }
                />
              </DetailPageForm>

              <DetailPageForm>
                <DetailFormTitle>게시 상태*</DetailFormTitle>
                <div style={{ display: "flex", gap: "20px" }}>
                  <Radio
                    id={""}
                    valueText={"활성"}
                    checked={curationInfo.activation}
                    onChange={() => {
                      setCurationInfo({
                        ...curationInfo,
                        activation: true,
                      });
                    }}
                  />
                  <Radio
                    id={""}
                    valueText={"비활성"}
                    checked={!curationInfo.activation}
                    onChange={() => {
                      setCurationInfo({
                        ...curationInfo,
                        activation: false,
                      });
                    }}
                  />
                </div>
              </DetailPageForm>
            </div>
            <SaveBtn
              text="등록하기"
              style={{ bottom: "40px", right: "40px" }}
              onClick={() => {
                saveBtnHandler();
              }}
            />
          </DetailPage>
        </DetailPageLayout>
      </MainLayout>

      {saveModalOpen ? (
        <Modal
          title={modalMsg.update?.title}
          text={modalMsg.update?.content}
          setModalOpen={setSaveModalOpen}
          onClick={() => {
            saveFunc();
          }}
        />
      ) : null}
    </>
  );
};

export default CurationPostCreate;

const SelectBoxWrapper = styled.div.attrs((props) => {})`
  width: 338px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px 15px;
  border-radius: 4px;
  border: 1px solid #adb4ba;
  background: #fff;
`;

const SubMenuWrapper = styled.div.attrs((props) => {})`
  width: 338px;
  min-height: 190px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(10, 13, 20, 0.15);
  position: absolute;
  top: 50px;
  right: 0;
  padding: 10px;
  overflow-y: auto;
  z-index: 5;
`;

const SubMenuTextWrapper = styled.div.attrs((props) => {})`
  display: "flex";
  justify-content: "space-between";
  align-items: "center";
  width: "318px";
  height: "42px";
  padding: "10px";
  cursor: "pointer";
  border-radius: 3px;

  &:hover {
    background-color: #f2f4f5;
  }
`;
