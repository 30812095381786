import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { isNull } from "../../../util/check";
import { toast } from "react-toastify";
import { toastMsg } from "../../../util/toastMsg";
import { errorHandler } from "../../../util/errorHandler";
import PressApi from "../../../api/press.api";
import Header from "../../../component/organisms/header";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Sidebar from "../../../component/organisms/sidebar";
import SaveBtn from "../../../component/atoms/button/saveBtn";
import DetailPageLayout from "../../../component/atoms/layout/detail/detailPageLayout";
import DetailTop from "../../../component/atoms/layout/detail/detailTop";
import GoBack from "../../../component/molecules/goBack";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import DetailPageTitle from "../../../component/atoms/text/detail/detailPageTitle";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import ImgPreview from "../../../component/molecules/imgPreview";
import ImgInput from "../../../component/atoms/input/imgInput";
import MainInput from "../../../component/atoms/input/mainInput";
import Radio from "../../../component/atoms/input/radio";
import Modal from "../../../component/templates/Modal";
import { modalMsg } from "../../../util/modalMsg";
import DetailFormText from "./../../../component/atoms/text/detail/detailFormText";
import moment from "moment";
import TextArea from "./../../../component/atoms/input/textarea";
import TermsApi from "../../../api/terms.api";

function GeneralTermsEdit() {
  const navigate = useNavigate();
  let { id } = useParams();

  // mounted
  useEffect(() => {
    getTermsInfo();
  }, []);

  const [termsInfo, setTermsInfo] = useState({
    title: "이용약관", // 약관명
    content:
      "내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용내용", // 내용
  });

  const getTermsInfo = async () => {
    try {
      const response = (await TermsApi.getById(id)).data.data.content;
      setTermsInfo({
        ...response,
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  // ----- 저장
  const [saveModalOpen, setSaveModalOpen] = useState(false);

  const saveFunc = async () => {
    const data = {
      title: termsInfo.title,
      content: termsInfo.content,
    };

    try {
      await TermsApi.update(id, data);
      toast(toastMsg.create);
      setSaveModalOpen(false);
      navigate(-1);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
          </DetailTop>
          <DetailPage style={{ minHeight: "462px" }}>
            <DetailPageTitle>수정하기</DetailPageTitle>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "20px" }}
            >
              <DetailPageForm>
                <DetailFormTitle>약관명*</DetailFormTitle>
                <MainInput
                  style={{
                    width: "338px",
                  }}
                  type={"text"}
                  placeholder={"약관명을 입력해주세요"}
                  value={termsInfo.title}
                  onChange={(e) =>
                    setTermsInfo({ ...termsInfo, title: e.target.value })
                  }
                />
              </DetailPageForm>
              <DetailPageForm
                style={{
                  alignItems: "flex-start",
                }}
              >
                <DetailFormTitle>내용*</DetailFormTitle>
                <TextArea
                  type={"text"}
                  placeholder={"내용을 입력해주세요"}
                  value={termsInfo.content}
                  onChange={(e) =>
                    setTermsInfo({ ...termsInfo, content: e.target.value })
                  }
                  style={{
                    minHeight: "135px",
                  }}
                />
              </DetailPageForm>
            </div>
            <SaveBtn
              text="등록하기"
              style={{ bottom: "40px", right: "40px" }}
              onClick={() => {
                setSaveModalOpen(true);
              }}
            />
          </DetailPage>
        </DetailPageLayout>
      </MainLayout>

      {saveModalOpen ? (
        <Modal
          title={modalMsg.update?.title}
          text={modalMsg.update?.content}
          setModalOpen={setSaveModalOpen}
          onClick={() => {
            saveFunc();
          }}
        />
      ) : null}
    </>
  );
}

export default GeneralTermsEdit;
