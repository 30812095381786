import React, { useEffect, useMemo, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { errorHandler } from "../../../util/errorHandler";
import { toast } from "react-toastify";
import { toastMsg } from "../../../util/toastMsg";
import Header from "./../../../component/organisms/header";
import MainLayout from "./../../../component/atoms/layout/mainLayout";
import Sidebar from "./../../../component/organisms/sidebar";
import DetailPageLayout from "./../../../component/atoms/layout/detail/detailPageLayout";
import DetailTop from "./../../../component/atoms/layout/detail/detailTop";
import GoBack from "./../../../component/molecules/goBack";
import DelBtn from "./../../../component/atoms/button/delBtn";
import EditBtn from "./../../../component/atoms/button/editBtn";
import { route } from "./../../../router/route";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import DetailPageTitle from "./../../../component/atoms/text/detail/detailPageTitle";
import DetailPageForm from "./../../../component/atoms/layout/detail/detailPageForm";
import DetailFormTitle from "./../../../component/atoms/text/detail/detailFormTitle";
import styled from "styled-components";
import TableTab from "./../../../component/organisms/tableTab";
import Table from "./../../../component/atoms/layout/table/table";
import Tr from "./../../../component/atoms/layout/table/tr";
import Th from "./../../../component/atoms/layout/table/th";
import TableText from "./../../../component/atoms/text/table/tableText";
import Td from "./../../../component/atoms/layout/table/td";
import moment from "moment";
import TableFilter from "./../../../component/atoms/layout/table/tableFilter";
import TableDelBtn from "./../../../component/atoms/button/tableDelBtn";
import DetailBtn from "./../../../component/atoms/button/detailBtn";
import Pagination from "./../../../component/molecules/pagination";
import ImgPreview from "../../../component/molecules/imgPreview";
import ImgInput from "../../../component/atoms/input/imgInput";
import MainInput from "./../../../component/atoms/input/mainInput";
import PretendardText from "../../../component/atoms/text/mainText";
import TextArea from "./../../../component/atoms/input/textarea";
import Modal from "../../../component/templates/Modal";
import { modalMsg } from "../../../util/modalMsg";
import FaqModal from "../faqModal";
import CompanyServiceApi from "../../../api/companyService.api";
import ServiceFaqApi from "../../../api/serviceFaq.api";
import { fileUrl } from "./../../../api/api";
import { isNull } from "../../../util/check";
import MarketingApi from "../../../api/marketing.api";

const CompanyServiceManagementEdit = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams(); // eslint-disable-line no-unused-vars
  let { id } = useParams();

  useEffect(() => {
    getServiceInfo();
    getServiceFaqList();
    getMarketingTypeOption();
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  // ------------------------------------------- //
  // ---------- 서비스 정보 가져오기 ---------- //
  // ----------------------------------------- //
  const [serviceInfo, setServiceInfo] = useState({});
  const [marketingTypeInfo, setMarketingTypeInfo] = useState({});
  const [companyServiceList, setCompanyServiceList] = useState([]);
  const [portfolioList, setPortfolioList] = useState([]);
  const [portfolioIdList, setPortfolioIdList] = useState([]);
  const [portfolioDeleteIdList, setPortfolioDeleteIdList] = useState([]);

  const getServiceInfo = async () => {
    try {
      let response = (await CompanyServiceApi.getById(id)).data.data.content;

      setServiceInfo({
        ...response,
        bgImageDeleteFlag: false,
        profileImageDeleteFlag: false,
        detailFileDeleteFlag: false,
      });
      setMarketingTypeInfo(response.marketingType);

      setPortfolioList(
        response.portfolios.map((item) => ({
          id: item.id,
          title: item.merch.title,
        }))
      );

      const data = {
        memberId: response.member.id,
        keyword: searchParams.get("keyword"),
        page: searchParams.get("page"),
        size: pagination.size,
      };
      response = (await CompanyServiceApi.getListById(response.member.id, data))
        .data.data;
      setCompanyServiceList(
        response.content.map((item) => {
          return {
            ...item,
            checked: false,
          };
        })
      );
    } catch (error) {
      errorHandler(error);
    }
  };

  // ------------------------------------------------------ //
  // ----------------- 이미지 추가, 삭제 ----------------- //
  // ---------------------------------------------------- //

  // 배경 이미지
  const inputFileBg = (e) => {
    const file = e.target.files[0];

    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = (value) => {
      setServiceInfo({
        ...serviceInfo,
        bgImage: file,
        bgFileUrl: value.target.result,
        bgImageDeleteFlag: true,
      });
    };
    e.target.value = "";
  };

  const deleteFileBg = () => {
    setServiceInfo({
      ...serviceInfo,
      bgImage: "",
      bgFileUrl: "",
      bgServerFileName: "",
      bgImageDeleteFlag: true,
    });
  };

  // 프로필 이미지
  const inputFileProfile = (e) => {
    const file = e.target.files[0];

    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = (value) => {
      setServiceInfo({
        ...serviceInfo,
        profileImage: file,
        profileFileUrl: value.target.result,
        profileImageDeleteFlag: true,
      });
    };
    e.target.value = "";
  };

  const deleteFileProfile = () => {
    setServiceInfo({
      ...serviceInfo,
      profileImage: "",
      profileFileUrl: "",
      profileServerFileName: "",
      profileImageDeleteFlag: true,
    });
  };

  // 상세페이지 이미지
  const inputFileDetail = (e) => {
    const file = e.target.files[0];

    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = (value) => {
      setServiceInfo({
        ...serviceInfo,
        detailFile: file,
        detailFileUrl: value.target.result,
        detailFileDeleteFlag: true,
      });
    };
    e.target.value = "";
  };

  const deleteFileDetail = () => {
    setServiceInfo({
      ...serviceInfo,
      detailFile: "",
      detailFileUrl: "",
      detailServerFileName: "",
      detailFileDeleteFlag: true,
    });
  };

  // ----------------------------------------------- //
  // ----------------- 셀렉트박스 ----------------- //
  // --------------------------------------------- //
  const [selectBoxOpen, setSelectBoxOpen] = useState(false);
  const [budgetSelectBoxOpen, setBudgetSelectBoxOpen] = useState(false);
  const [periodSelectBoxOpen, setPeriodSelectBoxOpen] = useState(false);
  const [portfolioSelectBoxOpen, setPortfolioSelectBoxOpen] = useState(false);

  // 예산 선택 옵션
  const budgetSelectList = [
    { title: "50만 원 이하", value: "BELOW_500K" },
    { title: "50만 원 초과 ~ 100만 원 이하", value: "FROM_500K_TO_1M" },
    { title: "100만 원 초과 ~ 300만 원 이하", value: "FROM_1M_TO_3M" },
    { title: "300만 원 초과 ~ 500만 원 이하", value: "FROM_3M_TO_5M" },
    { title: "500만 원 초과", value: "ABOVE_5M" },
  ];

  // 집행 기간 선택 옵션
  const periodSelectList = [
    { title: "1개월 이하", value: "BELOW_1_MONTH" },
    { title: "1개월 초과 ~ 3개월 이하", value: "FROM_1_TO_3_MONTHS" },
    { title: "3개월 초과 ~ 6개월 이하", value: "FROM_3_TO_6_MONTHS" },
    { title: "6개월 초과", value: "ABOVE_6_MONTHS" },
  ];

  const [categoryList, setCategoryList] = useState([]);

  const getMarketingTypeOption = async () => {
    try {
      let response = (await MarketingApi.getList()).data.data;

      const data = {
        size: response.totalCount,
      };

      response = (await MarketingApi.getList(data)).data.data.content;

      setCategoryList(
        response.map((item) => {
          return {
            title: item.title,
            value: item.id,
          };
        })
      );
    } catch (error) {
      errorHandler(error);
    }
  };

  const [saveModalOpen, setSaveModalOpen] = useState(false);

  const saveBtnHandler = () => {
    if (
      isNull(serviceInfo.title) ||
      isNull(marketingTypeInfo.title) ||
      isNull(serviceInfo.budget) ||
      isNull(serviceInfo.price) ||
      isNull(serviceInfo.period) ||
      isNull(serviceInfo.intro)
    ) {
      return toast("필수사항을 모두 입력해주세요.");
    }

    setSaveModalOpen(true);
  };

  const saveFunc = async () => {
    try {
      const formData = new FormData();
      formData.append("title", serviceInfo.title);
      formData.append("marketingTypeId", marketingTypeInfo.id);
      formData.append("budget", serviceInfo.budget);
      formData.append("price", serviceInfo.price);
      formData.append("period", serviceInfo.period);
      formData.append("intro", serviceInfo.intro);
      formData.append("createPortfolios", portfolioIdList);
      formData.append("deletePortfolios", portfolioDeleteIdList);
      formData.append("bgImageDeleteFlag", serviceInfo.bgImageDeleteFlag);
      formData.append(
        "profileImageDeleteFlag",
        serviceInfo.profileImageDeleteFlag
      );
      formData.append("detailFileDeleteFlag", serviceInfo.detailFileDeleteFlag);

      if (serviceInfo.bgImage) {
        formData.append("bgImage", serviceInfo.bgImage);
      }
      if (serviceInfo.profileImage) {
        formData.append("profileImage", serviceInfo.profileImage);
      }
      if (serviceInfo.detailFile) {
        formData.append("detailFile", serviceInfo.detailFile);
      }

      await CompanyServiceApi.update(id, formData);
      setSaveModalOpen(false);
      navigate(-1);
      toast(toastMsg.update);
    } catch (error) {
      errorHandler(error);
    }
  };

  // ------------------------------------------ //
  // ---------- 서비스 FAQ 가져오기 ---------- //
  // ---------------------------------------- //
  const page = useMemo(() => {
    return searchParams.get("page") ? searchParams.get("page") : 1;
  }, [searchParams]);

  const [pagination, setPagination] = useState({
    size: 10, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈

    totalCount: 0,
    totalPages: 0,
    first: false,
    last: false,
  });

  const [serviceFaqList, setServiceFaqList] = useState([]);

  const getServiceFaqList = async () => {
    try {
      const data = {
        merchId: id,
        keyword: searchParams.get("keyword"),
        page: searchParams.get("page"),
        size: pagination.size,
      };
      let responseData = (await ServiceFaqApi.getList(id, data)).data.data;
      setServiceFaqList(
        responseData.content.map((item) => {
          return {
            ...item,
            activation: item.activation ? item.activation : false,
          };
        })
      );
      setPagination({
        ...pagination,
        totalCount: responseData.totalCount ? responseData.totalCount : 0,
        totalPages: responseData.totalPage
          ? responseData.totalPage
          : Math.ceil(responseData.totalCount / 10),
        first: responseData.isFirst ? responseData.isFirst : false,
        last: responseData.isLast ? responseData.isLast : false,
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  // 서비스 FAQ 삭제
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [delId, setDelId] = useState([]);

  const deleteFunc = async () => {
    try {
      let data = {
        ids: delId,
      };
      await ServiceFaqApi.delete(data);
      toast(toastMsg.delete);
      setDeleteModalOpen(false);
      setDelId([]);
      getServiceFaqList();
    } catch (error) {
      errorHandler(error);
    }
  };

  // 활성, 비활성
  const [filterOpen, setFilterOpen] = useState(-1);
  const [activationId, setActivationId] = useState(null);
  const [activation, setActivation] = useState(null);
  const [activationModalTitle, setActivationModalTitle] = useState("");
  const [activationModalOpen, setActivationModalOpen] = useState(false);

  const activationFunc = async () => {
    try {
      const data = {
        activation: activation,
      };
      await ServiceFaqApi.updateActivation(activationId, data);
      toast(toastMsg.create);
      setFilterOpen(-1);
      setActivationModalOpen(false);
      getServiceFaqList();
    } catch (error) {
      errorHandler(error);
    }
  };

  // 상세보기
  const [selectedData, setSelectedData] = useState({});
  const [detailModalOpen, setDetailModalOpen] = useState(false);

  useEffect(() => {
    getServiceFaqList();
  }, [detailModalOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <TableTab
            leftTitle="서비스 정보"
            rightTitle="리뷰 관리"
            route={route.service_company_service_detail}
            anotherRoute={route.service_company_service_review}
            id={id}
            isExpert={true}
          />
          <DetailTop>
            <GoBack />
            <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
              <DelBtn />
              <EditBtn text="저장하기" onClick={saveBtnHandler} />
            </div>
          </DetailTop>
          <DetailPage style={{ minHeight: "495px" }}>
            <DetailPageTitle>수정하기</DetailPageTitle>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              {/* 서비스명 */}
              <DetailPageForm>
                <DetailFormTitle>서비스명*</DetailFormTitle>
                <MainInput
                  style={{
                    width: 338,
                  }}
                  type={"text"}
                  placeholder={"서비스명을 입력해주세요"}
                  value={serviceInfo.title}
                  onChange={(e) =>
                    setServiceInfo({
                      ...serviceInfo,
                      title: e.target.value,
                    })
                  }
                />
              </DetailPageForm>

              {/* 배경 이미지 */}
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>배경 이미지*</DetailFormTitle>
                {serviceInfo.bgServerFileName || serviceInfo.bgFileUrl ? (
                  <ImgPreview
                    fileUrl={
                      serviceInfo.bgFileUrl
                        ? serviceInfo.bgFileUrl
                        : `${fileUrl}/${serviceInfo.bgServerFileName}`
                    }
                    onClick={() => {
                      deleteFileBg();
                    }}
                  />
                ) : (
                  <ImgInput
                    id={"file"}
                    accept={"image/*"}
                    onChange={(e) => {
                      inputFileBg(e);
                    }}
                  />
                )}
              </DetailPageForm>

              {/* 프로필 이미지 */}
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>프로필 이미지*</DetailFormTitle>
                {serviceInfo.profileServerFileName ||
                serviceInfo.profileFileUrl ? (
                  <ImgPreview
                    fileUrl={
                      serviceInfo.profileFileUrl
                        ? serviceInfo.profileFileUrl
                        : `${fileUrl}/${serviceInfo.profileServerFileName}`
                    }
                    onClick={() => {
                      deleteFileProfile();
                    }}
                  />
                ) : (
                  <ImgInput
                    id={"file"}
                    accept={"image/*"}
                    onChange={(e) => {
                      inputFileProfile(e);
                    }}
                  />
                )}
              </DetailPageForm>

              {/* 마케팅 종류 */}
              <DetailPageForm>
                <DetailFormTitle>마케팅 종류*</DetailFormTitle>
                <div>
                  <div style={{ position: "relative", width: "338px" }}>
                    <SelectBoxWrapper
                      onClick={() => {
                        setSelectBoxOpen(!selectBoxOpen);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <PretendardText
                        style={{
                          color: "#262C31",
                          fontSize: "15px",
                          fontWeight: "400",
                          lineHeight: "21.25px",
                        }}
                      >
                        {marketingTypeInfo.title
                          ? marketingTypeInfo.title
                          : "마케팅 종류를 선택해 주세요."}
                      </PretendardText>
                      <img
                        src="/assets/admin/icons/ico_select_arrow_down.svg"
                        alt=""
                      />
                    </SelectBoxWrapper>
                    {selectBoxOpen ? (
                      <SubMenuWrapper>
                        {categoryList.map((item, index) => {
                          return (
                            <SubMenuTextWrapper
                              key={index}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "318px",
                                height: "42px",
                                padding: "10px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setMarketingTypeInfo({
                                  ...marketingTypeInfo,
                                  title: item.title,
                                  id: item.value,
                                });
                                setSelectBoxOpen(false);
                              }}
                            >
                              <PretendardText
                                style={{
                                  color: "#262C31",
                                  fontSize: "15px",
                                  fontWeight: "500",
                                  lineHeight: "21.5px",
                                }}
                              >
                                {item.title}
                              </PretendardText>
                              {marketingTypeInfo.title === item.title ? (
                                <img
                                  src="/assets/admin/icons/ico_select_checked.svg"
                                  alt=""
                                />
                              ) : null}
                            </SubMenuTextWrapper>
                          );
                        })}
                      </SubMenuWrapper>
                    ) : null}
                  </div>
                </div>
              </DetailPageForm>

              {/* 예산 */}
              <DetailPageForm>
                <DetailFormTitle>예산*</DetailFormTitle>
                <div>
                  <div style={{ position: "relative", width: "338px" }}>
                    <SelectBoxWrapper
                      onClick={() => {
                        setBudgetSelectBoxOpen(!budgetSelectBoxOpen);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <PretendardText
                        style={{
                          color: "#262C31",
                          fontSize: "15px",
                          fontWeight: "400",
                          lineHeight: "21.25px",
                        }}
                      >
                        {serviceInfo.budgetTitle
                          ? serviceInfo.budgetTitle
                          : "예산을 선택해 주세요."}
                      </PretendardText>
                      <img
                        src="/assets/admin/icons/ico_select_arrow_down.svg"
                        alt=""
                      />
                    </SelectBoxWrapper>
                    {budgetSelectBoxOpen ? (
                      <SubMenuWrapper>
                        {budgetSelectList.map((item, index) => {
                          return (
                            <SubMenuTextWrapper
                              key={index}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "318px",
                                height: "42px",
                                padding: "10px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setServiceInfo({
                                  ...serviceInfo,
                                  budget: item.value,
                                  budgetTitle: item.title,
                                });
                                setBudgetSelectBoxOpen(false);
                              }}
                            >
                              <PretendardText
                                style={{
                                  color: "#262C31",
                                  fontSize: "15px",
                                  fontWeight: "500",
                                  lineHeight: "21.5px",
                                }}
                              >
                                {item.title}
                              </PretendardText>
                              {serviceInfo.budgetTitle === item.title ? (
                                <img
                                  src="/assets/admin/icons/ico_select_checked.svg"
                                  alt=""
                                />
                              ) : null}
                            </SubMenuTextWrapper>
                          );
                        })}
                      </SubMenuWrapper>
                    ) : null}
                  </div>
                </div>
              </DetailPageForm>

              {/* 상품금액 */}
              <DetailPageForm>
                <DetailFormTitle>상품금액*</DetailFormTitle>
                <MainInput
                  style={{
                    width: 338,
                  }}
                  type={"number"}
                  placeholder={"상품금액을 입력해주세요"}
                  value={serviceInfo.price}
                  onChange={(e) =>
                    setServiceInfo({
                      ...serviceInfo,
                      price: e.target.value,
                    })
                  }
                />
              </DetailPageForm>

              {/* 집행 기간 */}
              <DetailPageForm>
                <DetailFormTitle>집행 기간*</DetailFormTitle>
                <div>
                  <div style={{ position: "relative", width: "338px" }}>
                    <SelectBoxWrapper
                      onClick={() => {
                        setPeriodSelectBoxOpen(!periodSelectBoxOpen);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <PretendardText
                        style={{
                          color: "#262C31",
                          fontSize: "15px",
                          fontWeight: "400",
                          lineHeight: "21.25px",
                        }}
                      >
                        {serviceInfo.periodTitle
                          ? serviceInfo.periodTitle
                          : "집행 기간을 선택해 주세요."}
                      </PretendardText>
                      <img
                        src="/assets/admin/icons/ico_select_arrow_down.svg"
                        alt=""
                      />
                    </SelectBoxWrapper>
                    {periodSelectBoxOpen ? (
                      <SubMenuWrapper>
                        {periodSelectList.map((item, index) => {
                          return (
                            <SubMenuTextWrapper
                              key={index}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "318px",
                                height: "42px",
                                padding: "10px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setServiceInfo({
                                  ...serviceInfo,
                                  period: item.value,
                                  periodTitle: item.title,
                                });
                                setPeriodSelectBoxOpen(false);
                              }}
                            >
                              <PretendardText
                                style={{
                                  color: "#262C31",
                                  fontSize: "15px",
                                  fontWeight: "500",
                                  lineHeight: "21.5px",
                                }}
                              >
                                {item.title}
                              </PretendardText>
                              {serviceInfo.periodTitle === item.title ? (
                                <img
                                  src="/assets/admin/icons/ico_select_checked.svg"
                                  alt=""
                                />
                              ) : null}
                            </SubMenuTextWrapper>
                          );
                        })}
                      </SubMenuWrapper>
                    ) : null}
                  </div>
                </div>
              </DetailPageForm>

              {/* 마케팅 전략 소개 */}
              <DetailPageForm
                style={{
                  alignItems: "flex-start",
                }}
              >
                <DetailFormTitle>마케팅 전략 소개*</DetailFormTitle>
                <TextArea
                  type={"text"}
                  placeholder={"마케팅 전략 소개를 입력해주세요"}
                  value={serviceInfo.intro}
                  onChange={(e) => {
                    setServiceInfo({
                      ...serviceInfo,
                      intro: e.target.value,
                    });
                  }}
                  style={{
                    minHeight: "92px",
                  }}
                />
              </DetailPageForm>

              {/* 상세페이지 이미지 */}
              <DetailPageForm
                style={{
                  alignItems: "flex-start",
                }}
              >
                <DetailFormTitle>상세페이지 이미지*</DetailFormTitle>
                {serviceInfo.detailServerFileName ||
                serviceInfo.detailFileUrl ? (
                  <ImgPreview
                    fileUrl={
                      serviceInfo.detailFileUrl
                        ? serviceInfo.detailFileUrl
                        : `${fileUrl}/${serviceInfo.detailServerFileName}`
                    }
                    onClick={() => {
                      deleteFileDetail();
                    }}
                  />
                ) : (
                  <ImgInput
                    id={"detailFile"}
                    accept={"image/*"}
                    onChange={(e) => {
                      inputFileDetail(e);
                    }}
                  />
                )}
              </DetailPageForm>

              {/* 포트폴리오 */}
              <DetailPageForm
                style={{
                  alignItems: "flex-start",
                }}
              >
                <DetailFormTitle>포트폴리오*</DetailFormTitle>
                <div>
                  <div style={{ position: "relative", width: "730px" }}>
                    <SelectBoxWrapper
                      onClick={() => {
                        setPortfolioSelectBoxOpen(!portfolioSelectBoxOpen);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <PretendardText
                        style={{
                          color: "#808991",
                          fontSize: "15px",
                          fontWeight: "400",
                          lineHeight: "21.25px",
                        }}
                      >
                        포트폴리오를 선택해 주세요.
                      </PretendardText>
                      <img
                        src="/assets/admin/icons/ico_select_arrow_down.svg"
                        alt=""
                      />
                    </SelectBoxWrapper>
                    {portfolioList.length > 0 && (
                      <div
                        style={{
                          marginTop: 20,
                          display: "flex",
                          alignItems: "center",
                          flexWrap: "wrap",
                          gap: 10,
                        }}
                      >
                        {portfolioList.map((item, index) => {
                          return (
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: 10,
                                border: "1px solid #B8BFC4",
                                borderRadius: 100,
                                padding: "13px 15px",
                              }}
                            >
                              <PretendardText
                                style={{
                                  color: "#000",
                                  fontSize: "15px",
                                  fontWeight: "400",
                                  lineHeight: "21.25px",
                                }}
                              >
                                {item.title}
                              </PretendardText>
                              <img
                                style={{ cursor: "pointer" }}
                                src="/assets/admin/icons/ico_img_del.svg"
                                alt=""
                                onClick={() => {
                                  setPortfolioList(
                                    portfolioList.filter(
                                      (value) => value.id !== item.id
                                    )
                                  );
                                  setPortfolioDeleteIdList([
                                    ...portfolioDeleteIdList,
                                    item.id,
                                  ]);
                                }}
                              />
                            </div>
                          );
                        })}
                      </div>
                    )}

                    {portfolioSelectBoxOpen ? (
                      <SubMenuWrapper>
                        {companyServiceList.map((item, index) => {
                          return (
                            <SubMenuTextWrapper
                              key={index}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "318px",
                                height: "42px",
                                padding: "10px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                if (
                                  !portfolioList.some(
                                    (portfolio) =>
                                      portfolio.title === item.title
                                  )
                                ) {
                                  setPortfolioList([
                                    ...portfolioList,
                                    { id: item.id, title: item.title },
                                  ]);
                                  setPortfolioIdList([
                                    ...portfolioIdList,
                                    item.id,
                                  ]);
                                }
                                setPortfolioSelectBoxOpen(false);
                              }}
                            >
                              <PretendardText
                                style={{
                                  color: "#262C31",
                                  fontSize: "15px",
                                  fontWeight: "500",
                                  lineHeight: "21.5px",
                                }}
                              >
                                {item.title}
                              </PretendardText>
                              {portfolioList.some(
                                (portfolio) => portfolio.id === item.id
                              ) ? (
                                <img
                                  src="/assets/admin/icons/ico_select_checked.svg"
                                  alt=""
                                />
                              ) : null}
                            </SubMenuTextWrapper>
                          );
                        })}
                      </SubMenuWrapper>
                    ) : null}
                  </div>
                </div>
              </DetailPageForm>
            </div>
          </DetailPage>

          <DetailPage
            style={{
              minHeight: 836,
            }}
          >
            <DetailPageTitle>자주 묻는 질문 관리</DetailPageTitle>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "20px" }}
            >
              <Table
                style={{
                  width: 964,
                }}
              >
                <colgroup>
                  {/* 제목 */}
                  <col width="231px" />
                  {/* 내용 */}
                  <col width="376px" />
                  {/* 작성일 */}
                  <col width="120px" />
                  {/* 게시 상태 */}
                  <col width="89px" />
                  {/* 완전삭제 */}
                  <col width="80px" />
                  {/* 상세보기 */}
                  <col width="68px" />
                </colgroup>
                <thead>
                  <Tr style={{ height: "40px", background: "#f2f4f5" }}>
                    <Th
                      style={{
                        paddingLeft: "20px",
                      }}
                    >
                      <TableText>제목</TableText>
                    </Th>
                    <Th>
                      <TableText>내용</TableText>
                    </Th>
                    <Th>
                      <TableText>작성일</TableText>
                    </Th>
                    <Th>
                      <TableText>게시 상태</TableText>
                    </Th>
                    <Th>
                      <TableText>완전삭제</TableText>
                    </Th>
                    <Th>
                      <TableText>상세보기</TableText>
                    </Th>
                  </Tr>
                </thead>
                <tbody>
                  {serviceFaqList.map((item, index) => {
                    return (
                      <Tr
                        key={index}
                        style={{ height: "59px", background: "#fff" }}
                      >
                        <Td
                          style={{
                            paddingLeft: "20px",
                          }}
                        >
                          {item.title}
                        </Td>
                        <Td>
                          <TableText>{item.content}</TableText>
                        </Td>
                        <Td>
                          <TableText>
                            {moment.utc(item.createAt).format("YYYY/MM/DD")}
                          </TableText>
                        </Td>

                        <Td>
                          <TableFilter
                            style={{
                              width: "fit-content",
                              gap: 10,
                            }}
                            onClick={() => {
                              filterOpen === index
                                ? setFilterOpen(-1)
                                : setFilterOpen(index);
                            }}
                            isOpen={filterOpen === index}
                            selectedValue={item.activation}
                            list={[
                              {
                                value: true,
                                title: "노출",
                                onClick: () => {
                                  setActivationId(item.id);
                                  setActivation(true);
                                  setActivationModalTitle("활성화");
                                  setActivationModalOpen(true);
                                },
                              },
                              {
                                value: false,
                                title: "미노출",
                                onClick: () => {
                                  setActivationId(item.id);
                                  setActivation(false);
                                  setActivationModalTitle("비활성화");
                                  setActivationModalOpen(true);
                                },
                              },
                            ]}
                          />
                        </Td>
                        <Td>
                          <TableDelBtn
                            style={{ marginLeft: "6px" }}
                            onClick={() => {
                              setDelId([item.id]);
                              setDeleteModalOpen(true);
                            }}
                          />
                        </Td>
                        <Td>
                          <DetailBtn
                            onClick={() => {
                              setSelectedData(item);
                              setDetailModalOpen(true);
                            }}
                          />
                        </Td>
                      </Tr>
                    );
                  })}
                </tbody>
              </Table>
              <Pagination
                route={route.service_company_service_detail}
                queryString={`keyword=${searchParams.get("keyword")}` || ""}
                currentPage={page}
                totalPages={pagination.totalPages}
                rangeSize={pagination.rangeSize}
                first={pagination.first}
                last={pagination.last}
              />
            </div>
          </DetailPage>
        </DetailPageLayout>
      </MainLayout>

      {activationModalOpen ? (
        <Modal
          title={`${activationModalTitle} 하시겠습니까?`}
          setModalOpen={setActivationModalOpen}
          onClick={() => {
            activationFunc();
          }}
        />
      ) : null}

      {deleteModalOpen ? (
        <Modal
          title={modalMsg.delete?.title}
          text={modalMsg.delete?.content}
          btnTitle={"삭제하기"}
          setModalOpen={setDeleteModalOpen}
          onClick={() => {
            deleteFunc();
          }}
        />
      ) : null}

      {detailModalOpen ? (
        <FaqModal data={selectedData} setModalOpen={setDetailModalOpen} />
      ) : (
        ""
      )}

      {saveModalOpen ? (
        <Modal
          title={modalMsg.update?.title}
          text={modalMsg.update?.content}
          setModalOpen={setSaveModalOpen}
          onClick={() => {
            saveFunc();
          }}
        />
      ) : null}
    </>
  );
};

export default CompanyServiceManagementEdit;

const SelectBoxWrapper = styled.div.attrs((props) => {})`
  width: 338px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px 15px;
  border-radius: 4px;
  border: 1px solid #adb4ba;
  background: #fff;
`;

const SubMenuWrapper = styled.div.attrs((props) => {})`
  width: 338px;
  min-height: 200px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(10, 13, 20, 0.15);
  position: absolute;
  top: 50px;
  padding: 10px;
  overflow-y: auto;
  z-index: 5;
`;

const SubMenuTextWrapper = styled.div.attrs((props) => {})`
  display: "flex";
  justify-content: "space-between";
  align-items: "center";
  width: "318px";
  height: "42px";
  padding: "10px";
  cursor: "pointer";
  border-radius: 3px;

  &:hover {
    background-color: #f2f4f5;
  }
`;
