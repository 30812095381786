import api from "./api.js";

export default class MemberApi {
  static async create(data) {
    return await api.post(`admin/member`, data);
  }
  static async getCommonList(params) {
    return await api.get(`admin/common/members`, params);
  }
  static async getCompanyList(params) {
    return await api.get(`admin/company/members`, params);
  }
  static async getById(memberId, params) {
    return await api.get(`admin/member/${memberId}`, params);
  }
  static async update(memberId, data) {
    return await api.patch(`admin/member/${memberId}`, data);
  }
  static async updateProfile(memberId, data) {
    return await api.patch(`admin/profile/${memberId}`, data);
  }
  static async delete(data) {
    return await api.del(`admin/members`, data);
  }
  static async updateActivation(memberId, data) {
    return await api.post(`admin/member/activation/${memberId}`, data);
  }
  static async updateApproval(memberId, data) {
    return await api.post(`admin/member/approval/${memberId}`, data);
  }

  static async getMemberCount() {
    return await api.get(`admin/members/count`);
  }

  static async updateUpdateProfile(memberId, data) {
    return await api.patch(`admin/update-profile/${memberId}`, data);
  }
}
