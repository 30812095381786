import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { errorHandler } from "../../../util/errorHandler";
import Header from "./../../../component/organisms/header";
import MainLayout from "./../../../component/atoms/layout/mainLayout";
import Sidebar from "./../../../component/organisms/sidebar";
import DetailPageLayout from "./../../../component/atoms/layout/detail/detailPageLayout";
import DetailTop from "./../../../component/atoms/layout/detail/detailTop";
import GoBack from "./../../../component/molecules/goBack";
import { route } from "./../../../router/route";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import DetailPageTitle from "./../../../component/atoms/text/detail/detailPageTitle";
import DetailPageForm from "./../../../component/atoms/layout/detail/detailPageForm";
import DetailFormText from "./../../../component/atoms/text/detail/detailFormText";
import DetailFormTitle from "./../../../component/atoms/text/detail/detailFormTitle";
import TableTab from "./../../../component/organisms/tableTab";
import CompanyServiceApi from "../../../api/companyService.api";
import {
  formatBusinessNumber,
  formatTelNumber,
} from "./../../../util/formatNumber";

const DeleteServiceDetail = () => {
  let { id } = useParams();

  useEffect(() => {
    getCompanyInfo();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [companyInfo, setCompanyInfo] = useState({});
  const [companyNumberIsNumeric, setCompanyNumberIsNumeric] = useState(false);
  const [telIsNumeric, setTelIsNumeric] = useState(false);

  const getCompanyInfo = async () => {
    try {
      let response = (await CompanyServiceApi.getRemoveListById(id)).data.data
        .content[0].member.profile;
      setCompanyNumberIsNumeric(
        typeof response.companyNumber === "string" &&
          /^[0-9-]+$/.test(response.companyNumber)
      );
      setTelIsNumeric(
        typeof response.tel === "string" && /^[0-9-]+$/.test(response.tel)
      );
      setCompanyInfo(response);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <TableTab
            leftTitle="전문가 정보"
            rightTitle="서비스 관리"
            route={route.service_delete_detail}
            anotherRoute={route.service_delete_service}
            id={id}
            isExpert={true}
          />
          <DetailTop>
            <GoBack />
          </DetailTop>
          <DetailPage style={{ minHeight: "495px" }}>
            <DetailPageTitle>기본정보</DetailPageTitle>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "20px" }}
            >
              {/* 기업명 */}
              <DetailPageForm>
                <DetailFormTitle>기업명</DetailFormTitle>
                <DetailFormText>{companyInfo.companyName}</DetailFormText>
              </DetailPageForm>

              {/* 지역 */}
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>지역</DetailFormTitle>
                <DetailFormText>{companyInfo.regionTitle}</DetailFormText>
              </DetailPageForm>

              {/* 사업장 소재지 */}
              <DetailPageForm>
                <DetailFormTitle>사업장 소재지</DetailFormTitle>
                <DetailFormText>{companyInfo.companyRegion}</DetailFormText>
              </DetailPageForm>

              {/* 사업자번호 */}
              <DetailPageForm>
                <DetailFormTitle>사업자번호</DetailFormTitle>
                <DetailFormText>
                  {" "}
                  {companyNumberIsNumeric
                    ? formatBusinessNumber(companyInfo.companyNumber)
                    : companyInfo.companyNumber}
                </DetailFormText>
              </DetailPageForm>

              {/* 대표자명 */}
              <DetailPageForm>
                <DetailFormTitle>대표자명</DetailFormTitle>
                <DetailFormText>{companyInfo.ceo}</DetailFormText>
              </DetailPageForm>

              {/* 전화번호 */}
              <DetailPageForm>
                <DetailFormTitle>전화번호</DetailFormTitle>
                <DetailFormText>
                  {" "}
                  {telIsNumeric
                    ? formatTelNumber(companyInfo.tel)
                    : companyInfo.tel}
                </DetailFormText>
              </DetailPageForm>

              {/* 기업 소개 */}
              <DetailPageForm>
                <DetailFormTitle>기업 소개</DetailFormTitle>
                <DetailFormText>
                  {" "}
                  <div
                    className="ql-editor"
                    dangerouslySetInnerHTML={{
                      __html: companyInfo.intro,
                    }}
                  />
                </DetailFormText>
              </DetailPageForm>
            </div>
          </DetailPage>
        </DetailPageLayout>
      </MainLayout>
    </>
  );
};

export default DeleteServiceDetail;
