import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

function TextArea({
  type,
  placeholder,
  value,
  onChange,
  disabled,
  style,
  maxLength,
  isInfinity,
}) {
  const textarea = useRef();
  const handleResizeHeight = () => {
    textarea.current.style.height = "auto";
    textarea.current.style.height = textarea.current.scrollHeight + "px";
    if (isInfinity || textarea.current.scrollHeight < 192) {
      textarea.current.style.padding = "13px 15px";
      textarea.current.style.overflowY = "hidden";
    } else {
      textarea.current.style.overflowY = "auto";
      textarea.current.style.padding = "13px 0px 13px 15px";
    }
  };

  return (
    <>
      <TextAreaWrapper
        isInfinity={isInfinity}
        height={textarea.current?.scrollHeight}
        ref={textarea}
        rows={1}
        type={type}
        disabled={disabled}
        placeholder={placeholder}
        value={value}
        onChange={(e) => {
          if (e.target.value.length > maxLength) {
            e.target.value = e.target.value.slice(0, maxLength);
          }
          handleResizeHeight();
          onChange(e);
        }}
        style={{
          ...style,
        }}
      />
    </>
  );
}

export default TextArea;

const TextAreaWrapper = styled.textarea.attrs((props) => {})`
  width: 744px;
  max-height: ${(props) => (props.isInfinity ? "unset" : "192px")};
  padding: 13px 15px;
  border-radius: 4px;
  border: 1px solid #b8bfc4;
  background: #fff;
  font-family: Pretendard;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 21.25px;
  color: #262c31;
  resize: none;

  &::placeholder {
    font-family: Pretendard;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 21.25px;
    color: #808991;
  }

  &:focus {
    outline: none;
    border: 1px solid var(--sub-color-3, #27beff);
  }

  &::-webkit-scrollbar {
    width: 15px;
  }

  &::-webkit-scrollbar-thumb {
    background: #b0b8c1; /* 스크롤바의 색상 */
    border-radius: 9px;
    background-clip: padding-box;
    border: 6px solid transparent;
  }

  &::-webkit-scrollbar-track {
    background: transparent; /*스크롤바 뒷 배경 색상*/
  }
`;
