import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { toastMsg } from "../../../util/toastMsg";
import { errorHandler } from "../../../util/errorHandler";
import Header from "../../../component/organisms/header";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Sidebar from "../../../component/organisms/sidebar";
import SaveBtn from "../../../component/atoms/button/saveBtn";
import DetailPageLayout from "../../../component/atoms/layout/detail/detailPageLayout";
import DetailTop from "../../../component/atoms/layout/detail/detailTop";
import GoBack from "../../../component/molecules/goBack";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import DetailPageTitle from "../../../component/atoms/text/detail/detailPageTitle";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import MainInput from "../../../component/atoms/input/mainInput";
import Modal from "../../../component/templates/Modal";
import { modalMsg } from "../../../util/modalMsg";
import TextArea from "./../../../component/atoms/input/textarea";
import OpenSourceApi from "../../../api/openSource.api";

function GeneralOpenSourceEdit() {
  const navigate = useNavigate();
  let { id } = useParams();

  // mounted
  useEffect(() => {
    getOpenSourceInfo();
  }, []);

  // ----- 정보 가져오기
  const [openSourceInfo, setOpenSourceInfo] = useState({
    title: "",
    content: "",
  });

  const getOpenSourceInfo = async () => {
    try {
      const response = (await OpenSourceApi.getById(id)).data.data.content;
      setOpenSourceInfo(response);
    } catch (error) {
      errorHandler(error);
    }
  };

  // ----- 저장
  const [saveModalOpen, setSaveModalOpen] = useState(false);

  const saveFunc = async () => {
    const data = {
      title: openSourceInfo.title,
      content: openSourceInfo.content,
    };

    try {
      await OpenSourceApi.update(id, data);
      toast(toastMsg.create);
      setSaveModalOpen(false);
      navigate(-1);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
          </DetailTop>
          <DetailPage style={{ minHeight: "462px" }}>
            <DetailPageTitle>수정하기</DetailPageTitle>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "20px" }}
            >
              <DetailPageForm>
                <DetailFormTitle>오픈소스명*</DetailFormTitle>
                <MainInput
                  style={{
                    width: "338px",
                  }}
                  type={"text"}
                  placeholder={"오픈소스명을 입력해주세요"}
                  value={openSourceInfo.title}
                  onChange={(e) =>
                    setOpenSourceInfo({
                      ...openSourceInfo,
                      title: e.target.value,
                    })
                  }
                />
              </DetailPageForm>
              <DetailPageForm
                style={{
                  alignItems: "flex-start",
                }}
              >
                <DetailFormTitle>오픈소스 내용*</DetailFormTitle>
                <TextArea
                  type={"text"}
                  placeholder={"오픈소스 내용을 입력해주세요"}
                  value={openSourceInfo.content}
                  onChange={(e) =>
                    setOpenSourceInfo({
                      ...openSourceInfo,
                      content: e.target.value,
                    })
                  }
                  style={{
                    minHeight: "135px",
                  }}
                />
              </DetailPageForm>
            </div>
            <SaveBtn
              text="저장하기"
              style={{ bottom: "40px", right: "40px" }}
              onClick={() => {
                setSaveModalOpen(true);
              }}
            />
          </DetailPage>
        </DetailPageLayout>
      </MainLayout>
      {saveModalOpen ? (
        <Modal
          title={modalMsg.update?.title}
          text={modalMsg.update?.content}
          setModalOpen={setSaveModalOpen}
          onClick={() => {
            saveFunc();
          }}
        />
      ) : null}
    </>
  );
}

export default GeneralOpenSourceEdit;
