import api from "./api.js";

export default class CompanyServiceApi {
  static async getList(params) {
    return await api.get(`admin/company/members/merch`, params); // 기업 리스트
  }
  static async create(data) {
    return await api.post(`member/merch`, data); // 서비스 등록
  }
  static async getListById(memberId, params) {
    return await api.get(`admin/merches/${memberId}`, params); // 서비스 목록 조회
  }
  static async getRemoveList(params) {
    return await api.get(`admin/company/members/merch/remove`, params); // 기업 리스트
  }
  static async getRemoveListById(memberId, params) {
    return await api.get(`admin/merches/remove/${memberId}`, params); // 삭제된 서비스 목록 조회
  }
  static async getById(merchId, params) {
    return await api.get(`admin/merch/${merchId}`, params); // 서비스 단일 조회
  }
  static async update(merchId, data) {
    return await api.patch(`admin/merch/${merchId}`, data); // 서비스 수정
  }
  static async updateViewFlag(memberId, data) {
    return await api.post(`admin/member/activation/view/${memberId}`, data); // 게시상태 활성/비활성
  }
  static async updateActivation(memberId, data) {
    return await api.post(`admin/merch/activation/${memberId}`, data); // 서비스 활성/비활성
  }
  static async delete(data) {
    return await api.del(`admin/merchs/soft`, data); // 서비스 삭제 상태로 변경
  }
  static async deleteAll(memberId, params) {
    return await api.del(`admin/merchs/soft/${memberId}`, params); // 서비스 전부 삭제 상태로 변경
  }
  static async deleteHard(data) {
    return await api.del(`admin/merchs`, data); // 서비스 완전 삭제
  }
  static async deleteHardAll(memberId, params) {
    return await api.del(`admin/merchs/${memberId}`, params); // 서비스 전부 완전 삭제
  }
}
