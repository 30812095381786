import React from "react";
import styled from "styled-components";
import MainText from "../mainText";

const TableText = (props) => {
  return <TableTextWrapper {...props} />;
};

export default TableText;

const TableTextWrapper = styled(MainText).attrs((props) => {})`
  color: #778088;
  font-size: 14px;
  font-weight: 500;
  line-height: 22.75px;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
`;
