import { Routes, Route } from "react-router-dom";
import React from "react";
import { route } from "./route";
import Login from "../pages/login/login";
import Join from "../pages/join/join";
import JoinComplete from "../pages/join/joinComplete";
import Home from "../pages/home/home";
import Manager from "../pages/manager/manager";
import ManagerDetail from "../pages/manager/managerDetail";
import RootEdit from "../pages/manager/rootEdit";
import AdminEdit from "../pages/manager/adminEdit";
import PaymentCancel from "../pages/payment/paymentCancel/paymentCancel";
import PaymentCancelDetail from "../pages/payment/paymentCancel/paymentCancelDetail";
import PaymentHistory from "../pages/payment/paymentHistory/paymentHistory";
import PaymentHistoryDetail from "../pages/payment/paymentHistory/paymentHistoryDetail";
import PaymentRequest from "./../pages/payment/paymentRequest/paymentRequest";
import PaymentRequestDetail from "../pages/payment/paymentRequest/paymentRequestDetail";
import ChatHistory from "../pages/chat/chatHistory/chatHistory";
import ChatHistoryDetail from "../pages/chat/chatHistory/chatHistoryDetail";
import ChatReport from "../pages/chat/chatReport/chatReport";
import GeneralAlarm from "./../pages/general/generalAlarm/generalAlarm";
import GeneralAlarmDetail from "./../pages/general/generalAlarm/generalAlarmDetail";
import GeneralAlarmCreate from "../pages/general/generalAlarm/generalAlarmCreate";
import GeneralAsk from "./../pages/general/generalAsk/generalAsk";
import GeneralNotice from "../pages/general/generalNotice/generalNotice";
import GeneralNoticeDetail from "../pages/general/generalNotice/generalNoticeDetail";
import GeneralNoticeCreate from "../pages/general/generalNotice/generalNoticeCreate";
import GeneralPopup from "./../pages/general/generalPopup/generalPopup";
import GeneralPopupDetail from "./../pages/general/generalPopup/generalPopupDetail";
import GeneralPopupEdit from "./../pages/general/generalPopup/generalPopupEdit";
import CommonMember from "../pages/user/commonMember/commonMember";
import CommonMemberDetail from "./../pages/user/commonMember/commonMemberDetail";
import CommonMemberEdit from "./../pages/user/commonMember/commonMemberEdit";
import CompanyMember from "./../pages/user/companyMember/companyMember";
import CompanyMemberDetail from "./../pages/user/companyMember/companyMemberDetail";
import CompanyMemberEdit from "./../pages/user/companyMember/companyMemberEdit";
import LeaveMember from "./../pages/user/leaveMember/leaveMember";
import CompanyService from "../pages/service/companyService/companyService";
import CompanyServiceDetail from "./../pages/service/companyService/companyServiceDetail";
import CompanyServiceManagement from "./../pages/service/companyService/companyServiceManagement";
import CompanyServiceManagementDetail from "./../pages/service/companyService/companyServiceManagementDetail";
import CompanyServiceManagementEdit from "./../pages/service/companyService/companyServiceManagementEdit";
import CompanyServiceManagementReview from "./../pages/service/companyService/companyServiceManagementReview";
import DeleteService from "./../pages/service/deleteService/deleteService";
import DeleteServiceDetail from "./../pages/service/deleteService/deleteServiceDetail";
import DeleteServiceManagement from "./../pages/service/deleteService/deleteServiceManagement";
import DeleteServiceManagementDetail from "./../pages/service/deleteService/deleteServiceManagementDetail";
import DeleteServiceManagementReview from "./../pages/service/deleteService/deleteServiceManagementReview";
import MarketingType from "./../pages/service/marketingType/marketingType";
import MarketingTypeDetail from "./../pages/service/marketingType/marketingTypeDetail";
import MarketingTypeEdit from "./../pages/service/marketingType/marketingTypeEdit";
import MarketingTypeCreate from "./../pages/service/marketingType/marketingTypeCreate";
import GeneralFaq from "./../pages/general/generalFaq/generalFaq";
import GeneralFaqCreate from "./../pages/general/generalFaq/generalFaqCreate";
import GeneralFaqDetail from "./../pages/general/generalFaq/generalFaqDetail";
import GeneralFaqEdit from "./../pages/general/generalFaq/generalFaqEdit";
import GeneralTerms from "./../pages/general/generalTerms/generalTerms";
import GeneralTermsDetail from "./../pages/general/generalTerms/generalTermsDetail";
import GeneralTermsEdit from "./../pages/general/generalTerms/generalTermsEdit";
import GeneralOpenSource from "./../pages/general/generalOpenSource/generalOpenSource";
import GeneralOpenSourceDetail from "./../pages/general/generalOpenSource/generalOpenSourceDetail";
import GeneralOpenSourceEdit from "./../pages/general/generalOpenSource/generalOpenSourceEdit";
import GeneralBasic from "./../pages/general/generalBasic/generalBasic";
import GeneralBasicEdit from "./../pages/general/generalBasic/generalBasicEdit";
import CurationPost from "./../pages/curation/curationPost";
import CurationPostDetail from "./../pages/curation/curationPostDetail";
import CurationPostEdit from "./../pages/curation/curationPostEdit";
import CurationPostCreate from "./../pages/curation/curationPostCreate";
import GeneralPopupCreate from "./../pages/general/generalPopup/generalPopupCreate";
import PaymentMethod from "./../pages/payment/paymentMethod/paymentMethod";

const Router = () => {
  return (
    <Routes>
      {/* ------------------------- */}
      {/* ---------- 기본 ---------- */}
      {/* ------------------------- */}
      <Route path={route.default} element={<Login />} />

      {/* --------------------------- */}
      {/* ---------- 로그인 ---------- */}
      {/* --------------------------- */}
      <Route path={route.login} element={<Login />} />
      <Route path={route.join} element={<Join />} />
      <Route path={route.join_complete} element={<JoinComplete />} />

      {/* ----------------------- */}
      {/* ---------- 홈 ---------- */}
      {/* ----------------------- */}
      <Route path={route.home} element={<Home />} />

      {/* ------------------------------ */}
      {/* ---------- 사용자 관리 ---------- */}
      {/* ------------------------------ */}

      {/* 일반회원 관리 */}
      <Route path={route.user_common_member} element={<CommonMember />} />
      <Route
        path={route.user_common_member_detail + "/:id"}
        element={<CommonMemberDetail />}
      />
      <Route
        path={route.user_common_member_edit + "/:id"}
        element={<CommonMemberEdit />}
      />

      {/* 전문가 회원 관리 */}
      <Route path={route.user_company_member} element={<CompanyMember />} />
      <Route
        path={route.user_company_member_detail + "/:id"}
        element={<CompanyMemberDetail />}
      />
      <Route
        path={route.user_company_member_edit + "/:id"}
        element={<CompanyMemberEdit />}
      />

      {/* 탈퇴 회원 관리 */}
      <Route path={route.user_leave_member} element={<LeaveMember />} />

      {/* ------------------------------ */}
      {/* ---------- 서비스 관리 ---------- */}
      {/* ------------------------------ */}

      {/* 전문가 서비스 관리 */}
      <Route path={route.service_company} element={<CompanyService />} />
      <Route
        path={route.service_company_detail + "/:id"}
        element={<CompanyServiceDetail />}
      />
      <Route
        path={route.service_company_service + "/:id"}
        element={<CompanyServiceManagement />}
      />
      <Route
        path={route.service_company_service_detail + "/:id"}
        element={<CompanyServiceManagementDetail />}
      />
      <Route
        path={route.service_company_service_edit + "/:id"}
        element={<CompanyServiceManagementEdit />}
      />
      <Route
        path={route.service_company_service_review + "/:id"}
        element={<CompanyServiceManagementReview />}
      />

      {/* 삭제된 서비스 관리 */}
      <Route path={route.service_delete} element={<DeleteService />} />
      <Route
        path={route.service_delete_detail + "/:id"}
        element={<DeleteServiceDetail />}
      />
      <Route
        path={route.service_delete_service + "/:id"}
        element={<DeleteServiceManagement />}
      />
      <Route
        path={route.service_delete_service_detail + "/:id"}
        element={<DeleteServiceManagementDetail />}
      />
      <Route
        path={route.service_delete_service_review + "/:id"}
        element={<DeleteServiceManagementReview />}
      />

      {/* 마케팅 종류 관리 */}
      <Route path={route.service_marketing} element={<MarketingType />} />
      <Route
        path={route.service_marketing_detail + "/:id"}
        element={<MarketingTypeDetail />}
      />
      <Route
        path={route.service_marketing_edit + "/:id"}
        element={<MarketingTypeEdit />}
      />
      <Route
        path={route.service_marketing_create}
        element={<MarketingTypeCreate />}
      />

      {/* ------------------------------ */}
      {/* ---------- 결제 관리 ---------- */}
      {/* ------------------------------ */}

      {/* 결제 수단 관리 */}
      <Route path={route.payment_method} element={<PaymentMethod />} />

      {/* 결제 내역 관리 */}
      <Route path={route.payment_history} element={<PaymentHistory />} />
      <Route
        path={route.payment_history_detail + "/:id"}
        element={<PaymentHistoryDetail />}
      />

      {/* 취소/환불 내역 관리 */}
      <Route path={route.payment_cancel} element={<PaymentCancel />} />
      <Route
        path={route.payment_cancel_detail + "/:id"}
        element={<PaymentCancelDetail />}
      />

      {/* 정산 요청 관리 */}
      <Route path={route.payment_request} element={<PaymentRequest />} />
      <Route
        path={route.payment_request_detail + "/:id"}
        element={<PaymentRequestDetail />}
      />

      {/* ------------------------------ */}
      {/* ---------- 채팅 관리 ---------- */}
      {/* ------------------------------ */}

      {/* 채팅 내역 관리 */}
      <Route path={route.chat_history} element={<ChatHistory />} />
      <Route
        path={route.chat_history_detail + "/:id"}
        element={<ChatHistoryDetail />}
      />

      {/* 신고 내역 관리 */}
      <Route path={route.chat_report} element={<ChatReport />} />

      {/* ---------------------------------- */}
      {/* ---------- 큐레이션 관리 ---------- */}
      {/* ---------------------------------- */}
      <Route path={route.curation_post} element={<CurationPost />} />
      <Route
        path={route.curation_post_detail + "/:id"}
        element={<CurationPostDetail />}
      />
      <Route
        path={route.curation_post_edit + "/:id"}
        element={<CurationPostEdit />}
      />
      <Route
        path={route.curation_post_create}
        element={<CurationPostCreate />}
      />

      {/* ------------------------------ */}
      {/* ---------- 일반 관리 ---------- */}
      {/* ------------------------------ */}

      {/* 알람 관리 */}
      <Route path={route.general_alarm} element={<GeneralAlarm />} />
      <Route
        path={route.general_alarm_detail + "/:id"}
        element={<GeneralAlarmDetail />}
      />
      <Route
        path={route.general_alarm_create}
        element={<GeneralAlarmCreate />}
      />

      {/* 1:1 문의 관리 */}
      <Route path={route.general_ask} element={<GeneralAsk />} />

      {/* 공지사항 관리 */}
      <Route path={route.general_notice} element={<GeneralNotice />} />
      <Route
        path={route.general_notice_detail + "/:id"}
        element={<GeneralNoticeDetail />}
      />
      <Route
        path={route.general_notice_create}
        element={<GeneralNoticeCreate />}
      />

      {/* FAQ 관리 */}
      <Route path={route.general_faq} element={<GeneralFaq />} />
      <Route
        path={route.general_faq_detail + "/:id"}
        element={<GeneralFaqDetail />}
      />
      <Route path={route.general_faq_create} element={<GeneralFaqCreate />} />
      <Route
        path={route.general_faq_edit + "/:id"}
        element={<GeneralFaqEdit />}
      />

      {/* 팝업 관리 */}
      <Route path={route.general_popup} element={<GeneralPopup />} />
      <Route
        path={route.general_popup_create}
        element={<GeneralPopupCreate />}
      />
      <Route
        path={route.general_popup_detail + "/:id"}
        element={<GeneralPopupDetail />}
      />
      <Route
        path={route.general_popup_edit + "/:id"}
        element={<GeneralPopupEdit />}
      />

      {/* 약관 관리 */}
      <Route path={route.general_terms} element={<GeneralTerms />} />
      <Route
        path={route.general_terms_detail + "/:id"}
        element={<GeneralTermsDetail />}
      />
      <Route
        path={route.general_terms_edit + "/:id"}
        element={<GeneralTermsEdit />}
      />

      {/* 오픈소스 관리 */}
      <Route path={route.general_open_source} element={<GeneralOpenSource />} />
      <Route
        path={route.general_open_source_detail + "/:id"}
        element={<GeneralOpenSourceDetail />}
      />
      <Route
        path={route.general_open_source_edit + "/:id"}
        element={<GeneralOpenSourceEdit />}
      />

      {/* 기본 관리 */}
      <Route path={route.general_basic} element={<GeneralBasic />} />
      <Route path={route.general_basic_edit} element={<GeneralBasicEdit />} />

      {/* ------------------------------------ */}
      {/* ---------- 관리자회원 관리 ---------- */}
      {/* ------------------------------------ */}
      <Route path={route.manager} element={<Manager />} />
      <Route path={route.manager_detail + "/:id"} element={<ManagerDetail />} />
      <Route path={route.manager_edit_root + "/:id"} element={<RootEdit />} />
      <Route path={route.manager_edit_admin + "/:id"} element={<AdminEdit />} />
    </Routes>
  );
};

export default Router;
