import React, { useEffect } from "react";
import { route } from "../../router/route";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ManagerApi from "../../api/manager.api";
import { toast } from "react-toastify";
import { errorHandler } from "../../util/errorHandler";

function LoginProcess() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const autoLoginFunc = async () => {
    try {
      const response = await ManagerApi.getMyInfo();
      sessionStorage.setItem("approval", response.data.data.content.approval);
      sessionStorage.setItem("id", response.data.data.content.id);
      sessionStorage.setItem("name", response.data.data.content.name);
      sessionStorage.setItem(
        "accessMenu",
        response.data.data.content.accessMenu
      );
      sessionStorage.setItem("role", response.data.data.content.role);
      sessionStorage.setItem("email", response.data.data.content.email);
    } catch (error) {
      if (error.response.status === 401) {
        return toast(`${error.response.data.message}`);
      }
      if (error.response.status === 404) {
        return toast(`존재하지 않는 회원입니다.`);
      }
      errorHandler(error);
    }
  };

  useEffect(() => {
    const allRefresh = searchParams.get("refresh");
    if (
      !allRefresh &&
      window.location.pathname !== "/" &&
      window.location.pathname !== route.login
    ) {
      autoLoginFunc();
    }
  }, []);

  return null;
}

export default LoginProcess;
