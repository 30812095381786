import React, { useEffect, useMemo, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { errorHandler } from "../../../util/errorHandler";
import { toast } from "react-toastify";
import { toastMsg } from "../../../util/toastMsg";
import Header from "./../../../component/organisms/header";
import MainLayout from "./../../../component/atoms/layout/mainLayout";
import Sidebar from "./../../../component/organisms/sidebar";
import DetailPageLayout from "./../../../component/atoms/layout/detail/detailPageLayout";
import DetailTop from "./../../../component/atoms/layout/detail/detailTop";
import GoBack from "./../../../component/molecules/goBack";
import DelBtn from "./../../../component/atoms/button/delBtn";
import { route } from "./../../../router/route";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import DetailPageTitle from "./../../../component/atoms/text/detail/detailPageTitle";
import DetailPageForm from "./../../../component/atoms/layout/detail/detailPageForm";
import DetailFormText from "./../../../component/atoms/text/detail/detailFormText";
import DetailFormTitle from "./../../../component/atoms/text/detail/detailFormTitle";
import TableTab from "./../../../component/organisms/tableTab";
import FileImgPreview from "./../../../component/molecules/fileImgPreview";
import Table from "./../../../component/atoms/layout/table/table";
import Tr from "./../../../component/atoms/layout/table/tr";
import Th from "./../../../component/atoms/layout/table/th";
import TableText from "./../../../component/atoms/text/table/tableText";
import Td from "./../../../component/atoms/layout/table/td";
import moment from "moment";
import TableFilter from "./../../../component/atoms/layout/table/tableFilter";
import TableDelBtn from "./../../../component/atoms/button/tableDelBtn";
import DetailBtn from "./../../../component/atoms/button/detailBtn";
import Pagination from "./../../../component/molecules/pagination";
import ServiceFaqApi from "../../../api/serviceFaq.api";
import CompanyServiceApi from "../../../api/companyService.api";
import { fileUrl } from "./../../../api/api";
import { formatNumberWithCommas } from "./../../../util/formatNumber";
import Modal from "../../../component/templates/Modal";
import { modalMsg } from "../../../util/modalMsg";
import DeleteFaqModal from "../deleteFaqModal";

const DeleteServiceManagementDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams(); // eslint-disable-line no-unused-vars
  let { id } = useParams();

  useEffect(() => {
    getServiceInfo();
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  // ----------------------------------------- //
  // ---------- 서비스 정보 가져오기 ---------- //
  // --------------------------------------- //
  const page = useMemo(() => {
    return searchParams.get("page") ? searchParams.get("page") : 1;
  }, [searchParams]);

  const [pagination, setPagination] = useState({
    size: 10, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈

    totalCount: 0,
    totalPages: 0,
    first: false,
    last: false,
  });

  const [serviceInfo, setServiceInfo] = useState({});
  const [marketingTypeInfo, setMarketingTypeInfo] = useState({});
  const [portfolioList, setPortfolioList] = useState([]);
  const [serviceFaqList, setServiceFaqList] = useState([]);

  const getServiceInfo = async () => {
    try {
      let response;

      // 서비스 정보 가져오기
      response = (await CompanyServiceApi.getById(id)).data.data.content;

      setServiceInfo(response);
      setMarketingTypeInfo(response.marketingType);
      setPortfolioList(response.portfolios);

      // 서비스 FAQ 가져오기
      const data = {
        memberId: response.member.id,
        keyword: searchParams.get("keyword"),
        page: searchParams.get("page"),
        size: pagination.size,
      };
      response = (await ServiceFaqApi.getList(data.memberId, data)).data.data;
      setServiceFaqList(
        response.content.map((item) => {
          return {
            ...item,
            activation: item.activation ? item.activation : false,
          };
        })
      );
      setPagination({
        ...pagination,
        totalCount: response.totalCount ? response.totalCount : 0,
        totalPages: response.totalPage
          ? response.totalPage
          : Math.ceil(response.totalCount / 10),
        first: response.isFirst ? response.isFirst : false,
        last: response.isLast ? response.isLast : false,
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  // 전문가 서비스 삭제
  const [serviceDeleteModalOpen, setServiceDeleteModalOpen] = useState(false);
  const [delServiceId, setDelServiceId] = useState([]);

  const deleteServiceFunc = async () => {
    try {
      let data = {
        ids: delServiceId,
      };
      await CompanyServiceApi.deleteHard(data);
      toast(toastMsg.delete);
      setDeleteModalOpen(false);
      setDelServiceId([]);
      navigate(-1);
    } catch (error) {
      errorHandler(error);
    }
  };

  // 서비스 FAQ 삭제
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [delId, setDelId] = useState([]);

  const deleteFunc = async () => {
    try {
      let data = {
        ids: delId,
      };
      await ServiceFaqApi.delete(data);
      toast(toastMsg.delete);
      setDeleteModalOpen(false);
      setDelId([]);
    } catch (error) {
      errorHandler(error);
    }
  };

  // 활성, 비활성
  const [filterOpen, setFilterOpen] = useState(-1);
  const [activationId, setActivationId] = useState(null);
  const [activation, setActivation] = useState(null);
  const [activationModalTitle, setActivationModalTitle] = useState("");
  const [activationModalOpen, setActivationModalOpen] = useState(false);

  const activationFunc = async () => {
    try {
      const data = {
        activation: activation,
      };
      await ServiceFaqApi.updateActivation(activationId, data);
      toast(toastMsg.create);
      setFilterOpen(-1);
      setActivationModalOpen(false);
    } catch (error) {
      errorHandler(error);
    }
  };

  // 상세보기
  const [selectedData, setSelectedData] = useState({});
  const [detailModalOpen, setDetailModalOpen] = useState(false);

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <TableTab
            leftTitle="서비스 정보"
            rightTitle="리뷰 관리"
            route={route.service_delete_service_detail}
            anotherRoute={route.service_delete_service_review}
            id={id}
            isExpert={true}
          />
          <DetailTop>
            <GoBack />
            <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
              <DelBtn
                onClick={() => {
                  setDelServiceId([serviceInfo.id]);
                  setServiceDeleteModalOpen(true);
                }}
              />
            </div>
          </DetailTop>
          <DetailPage style={{ minHeight: "495px" }}>
            <DetailPageTitle>서비스 정보</DetailPageTitle>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "20px" }}
            >
              {/* 서비스명 */}
              <DetailPageForm>
                <DetailFormTitle>서비스명</DetailFormTitle>
                <DetailFormText>{serviceInfo.title}</DetailFormText>
              </DetailPageForm>

              {/* 배경 이미지 */}
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>배경 이미지</DetailFormTitle>
                <FileImgPreview
                  fileUrl={`${fileUrl}/${serviceInfo.bgServerFileName}`}
                  fileName={serviceInfo.bgOriginFileName}
                  serverFileName={serviceInfo.bgServerFileName}
                />
              </DetailPageForm>

              {/* 프로필 이미지 */}
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>프로필 이미지</DetailFormTitle>
                <FileImgPreview
                  fileUrl={`${fileUrl}/${serviceInfo.profileServerFileName}`}
                  fileName={serviceInfo.profileOriginFileName}
                  serverFileName={serviceInfo.profileServerFileName}
                />
              </DetailPageForm>

              {/* 마케팅 종류 */}
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>마케팅 종류</DetailFormTitle>
                <DetailFormText>{marketingTypeInfo.title}</DetailFormText>
              </DetailPageForm>

              {/* 예산 */}
              <DetailPageForm>
                <DetailFormTitle>예산</DetailFormTitle>
                <DetailFormText>
                  {serviceInfo.budgetTitle ? serviceInfo.budgetTitle : ""}
                </DetailFormText>
              </DetailPageForm>

              {/* 상품금액 */}
              <DetailPageForm>
                <DetailFormTitle>상품금액</DetailFormTitle>
                <DetailFormText>
                  {serviceInfo.price
                    ? formatNumberWithCommas(serviceInfo.price)
                    : ""}
                </DetailFormText>
              </DetailPageForm>

              {/* 집행 기간 */}
              <DetailPageForm>
                <DetailFormTitle>집행 기간</DetailFormTitle>
                <DetailFormText>
                  {serviceInfo.periodTitle ? serviceInfo.periodTitle : ""}
                </DetailFormText>
              </DetailPageForm>

              {/* 마케팅 전략 소개 */}
              <DetailPageForm
                style={{
                  alignItems: "flex-start",
                }}
              >
                <DetailFormTitle>마케팅 전략 소개</DetailFormTitle>
                <DetailFormText>{serviceInfo.intro}</DetailFormText>
              </DetailPageForm>

              {/* 상세페이지 이미지 */}
              <DetailPageForm
                style={{
                  alignItems: "flex-start",
                }}
              >
                <DetailFormTitle>상세페이지 이미지</DetailFormTitle>
                <FileImgPreview
                  fileUrl={`${fileUrl}/${serviceInfo.detailServerFileName}`}
                  fileName={serviceInfo.detailOriginFileName}
                  serverFileName={serviceInfo.detailServerFileName}
                />
              </DetailPageForm>

              {/* 포트폴리오 */}
              <DetailPageForm
                style={{
                  alignItems: "flex-start",
                }}
              >
                <DetailFormTitle>포트폴리오</DetailFormTitle>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 10,
                  }}
                >
                  {portfolioList.map((item, index) => {
                    return (
                      <DetailFormText key={index}>
                        {item.merch.title}
                      </DetailFormText>
                    );
                  })}
                </div>
              </DetailPageForm>
            </div>
          </DetailPage>

          <DetailPage
            style={{
              minHeight: 836,
            }}
          >
            <DetailPageTitle>자주 묻는 질문 관리</DetailPageTitle>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "20px" }}
            >
              <Table
                style={{
                  width: 964,
                }}
              >
                <colgroup>
                  {/* 제목 */}
                  <col width="231px" />
                  {/* 내용 */}
                  <col width="376px" />
                  {/* 작성일 */}
                  <col width="120px" />
                  {/* 게시 상태 */}
                  <col width="89px" />
                  {/* 완전삭제 */}
                  <col width="80px" />
                  {/* 상세보기 */}
                  <col width="68px" />
                </colgroup>
                <thead>
                  <Tr style={{ height: "40px", background: "#f2f4f5" }}>
                    <Th
                      style={{
                        paddingLeft: "20px",
                      }}
                    >
                      <TableText>제목</TableText>
                    </Th>
                    <Th>
                      <TableText>내용</TableText>
                    </Th>
                    <Th>
                      <TableText>작성일</TableText>
                    </Th>
                    <Th>
                      <TableText>게시 상태</TableText>
                    </Th>
                    <Th>
                      <TableText>완전삭제</TableText>
                    </Th>
                    <Th>
                      <TableText>상세보기</TableText>
                    </Th>
                  </Tr>
                </thead>
                <tbody>
                  {serviceFaqList.map((item, index) => {
                    return (
                      <Tr
                        key={index}
                        style={{ height: "59px", background: "#fff" }}
                      >
                        <Td
                          style={{
                            paddingLeft: "20px",
                          }}
                        >
                          <TableText
                            style={{
                              width: "169px",
                            }}
                          >
                            {item.title}
                          </TableText>
                        </Td>
                        <Td>
                          <TableText
                            style={{
                              width: "346px",
                            }}
                          >
                            {item.content}
                          </TableText>
                        </Td>
                        <Td>
                          <TableText>
                            {moment.utc(item.createAt).format("YYYY/MM/DD")}
                          </TableText>
                        </Td>

                        <Td>
                          <TableFilter
                            style={{
                              width: "fit-content",
                              gap: 10,
                            }}
                            onClick={() => {
                              filterOpen === index
                                ? setFilterOpen(-1)
                                : setFilterOpen(index);
                            }}
                            isOpen={filterOpen === index}
                            selectedValue={item.activation}
                            list={[
                              {
                                value: true,
                                title: "노출",
                                onClick: () => {
                                  setActivationId(item.id);
                                  setActivation(true);
                                  setActivationModalTitle("노출");
                                  setActivationModalOpen(true);
                                },
                              },
                              {
                                value: false,
                                title: "미노출",
                                onClick: () => {
                                  setActivationId(item.id);
                                  setActivation(false);
                                  setActivationModalTitle("미노출");
                                  setActivationModalOpen(true);
                                },
                              },
                            ]}
                          />
                        </Td>
                        <Td>
                          <TableDelBtn
                            style={{ marginLeft: "6px" }}
                            onClick={() => {
                              setDelId([item.id]);
                              setDeleteModalOpen(true);
                            }}
                          />
                        </Td>
                        <Td>
                          <DetailBtn
                            onClick={() => {
                              setSelectedData(item);
                              setDetailModalOpen(true);
                            }}
                          />
                        </Td>
                      </Tr>
                    );
                  })}
                </tbody>
              </Table>
              <Pagination
                route={`${route.service_delete_service_detail}/${id}`}
                queryString={`keyword=${searchParams.get("keyword")}` || ""}
                currentPage={page}
                totalPages={pagination.totalPages}
                rangeSize={pagination.rangeSize}
                first={pagination.first}
                last={pagination.last}
              />
            </div>
          </DetailPage>
        </DetailPageLayout>
      </MainLayout>

      {activationModalOpen ? (
        <Modal
          title={`${activationModalTitle} 하시겠습니까?`}
          setModalOpen={setActivationModalOpen}
          onClick={() => {
            activationFunc();
          }}
        />
      ) : null}

      {serviceDeleteModalOpen ? (
        <Modal
          title={modalMsg.delete?.title}
          text={modalMsg.delete?.content}
          btnTitle={"삭제하기"}
          setModalOpen={setServiceDeleteModalOpen}
          onClick={() => {
            deleteServiceFunc();
          }}
        />
      ) : null}

      {deleteModalOpen ? (
        <Modal
          title={modalMsg.delete?.title}
          text={modalMsg.delete?.content}
          btnTitle={"삭제하기"}
          setModalOpen={setDeleteModalOpen}
          onClick={() => {
            deleteFunc();
          }}
        />
      ) : null}

      {detailModalOpen ? (
        <DeleteFaqModal data={selectedData} setModalOpen={setDetailModalOpen} />
      ) : (
        ""
      )}
    </>
  );
};

export default DeleteServiceManagementDetail;
