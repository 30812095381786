import React, { useMemo } from "react";
import styled from "styled-components";
import PretendardText from "../../text/pretendardText";
import TableText from "../../text/table/tableText";

function TableFilter({
  style,
  subStyle,
  onClick,
  isOpen,
  list,
  selectedValue,
  isRed,
}) {
  const selectedTitle = useMemo(() => {
    return list.find((item) => item.value === selectedValue).title;
  }, [list, selectedValue]);

  return (
    <div>
      <TableFilterWrapper
        style={{
          ...style,
        }}
      >
        <TableText style={{ color: isRed ? "#FF003D" : "#262c31" }}>
          {selectedTitle}
        </TableText>
        {isOpen ? (
          <img
            src="/assets/admin/icons/ico_arrow_up.svg"
            style={{ cursor: "pointer" }}
            onClick={() => {
              onClick();
            }}
            alt=""
          />
        ) : (
          <img
            src="/assets/admin/icons/ico_arrow_down.svg"
            style={{ cursor: "pointer" }}
            onClick={() => {
              onClick();
            }}
            alt=""
          />
        )}
      </TableFilterWrapper>
      {isOpen ? (
        <SubMenuWrapper
          style={{
            ...subStyle,
          }}
        >
          {list.map((item, index) => {
            return (
              <SubMenuTextWrapper
                key={index}
                onClick={() => {
                  if (item.value === selectedValue) {
                    return;
                  }
                  item.onClick();
                }}
              >
                <SubMenuText
                  style={{
                    color: `${selectedValue === item.value ? "#262C31" : ""}`,
                  }}
                >
                  {item.title}
                </SubMenuText>
                {selectedValue === item.value ? (
                  <img
                    src="/assets/admin/icons/ico_selectbox_checked.svg"
                    alt=""
                  />
                ) : (
                  ""
                )}
              </SubMenuTextWrapper>
            );
          })}
        </SubMenuWrapper>
      ) : (
        ""
      )}
    </div>
  );
}

export default TableFilter;

const TableFilterWrapper = styled.div.attrs((props) => {})`
  width: 77px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SubMenuWrapper = styled.div.attrs((props) => {})`
  min-width: 113px;
  padding: 10px 14px;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
  position: absolute;
  top: 48px;
  left: 0px;
  z-index: 2;
  gap: 4px;
`;

const SubMenuTextWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  height: 42px;
  padding: 10px;
  padding-left: 10px;
  display: flex;
  align-items: center;
  border-radius: 3px;
  justify-content: space-between;
  cursor: pointer;

  &:hover {
    background-color: #f2f4f5;
  }
`;

const SubMenuText = styled(PretendardText).attrs((props) => {})`
  color: #778088;
  font-size: 15px;
  font-weight: 600;
  line-height: 24.5px;
  letter-spacing: -0.408px;
  border-radius: 4px;
  cursor: pointer;
`;
