import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Header from "./../../component/organisms/header";
import MainLayout from "./../../component/atoms/layout/mainLayout";
import Sidebar from "./../../component/organisms/sidebar";
import TableLayout from "./../../component/atoms/layout/table/tableLayout";
import TableTitle from "./../../component/atoms/text/table/tableTitle";
import TableTop from "./../../component/atoms/layout/table/tableTop";
import TableCount from "./../../component/molecules/tableCount";
import TableSearchBtn from "./../../component/atoms/layout/table/tableSearchBtn";
import SearchBar from "./../../component/atoms/input/searchBar";
import { route } from "../../router/route";
import Tr from "../../component/atoms/layout/table/tr";
import Th from "../../component/atoms/layout/table/th";
import TableText from "../../component/atoms/text/table/tableText";
import Checkbox from "../../component/atoms/input/checkbox";
import Td from "../../component/atoms/layout/table/td";
import moment from "moment";
import TableFilter from "../../component/atoms/layout/table/tableFilter";
import DetailBtn from "../../component/atoms/button/detailBtn";
import Pagination from "../../component/molecules/pagination";
import TableDelBtn from "../../component/atoms/button/tableDelBtn";
import Table from "../../component/atoms/layout/table/table";
import AddBtn from "../../component/atoms/button/addBtn";
import CurationApi from "../../api/curation.api";
import { errorHandler } from "../../util/errorHandler";
import { toast } from "react-toastify";
import { toastMsg } from "../../util/toastMsg";
import DelBtn from "../../component/atoms/button/delBtn";
import Modal from "../../component/templates/Modal";
import { modalMsg } from "../../util/modalMsg";

const Curation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams(); // eslint-disable-line no-unused-vars

  // ----------------------------------- //
  // ---------- 마운트가 되면 ---------- //
  // --------------------------------- //
  useEffect(() => {
    setKeyword(searchParams.get("keyword") ? searchParams.get("keyword") : "");
    getCurationList();
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  const page = useMemo(() => {
    return searchParams.get("page") ? searchParams.get("page") : 1;
  }, [searchParams]);

  const [pagination, setPagination] = useState({
    size: 10, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈

    totalCount: 0,
    totalPages: 0,
    first: false,
    last: false,
  });

  const [curationList, setCurationList] = useState([]);
  const [curationCount, setCurationCount] = useState(0);
  const [keyword, setKeyword] = useState("");

  const getCurationList = async () => {
    try {
      const data = {
        keyword: searchParams.get("keyword"),
        page: searchParams.get("page"),
        size: pagination.size,
      };
      let responseData = (await CurationApi.getList(data)).data.data;
      setCurationCount(responseData.totalCount);
      setCurationList(
        responseData.content.map((item) => {
          return {
            ...item,
            activation: item.activation ? item.activation : false,
            checked: false,
          };
        })
      );
      setPagination({
        ...pagination,
        totalCount: responseData.totalCount ? responseData.totalCount : 0,
        totalPages: responseData.totalPage
          ? responseData.totalPage
          : Math.ceil(responseData.totalCount / 10),
        first: responseData.isFirst ? responseData.isFirst : false,
        last: responseData.isLast ? responseData.isLast : false,
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  // ----- 활성/비활성
  const [filterOpen, setFilterOpen] = useState(-1);
  const [activationId, setActivationId] = useState(null);
  const [activation, setActivation] = useState(null);
  const [activationModalTitle, setActivationModalTitle] = useState("");
  const [activationModalOpen, setActivationModalOpen] = useState(false);

  const activationFunc = async () => {
    try {
      const data = {
        activation: activation,
      };
      await CurationApi.updateActivation(activationId, data);
      toast(toastMsg.create);
      setFilterOpen(-1);
      setActivationModalOpen(false);
      getCurationList();
    } catch (error) {
      errorHandler(error);
    }
  };

  // ----- 삭제
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [delIdList, setDelIdList] = useState([]);

  const isAllCheck = useMemo(() => {
    const checkedList = curationList.filter((item) => item.checked);
    return (
      checkedList.length !== 0 && checkedList.length === curationList.length
    );
  }, [curationList]);

  const deleteFunc = async () => {
    try {
      let data = {
        ids: delIdList,
      };
      await CurationApi.delete(data);
      toast(toastMsg.delete);
      setDeleteModalOpen(false);
      setDelIdList([]);
      getCurationList();
    } catch (error) {
      errorHandler(error);
    }
  };

  const [title, setTitle] = useState("게시글 관리");
  const [subTitle, setSubTitle] = useState("항목");

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <TableLayout>
          <TableTitle>{title}</TableTitle>
          <TableTop>
            <TableCount text={subTitle} count={curationCount} />
            <TableSearchBtn>
              <SearchBar
                value={keyword}
                onChange={(e) => {
                  setKeyword(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    navigate(
                      `${route.curation_post}?page=1&keyword=${keyword}`,
                      {
                        replace: true,
                      }
                    );
                    setTitle("검색 결과");
                    setSubTitle("검색 결과");
                  }
                }}
                onClick={() => {
                  navigate(`${route.curation_post}?page=1&keyword=${keyword}`, {
                    replace: true,
                  });
                  setTitle("검색 결과");
                  setSubTitle("검색 결과");
                }}
              />
              <DelBtn
                text={"선택항목 삭제"}
                onClick={() => {
                  const delList = curationList.filter((item) => item.checked);
                  if (delList.length <= 0) {
                    return;
                  }
                  setDelIdList(delList.map((item) => item.id));
                  setDeleteModalOpen(true);
                }}
              />
              <AddBtn onClick={() => navigate(route.curation_post_create)} />
            </TableSearchBtn>
          </TableTop>
          <Table>
            <colgroup>
              {/* 체크박스 영역 */}
              <col width="71px" />
              {/* 제목 */}
              <col width="175px" />
              {/* 업종 */}
              <col width="211px" />
              {/* 지역 */}
              <col width="55px" />
              {/* 나이 */}
              <col width="60px" />
              {/* 모집기간 */}
              <col width="240px" />
              {/* 게시상태 */}
              <col width="90px" />
              {/* 삭제하기 */}
              <col width="69px" />
              {/* 상세보기 */}
              <col width="73px" />
            </colgroup>
            <thead>
              <Tr style={{ height: "40px", background: "#f2f4f5" }}>
                <Th style={{ paddingLeft: "20px" }}>
                  <Checkbox
                    checked={isAllCheck}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setCurationList(
                          curationList.map((item) => {
                            return {
                              ...item,
                              checked: true,
                            };
                          })
                        );
                      } else {
                        setCurationList(
                          curationList.map((item) => {
                            return {
                              ...item,
                              checked: false,
                            };
                          })
                        );
                      }
                    }}
                  />
                </Th>
                <Th>
                  <TableText>제목</TableText>
                </Th>
                <Th>
                  <TableText>업종</TableText>
                </Th>
                <Th>
                  <TableText>지역</TableText>
                </Th>
                <Th>
                  <TableText>나이</TableText>
                </Th>
                <Th>
                  <TableText>모집기간</TableText>
                </Th>
                <Th>
                  <TableText>게시상태</TableText>
                </Th>
                <Th>
                  <TableText>삭제하기</TableText>
                </Th>
                <Th>
                  <TableText>상세보기</TableText>
                </Th>
              </Tr>
            </thead>
            <tbody>
              {curationList.map((item, index) => {
                return (
                  <Tr
                    key={index}
                    style={{ height: "59px", background: "#fff" }}
                  >
                    <Td style={{ paddingLeft: "20px" }}>
                      <Checkbox
                        checked={item.checked}
                        onChange={() => {
                          item.checked = !item.checked;
                          setCurationList([...curationList]);
                        }}
                      />
                    </Td>
                    <Td>
                      <TableText>{item.title}</TableText>
                    </Td>
                    <Td>
                      <TableText>
                        {item.sector === "RESTAURANT"
                          ? "음식점업"
                          : item.sector === "REAL_ESTATE"
                          ? "부동산업"
                          : item.sector === "TECHNOLOGY"
                          ? "전문, 과학 및 기술 서비스업"
                          : item.sector === "ONLINE"
                          ? "통신판매업"
                          : item.sector === "ART"
                          ? "예술, 스포츠 및 여가 관련 서비스업"
                          : item.sector === "EDUCATION"
                          ? "교육 서비스업"
                          : item.sector === "PERSONAL"
                          ? "수리 및 개인 서비스업"
                          : "없음"}
                      </TableText>
                    </Td>
                    <Td>
                      <TableText>{item.regionTitle}</TableText>
                    </Td>
                    <Td>
                      <TableText
                        style={{
                          width: 60,
                        }}
                      >
                        {item.ageTitle ? item.ageTitle : "없음"}
                      </TableText>
                    </Td>
                    <Td>
                      <TableText>
                        {moment.utc(item.startDate).format("YYYY/MM/DD")} ~{" "}
                        {moment.utc(item.endDate).format("YYYY/MM/DD")}
                      </TableText>
                    </Td>
                    <Td>
                      <TableFilter
                        style={{
                          width: "fit-content",
                          gap: 10,
                        }}
                        onClick={() => {
                          filterOpen === index
                            ? setFilterOpen(-1)
                            : setFilterOpen(index);
                        }}
                        isOpen={filterOpen === index}
                        selectedValue={item.activation}
                        list={[
                          {
                            value: true,
                            title: "활성",
                            onClick: () => {
                              setActivationId(item.id);
                              setActivation(true);
                              setActivationModalTitle("활성화");
                              setActivationModalOpen(true);
                            },
                          },
                          {
                            value: false,
                            title: "비활성",
                            onClick: () => {
                              setActivationId(item.id);
                              setActivation(false);
                              setActivationModalTitle("비활성화");
                              setActivationModalOpen(true);
                            },
                          },
                        ]}
                      />
                    </Td>
                    <Td>
                      <TableDelBtn
                        style={{ marginLeft: "6px" }}
                        onClick={() => {
                          item.checked = !item.checked;
                          setCurationList([...curationList]);
                          const delList = [item];
                          setDelIdList(delList.map((item) => item.id));
                          setDeleteModalOpen(true);
                        }}
                      />
                    </Td>
                    <Td>
                      <DetailBtn
                        onClick={() => {
                          navigate(route.curation_post_detail + `/${item.id}`);
                        }}
                      />
                    </Td>
                  </Tr>
                );
              })}
            </tbody>
          </Table>
          <Pagination
            route={route.curation_post}
            queryString={`keyword=${searchParams.get("keyword") || ""}`}
            currentPage={page}
            totalPages={pagination.totalPages}
            rangeSize={pagination.rangeSize}
            first={pagination.first}
            last={pagination.last}
          />
        </TableLayout>
      </MainLayout>
      {activationModalOpen ? (
        <Modal
          title={`${activationModalTitle} 하시겠습니까?`}
          setModalOpen={setActivationModalOpen}
          onClick={() => {
            activationFunc();
          }}
        />
      ) : null}

      {deleteModalOpen ? (
        <Modal
          title={modalMsg.delete?.title}
          text={modalMsg.delete?.content}
          btnTitle={"삭제하기"}
          setModalOpen={setDeleteModalOpen}
          onClick={() => {
            deleteFunc();
          }}
        />
      ) : null}
    </>
  );
};

export default Curation;
