import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Header from "../../../component/organisms/header";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Sidebar from "../../../component/organisms/sidebar";
import DetailPageLayout from "../../../component/atoms/layout/detail/detailPageLayout";
import { route } from "../../../router/route";
import TableTitle from "../../../component/atoms/text/table/tableTitle";
import TableTop from "../../../component/atoms/layout/table/tableTop";
import TableCount from "../../../component/molecules/tableCount";
import TableSearchBtn from "../../../component/atoms/layout/table/tableSearchBtn";
import SearchBar from "../../../component/atoms/input/searchBar";
import Table from "../../../component/atoms/layout/table/table";
import Tr from "../../../component/atoms/layout/table/tr";
import Th from "../../../component/atoms/layout/table/th";
import Checkbox from "../../../component/atoms/input/checkbox";
import TableText from "../../../component/atoms/text/table/tableText";
import Td from "../../../component/atoms/layout/table/td";
import DetailBtn from "../../../component/atoms/button/detailBtn";
import Pagination from "../../../component/molecules/pagination";
import ExcelBtn from "./../../../component/atoms/button/excelBtn";
import OpenSourceApi from "./../../../api/openSource.api";
import { errorHandler } from "../../../util/errorHandler";
import moment from "moment";

const GeneralOpenSource = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  // ----------------------------------- //
  // ---------- 마운트가 되면 ---------- //
  // --------------------------------- //
  useEffect(() => {
    setKeyword(searchParams.get("keyword") ? searchParams.get("keyword") : "");
    getOpenSourceList();
  }, [location]);

  // ----- 목록 가져오기
  const page = useMemo(() => {
    return searchParams.get("page") ? searchParams.get("page") : 1;
  }, [searchParams]);

  const [pagination, setPagination] = useState({
    size: 10, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈
    totalCount: 0,
    totalPages: 0,
    first: false,
    last: false,
  });

  const [openSourceList, setOpenSourceList] = useState([]);
  const [openSourceCount, setOpenSourceCount] = useState(0);
  const [keyword, setKeyword] = useState("");

  const getOpenSourceList = async () => {
    try {
      const data = {
        keyword: searchParams.get("keyword"),
        page: searchParams.get("page"),
        size: pagination.size,
      };
      let responseData = (await OpenSourceApi.getList(data)).data.data;
      setOpenSourceCount(responseData.totalCount);
      setOpenSourceList(
        responseData.content.map((item) => {
          return {
            ...item,
            checked: false,
          };
        })
      );
      setPagination({
        ...pagination,
        totalCount: responseData.totalCount ? responseData.totalCount : 0,
        totalPages: responseData.totalPage
          ? responseData.totalPage
          : Math.ceil(responseData.totalCount / 10),
        first: responseData.isFirst ? responseData.isFirst : false,
        last: responseData.isLast ? responseData.isLast : false,
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  const isAllCheck = useMemo(() => {
    const checkedList = openSourceList.filter((item) => item.checked);
    return (
      checkedList.length !== 0 && checkedList.length === openSourceList.length
    );
  }, [openSourceList]);

  // // ----- 엑셀 생성
  // const downloadExcel = async (excelData) => {
  //   const Excel = require("exceljs");
  //   const workbook = new Excel.Workbook();
  //   workbook.addWorksheet("Sheet One");
  //   const sheetOne = workbook.getWorksheet("Sheet One");
  //   sheetOne.columns = [
  //     { header: "오픈소스 종류", key: "title", width: 40 },
  //     { header: "오픈소스 내용", key: "content", width: 40 },
  //     { header: "생성일", key: "createdAt", width: 40 },
  //     { header: "마지막 수정일", key: "updateAt", width: 40 },
  //   ];
  //   excelData.map((item) => {
  //     // 행 추가
  //     sheetOne.addRow(item);
  //   });
  //   const buffer = await workbook.xlsx.writeBuffer();
  //   saveAs(new Blob([buffer]), "일반 회원 관리.xlsx");
  // };

  // const getExcelAll = async () => {
  //   try {
  //     const excelArr = [];
  //     const response = (await ExcelApi.GetMemberAll()).data.data;
  //     response.map((item) => {
  //       const activeTitle = item.isActive ? "활성" : "비활성";
  //       const createDate = moment(item.createdAt).format("YYYY/MM/DD");
  //       excelArr.unshift({ ...item, isActive: activeTitle, createdAt: createDate });
  //     });
  //     downloadExcel(excelArr);
  //   } catch (error) {
  //     errorHandler(error);
  //   }
  // };

  // const getExcelChecked = async () => {
  // const filter = list.filter((item) => item.checked);
  // if (filter.length <= 0) {
  //   return toast("항목을 선택해주세요");
  // }
  // let queryString = filter.map((idList) => `idList=${idList.id}`).join("&");
  // try {
  //   const excelArr = [];
  //   const response = (await ExcelApi.GetMemberSelected(queryString)).data.data;
  //   response.map((item) => {
  //     const activeTitle = item.isActive ? "활성" : "비활성";
  //     const createDate = moment(item.createdAt).format("YYYY/MM/DD");
  //     excelArr.unshift({ ...item, isActive: activeTitle, createdAt: createDate });
  //   });
  //   downloadExcel(excelArr);
  //   // getList();
  // } catch (error) {
  //   errorHandler(error);
  // }
  // };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <div style={{ marginTop: "40px" }}>
            <TableTitle>오픈소스 관리</TableTitle>
            <TableTop>
              <TableCount text={"항목"} count={openSourceCount} />
              <TableSearchBtn>
                <SearchBar
                  value={keyword}
                  onChange={(e) => {
                    setKeyword(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      navigate(
                        `${route.general_open_source}?page=1&keyword=${keyword}`,
                        {
                          replace: true,
                        }
                      );
                    }
                  }}
                  onClick={() => {
                    navigate(
                      `${route.general_open_source}?page=1&keyword=${keyword}`,
                      {
                        replace: true,
                      }
                    );
                  }}
                />
                <ExcelBtn />
              </TableSearchBtn>
            </TableTop>
            <Table>
              <colgroup>
                {/* 체크박스 */}
                <col width="90px" />
                {/* 약관 종류 */}
                <col width="737px" />
                {/* 마지막 수정일 */}
                <col width="144px" />
                {/* 상세보기 */}
                <col width="75px" />
              </colgroup>
              <thead>
                <Tr style={{ height: "41px", background: "#f2f4f5" }}>
                  <Th style={{ paddingLeft: "20px" }}>
                    <Checkbox
                      checked={isAllCheck}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setOpenSourceList(
                            openSourceList.map((item) => {
                              return {
                                ...item,
                                checked: true,
                              };
                            })
                          );
                        } else {
                          setOpenSourceList(
                            openSourceList.map((item) => {
                              return {
                                ...item,
                                checked: false,
                              };
                            })
                          );
                        }
                      }}
                    />
                  </Th>
                  <Th>
                    <TableText>오픈소스 종류</TableText>
                  </Th>
                  <Th>
                    <TableText>마지막 수정일</TableText>
                  </Th>
                  <Th>
                    <TableText>상세보기</TableText>
                  </Th>
                </Tr>
              </thead>
              <tbody>
                {openSourceList.map((item, index) => {
                  return (
                    <Tr
                      key={index}
                      style={{ height: "59px", background: "#fff" }}
                    >
                      <Td style={{ paddingLeft: "20px" }}>
                        <Checkbox
                          checked={item.checked}
                          onChange={() => {
                            item.checked = !item.checked;
                            setOpenSourceList([...openSourceList]);
                          }}
                        />
                      </Td>
                      <Td>
                        <TableText>{item.title}</TableText>
                      </Td>
                      <Td>
                        <TableText>
                          {moment(item.updatedAt).format("YYYY/MM/DD")}
                        </TableText>
                      </Td>
                      <Td>
                        <DetailBtn
                          onClick={() => {
                            navigate(
                              route.general_open_source_detail + `/${item.id}`
                            );
                          }}
                        />
                      </Td>
                    </Tr>
                  );
                })}
              </tbody>
            </Table>
            <Pagination
              route={route.general_open_source}
              queryString={`keyword=${searchParams.get("keyword") || ""}`}
              currentPage={page}
              totalPages={pagination.totalPages}
              rangeSize={pagination.rangeSize}
              first={pagination.first}
              last={pagination.last}
            />
          </div>
        </DetailPageLayout>
      </MainLayout>
    </>
  );
};

export default GeneralOpenSource;
