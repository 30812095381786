import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import Header from "../../component/organisms/header";
import MainLayout from "../../component/atoms/layout/mainLayout";
import Sidebar from "../../component/organisms/sidebar";
import DetailTop from "../../component/atoms/layout/detail/detailTop";
import DetailPage from "../../component/atoms/layout/detail/detailPage";
import DetailPageTitle from "../../component/atoms/text/detail/detailPageTitle";
import DetailPageLayout from "../../component/atoms/layout/detail/detailPageLayout";
import GoBack from "../../component/molecules/goBack";
import DetailPageForm from "../../component/atoms/layout/detail/detailPageForm";
import DetailFormTitle from "../../component/atoms/text/detail/detailFormTitle";
import DetailFormText from "../../component/atoms/text/detail/detailFormText";
import MainInput from "../../component/atoms/input/mainInput";
import SaveBtn from "../../component/atoms/button/saveBtn";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { toastMsg } from "../../util/toastMsg";
import { errorHandler } from "../../util/errorHandler";
import { isNull } from "../../util/check";
import { regExpression } from "../../util/regExpresstion";
import Modal from "../../component/templates/Modal";
import { modalMsg } from "../../util/modalMsg";
import ManagerApi from "../../api/manager.api";

function AdminEdit(props) {
  // import
  const navigate = useNavigate();
  let { id } = useParams();

  // mounted
  useEffect(() => {
    getManagerInfo();
  }, []);

  // ----- 정보 가져오기
  const [managerInfo, setManagerInfo] = useState({
    name: "",
    email: "",
    password: "",
    passwordCheck: "",
    passwordChangeFlag: false,
  });

  const getManagerInfo = async () => {
    try {
      const response = await ManagerApi.getById(id);
      const data = response.data.data.content;
      setManagerInfo({
        ...data,
        password: "",
        passwordCheck: "",
        passwordChangeFlag: false,
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  // ----- 비밀번호 변경
  const [pwEdit, setPwEdit] = useState(false);

  // 비밀번호 체크
  const pwErr = useMemo(() => {
    if (
      !isNull(managerInfo.password) &&
      !regExpression.password.test(managerInfo.password)
    ) {
      return true;
    }
    return false;
  }, [managerInfo.password]);

  // 비밀번호 확인 체크
  const pwCheckErr = useMemo(() => {
    if (
      !isNull(managerInfo.password) &&
      !isNull(managerInfo.passwordCheck) &&
      managerInfo.password !== managerInfo.passwordCheck
    ) {
      return true;
    }
    return false;
  }, [managerInfo.password, managerInfo.passwordCheck]);

  //저장하기
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const saveFunc = async () => {
    try {
      if (isNull(managerInfo.name)) {
        setSaveModalOpen(false);
        return toast("이름을 입력해주세요.");
      }
      if (pwEdit && isNull(managerInfo.password)) {
        setSaveModalOpen(false);
        return toast("비밀번호를 입력해주세요");
      }
      if (pwEdit && isNull(managerInfo.passwordCheck)) {
        setSaveModalOpen(false);
        return toast("비밀번호를 입력해주세요");
      }
      if (pwErr) {
        setSaveModalOpen(false);
        return toast("비밀번호를 올바르게 입력해주세요");
      }
      if (pwCheckErr) {
        setSaveModalOpen(false);
        return toast("비밀번호가 일치하지 않습니다");
      }

      const requestDto = {
        name: managerInfo.name,
        passwordChangeFlag: pwEdit ? true : false,
        password: managerInfo.password,
      };

      await ManagerApi.update(id, requestDto);
      toast(toastMsg.update);
      navigate(-1);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
          </DetailTop>
          <DetailPage style={{ minHeight: "375px" }}>
            <DetailPageTitle>관리자 상세</DetailPageTitle>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                marginBottom: "79px",
              }}
            >
              <DetailPageForm>
                <DetailFormTitle>이름*</DetailFormTitle>
                <MainInput
                  style={{ width: "338px" }}
                  type={"text"}
                  placeholder={"이름을 입력해주세요"}
                  value={managerInfo.name}
                  onChange={(e) =>
                    setManagerInfo({ ...managerInfo, name: e.target.value })
                  }
                />
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>이메일</DetailFormTitle>
                <DetailFormText>{managerInfo.email}</DetailFormText>
              </DetailPageForm>
              {pwEdit ? (
                <>
                  <DetailPageForm>
                    <DetailFormTitle>비밀번호*</DetailFormTitle>
                    <MainInput
                      style={{ width: "338px" }}
                      type={"password"}
                      placeholder={"새로운 비밀번호를 입력해 주세요."}
                      helperText={
                        "영문/숫자/특수문자 중 2가지 이상 포함, 8자 ~ 16자"
                      }
                      value={managerInfo.password}
                      onError={pwErr}
                      onChange={(e) => {
                        setManagerInfo({
                          ...managerInfo,
                          password: e.target.value,
                        });
                      }}
                    />
                  </DetailPageForm>
                  <DetailPageForm>
                    <DetailFormTitle>비밀번호 재입력*</DetailFormTitle>
                    <MainInput
                      style={{ width: "338px" }}
                      type={"password"}
                      placeholder={"새로운 비밀번호를 한 번 더 입력해 주세요."}
                      helperText={"비밀번호가 일치하지 않습니다"}
                      value={managerInfo.passwordCheck}
                      onError={pwCheckErr}
                      onChange={(e) => {
                        setManagerInfo({
                          ...managerInfo,
                          passwordCheck: e.target.value,
                        });
                      }}
                    />
                  </DetailPageForm>
                </>
              ) : (
                <DetailPageForm>
                  <DetailFormTitle>비밀번호</DetailFormTitle>
                  <PwEditBtn
                    onClick={() => {
                      setPwEdit(true);
                      managerInfo.passwordChangeFlag = true;
                      setManagerInfo({ ...managerInfo });
                    }}
                  >
                    비밀번호 수정하기
                  </PwEditBtn>
                </DetailPageForm>
              )}
            </div>
            <SaveBtn
              style={{ bottom: "40px", right: "40px" }}
              onClick={() => {
                setSaveModalOpen(true);
                setManagerInfo({
                  ...managerInfo,
                  passwordChangeFlag: true,
                });
              }}
            />
          </DetailPage>
        </DetailPageLayout>
      </MainLayout>
      {saveModalOpen ? (
        <Modal
          title={modalMsg.update?.title}
          text={modalMsg.update?.content}
          setModalOpen={setSaveModalOpen}
          onClick={saveFunc}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default AdminEdit;

const PwEditBtn = styled.button.attrs((props) => {})`
  width: 338px;
  height: 48px;
  display: flex;
  padding: 13px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  border: 1px solid #b8bfc4;
  background-color: transparent;
  color: #808991;
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
`;
