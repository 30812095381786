import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Header from "../../../component/organisms/header";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Sidebar from "../../../component/organisms/sidebar";
import DetailPageLayout from "../../../component/atoms/layout/detail/detailPageLayout";
import { route } from "../../../router/route";
import TableTitle from "../../../component/atoms/text/table/tableTitle";
import TableTop from "../../../component/atoms/layout/table/tableTop";
import TableCount from "../../../component/molecules/tableCount";
import TableSearchBtn from "../../../component/atoms/layout/table/tableSearchBtn";
import SearchBar from "../../../component/atoms/input/searchBar";
import Table from "../../../component/atoms/layout/table/table";
import Tr from "../../../component/atoms/layout/table/tr";
import Th from "../../../component/atoms/layout/table/th";
import TableText from "../../../component/atoms/text/table/tableText";
import Td from "../../../component/atoms/layout/table/td";
import DetailBtn from "../../../component/atoms/button/detailBtn";
import Pagination from "../../../component/molecules/pagination";
import TableDelBtn from "../../../component/atoms/button/tableDelBtn";
import ChatReportModal from "./chatReportModal";
import ChatReportApi from "../../../api/chatReport.api";
import { errorHandler } from "../../../util/errorHandler";
import moment from "moment";
import Modal from "../../../component/templates/Modal";
import { modalMsg } from "../../../util/modalMsg";
import { toast } from "react-toastify";
import { toastMsg } from "../../../util/toastMsg";

const ChatReport = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams(); // eslint-disable-line no-unused-vars

  // ----------------------------------- //
  // ---------- 마운트가 되면 ---------- //
  // --------------------------------- //
  useEffect(() => {
    setKeyword(searchParams.get("keyword") ? searchParams.get("keyword") : "");
    getChatReportList();
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  // ----- 목록 가져오기
  const page = useMemo(() => {
    return searchParams.get("page") ? searchParams.get("page") : 1;
  }, [searchParams]);

  const [pagination, setPagination] = useState({
    size: 10, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈
    totalCount: 0,
    totalPages: 0,
    first: false,
    last: false,
  });

  const [chatReportList, setChatReportList] = useState([]);
  const [chatReportCount, setChatReportCount] = useState(0);
  const [keyword, setKeyword] = useState("");

  const getChatReportList = async () => {
    try {
      const data = {
        keyword: searchParams.get("keyword"),
        page: searchParams.get("page"),
        size: pagination.size,
      };
      let responseData = (await ChatReportApi.getList(data)).data.data;
      setChatReportCount(responseData.totalCount);
      setChatReportList(
        responseData.content.map((item) => {
          return {
            ...item,
          };
        })
      );
      setPagination({
        ...pagination,
        totalCount: responseData.totalCount ? responseData.totalCount : 0,
        totalPages: responseData.totalPage
          ? responseData.totalPage
          : Math.ceil(responseData.totalCount / 10),
        first: responseData.isFirst ? responseData.isFirst : false,
        last: responseData.isLast ? responseData.isLast : false,
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  const [selectedData, setSelectedData] = useState({});
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [detailModalOpen, setDetailModalOpen] = useState(false);

  // 삭제
  const deleteFunc = async () => {
    try {
      await ChatReportApi.delete(selectedData);
      toast(toastMsg.delete);
      setDeleteModalOpen(false);
      getChatReportList();
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <div style={{ marginTop: "40px" }}>
            <TableTitle>신고 내역 관리</TableTitle>
            <TableTop>
              <TableCount text={"항목"} count={chatReportCount} />
              <TableSearchBtn>
                <SearchBar
                  value={keyword}
                  onChange={(e) => {
                    setKeyword(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      navigate(
                        `${route.chat_report}?page=1&keyword=${keyword}`,
                        {
                          replace: true,
                        }
                      );
                    }
                  }}
                  onClick={() => {
                    navigate(`${route.chat_report}?page=1&keyword=${keyword}`, {
                      replace: true,
                    });
                  }}
                />
              </TableSearchBtn>
            </TableTop>
            <Table>
              <colgroup>
                {/* 이메일 */}
                <col width="205px" />
                {/* 신고사유 */}
                <col width="446px" />
                {/* 작성일 */}
                <col width="155px" />
                {/* 삭제하기 */}
                <col width="140px" />
                {/* 상세보기 */}
                <col width="78px" />
              </colgroup>
              <thead>
                <Tr style={{ height: "41px", background: "#f2f4f5" }}>
                  <Th style={{ paddingLeft: "20px" }}>
                    <TableText>이메일</TableText>
                  </Th>
                  <Th>
                    <TableText>신고사유</TableText>
                  </Th>
                  <Th>
                    <TableText>작성일</TableText>
                  </Th>
                  <Th>
                    <TableText>삭제하기</TableText>
                  </Th>
                  <Th>
                    <TableText>상세보기</TableText>
                  </Th>
                </Tr>
              </thead>
              <tbody>
                {chatReportList.map((item, index) => {
                  return (
                    <Tr
                      key={index}
                      style={{ height: "59px", background: "#fff" }}
                    >
                      <Td style={{ paddingLeft: "20px" }}>
                        <TableText>{item.requestMemberEmail}</TableText>
                      </Td>
                      <Td>
                        <TableText>{item.content}</TableText>
                      </Td>
                      <Td>
                        <TableText>
                          {moment.utc(item.createAt).format("YYYY/MM/DD")}
                        </TableText>
                      </Td>
                      <Td>
                        <TableDelBtn
                          onClick={() => {
                            setSelectedData(item.id);
                            setDeleteModalOpen(true);
                          }}
                        />
                      </Td>
                      <Td>
                        <DetailBtn
                          onClick={() => {
                            setSelectedData(item);
                            setDetailModalOpen(true);
                          }}
                        />
                      </Td>
                    </Tr>
                  );
                })}
              </tbody>
            </Table>
            <Pagination
              route={route.chat_report}
              queryString={`keyword=${searchParams.get("keyword") || ""}`}
              currentPage={page}
              totalPages={pagination.totalPages}
              rangeSize={pagination.rangeSize}
              first={pagination.first}
              last={pagination.last}
            />
          </div>
        </DetailPageLayout>
      </MainLayout>

      {deleteModalOpen ? (
        <Modal
          title={modalMsg.delete?.title}
          text={modalMsg.delete?.content}
          btnTitle={"삭제하기"}
          setModalOpen={setDeleteModalOpen}
          onClick={() => {
            deleteFunc();
          }}
        />
      ) : null}

      {detailModalOpen ? (
        <ChatReportModal
          data={selectedData}
          setModalOpen={setDetailModalOpen}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default ChatReport;
