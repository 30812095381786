import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { route } from "../../router/route";
import PretendardText from "../atoms/text/pretendardText";

const Sidebar = (props) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(0);

  //경로 저장
  const location = useLocation();
  const [path, setPath] = useState(null);
  const [menus, setMenus] = useState([
    // 홈 영역
    {
      title: "홈",
      link: route.home,
      subMenus: [],
      isView: true,
    },
    // 사용자 관리 영역
    {
      title: "사용자 관리",
      link: route.user,
      subMenus: [
        {
          title: "일반회원 관리",
          link: route.user_common_member,
          isView: true,
        },
        {
          title: "전문가 회원 관리",
          link: route.user_company_member,
          isView: true,
        },
        {
          title: "탈퇴 회원 관리",
          link: route.user_leave_member,
          isView: true,
        },
      ],
      isView: true,
    },
    // 서비스 관리 영역
    {
      title: "서비스 관리",
      link: route.service,
      subMenus: [
        {
          title: "전문가 서비스 관리",
          link: route.service_company,
          isView: true,
        },
        {
          title: "삭제된 서비스 관리",
          link: route.service_delete,
          isView: true,
        },
        {
          title: "마케팅 종류 관리",
          link: route.service_marketing,
          isView: true,
        },
      ],
      isView: true,
    },
    // 결제 관리
    {
      title: "결제 관리",
      link: route.payment,
      subMenus: [
        {
          title: "결제 수단 관리",
          link: route.payment_method,
          isView: true,
        },
        {
          title: "결제 내역 관리",
          link: route.payment_history,
          isView: true,
        },
        {
          title: "취소/환불 내역 관리",
          link: route.payment_cancel,
          isView: true,
        },
        {
          title: "정산 요청 관리",
          link: route.payment_request,
          isView: true,
        },
      ],
      isView: true,
    },
    // 채팅 관리
    {
      title: "채팅 관리",
      link: route.chat,
      subMenus: [
        {
          title: "채팅 내역 관리",
          link: route.chat_history,
          isView: true,
        },
        {
          title: "신고 내역 관리",
          link: route.chat_report,
          isView: true,
        },
      ],
      isView: true,
    },
    // 큐레이션 관리
    {
      title: "큐레이션 관리",
      link: route.curation,
      subMenus: [
        {
          title: "게시글 관리",
          link: route.curation_post,
          isView: true,
        },
      ],
      isView: true,
    },
    // 일반 관리
    {
      title: "일반 관리",
      link: route.general,
      subMenus: [
        // {
        //   title: "알림 관리",
        //   link: route.general_alarm,
        //   isView: true,
        // },
        {
          title: "1:1 문의 관리",
          link: route.general_ask,
          isView: true,
        },
        {
          title: "공지사항 관리",
          link: route.general_notice,
          isView: true,
        },
        {
          title: "자주 묻는 질문 관리",
          link: route.general_faq,
          isView: true,
        },
        {
          title: "팝업 관리",
          link: route.general_popup,
          isView: true,
        },
        {
          title: "약관 관리",
          link: route.general_terms,
          isView: true,
        },
        {
          title: "오픈소스 관리",
          link: route.general_open_source,
          isView: true,
        },
        {
          title: "기본 관리",
          link: route.general_basic,
          isView: true,
        },
      ],
      isView: true,
    },
    // 관리자회원 관리 영역
    {
      title: "관리자회원 관리",
      link: route.manager,
      subMenus: [],
      isView: true,
    },
  ]);

  // useEffect(() => {
  //   setPath(location.pathname);
  // }, [location]);

  useEffect(() => {
    setPath(location.pathname);
    if (sessionStorage.getItem("role") !== "ROOTADMIN") {
      const accessMenu = sessionStorage.getItem("accessMenu");
      for (let index = 0; index < menus.length; index++) {
        const element = menus[index];
        if (element.subMenus.length > 0) {
          for (let index2 = 0; index2 < element.subMenus.length; index2++) {
            const element2 = element.subMenus[index2];
            if (!accessMenu?.includes(element2.link)) {
              element2.isView = false;
            }
          }
          if (
            element.subMenus.filter((item) => !item.isView).length ===
            element.subMenus.length
          ) {
            element.isView = false;
          }
        } else {
          if (!accessMenu?.includes(element.link)) {
            element.isView = false;
          }
        }
      }
      setMenus([...menus]);
    }
  }, [location]);

  return (
    <SidebarWrapper style={{ ...props.style }}>
      <ProfileWrapper>
        <img src="/assets/admin/icons/ico_profile02.svg" alt="" />
        <SidebarPretendardText
          style={{
            color: "#fff",
            fontSize: "14px",
            fontWeight: "600",
            letterSpacing: "-0.042px",
            cursor: "unset",
          }}
        >
          {sessionStorage.getItem("name") || "관리자 이메일"}
        </SidebarPretendardText>
      </ProfileWrapper>
      <NavListWrapper>
        {menus.map((item, index) => {
          return (
            <div key={index}>
              {item.isView ? (
                <NavWrapper
                  onClick={() => {
                    if (item.subMenus.length > 0) {
                      isOpen === item.link
                        ? setIsOpen(0)
                        : setIsOpen(item.link);
                    } else {
                      navigate(item.link);
                      sessionStorage.removeItem("tab");
                    }
                  }}
                >
                  <SidebarPretendardText
                    style={{
                      color: `${path?.includes(item.link) ? "#27BEFF" : ""}`,
                    }}
                  >
                    {item.title}
                  </SidebarPretendardText>
                  {item.subMenus.length > 0 ? (
                    <PretendardText
                      style={{
                        width: "12px",
                        height: "12px",
                        color: `${
                          path?.includes(item.link) ? "#27BEFF" : "#F2F4F5"
                        }`,
                      }}
                    >
                      {path?.includes(item.link) || isOpen === item.link
                        ? "-"
                        : "+"}
                    </PretendardText>
                  ) : null}
                </NavWrapper>
              ) : null}
              <SubmenuListWrapper>
                {item.subMenus.length > 0
                  ? item.subMenus.map((item2, index2) => {
                      return path?.includes(item.link) ||
                        isOpen === item.link ? (
                        <div key={index2}>
                          {item2.isView ? (
                            <SubmenuWrapper
                              onClick={() => {
                                navigate(item2.link);
                                sessionStorage.removeItem("tab");
                              }}
                              style={{
                                backgroundColor: `${
                                  path?.includes(item2.link) ? "#ffffff10" : ""
                                }`,
                                cursor: "pointer",
                              }}
                            >
                              <SubPretendardText
                                style={{
                                  color: `${
                                    path?.includes(item2.link) ? "#FFF" : ""
                                  }`,
                                }}
                              >
                                {item2.title}
                              </SubPretendardText>
                            </SubmenuWrapper>
                          ) : null}
                        </div>
                      ) : null;
                    })
                  : null}
              </SubmenuListWrapper>
            </div>
          );
        })}
      </NavListWrapper>
    </SidebarWrapper>
  );
};

export default Sidebar;

const SidebarWrapper = styled.div.attrs((props) => {})`
  width: 265px;
  min-height: 100vh;
  height: auto;
  background-color: #2e313e;
  padding-top: 83px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  position: relative;
  z-index: 5;
`;

const ProfileWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  height: 58px;
  padding: 10px 25px;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const NavListWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
`;

const NavWrapper = styled.div.attrs((props) => {})`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 25px;
  cursor: pointer;
`;

const SidebarPretendardText = styled(PretendardText).attrs((props) => {})`
  color: #f2f4f5;
  font-size: 18px;
  font-weight: 600;
  line-height: 32.5px;
  letter-spacing: -0.054px;
  cursor: pointer;
`;

const SubmenuListWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 13.5px;
`;

const SubmenuWrapper = styled.div.attrs((props) => {})`
  width: 236px;
  height: 46px;
  display: flex;
  align-items: center;
  padding: 14px 25px;
  border-radius: 8px;
`;

const SubPretendardText = styled(PretendardText).attrs((props) => {})`
  color: #778088;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.048px;
  cursor: pointer;
`;
