import api from "./api.js";

export default class BasicApi {
  static async getBasicInfo(params) {
    return await api.get(`admin/base-info`, params);
  }

  static async updateBasicInfo(data) {
    return await api.patch(`admin/base-info`, data);
  }
}
