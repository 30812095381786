import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Header from "../../../component/organisms/header";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Sidebar from "../../../component/organisms/sidebar";
import DetailPageLayout from "../../../component/atoms/layout/detail/detailPageLayout";
import TableTitle from "../../../component/atoms/text/table/tableTitle";
import TableTop from "../../../component/atoms/layout/table/tableTop";
import TableCount from "../../../component/molecules/tableCount";
import TableSearchBtn from "../../../component/atoms/layout/table/tableSearchBtn";
import SearchBar from "../../../component/atoms/input/searchBar";
import { route } from "../../../router/route";
import ExcelBtn from "../../../component/atoms/button/excelBtn";
import Table from "../../../component/atoms/layout/table/table";
import Tr from "../../../component/atoms/layout/table/tr";
import Th from "../../../component/atoms/layout/table/th";
import Checkbox from "../../../component/atoms/input/checkbox";
import TableText from "../../../component/atoms/text/table/tableText";
import Td from "../../../component/atoms/layout/table/td";
import TableFilter from "../../../component/atoms/layout/table/tableFilter";
import DetailBtn from "../../../component/atoms/button/detailBtn";
import Pagination from "../../../component/molecules/pagination";
import { saveAs } from "file-saver";
import ExcelApi from "../../../api/excel.api";
import moment from "moment";
import { errorHandler } from "../../../util/errorHandler";
import { toast } from "react-toastify";
import CalculateApi from "../../../api/calculate.api";
import { toastMsg } from "../../../util/toastMsg";
import Modal from "../../../component/templates/Modal";
import { formatNumberWithCommas } from "../../../util/formatNumber";

const PaymentRequest = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams(); // eslint-disable-line no-unused-vars

  // ----------------------------------- //
  // ---------- 마운트가 되면 ---------- //
  // --------------------------------- //
  useEffect(() => {
    setKeyword(searchParams.get("keyword") ? searchParams.get("keyword") : "");
    getPaymentRequestList();
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  // ----- 목록 가져오기
  const page = useMemo(() => {
    return searchParams.get("page") ? searchParams.get("page") : 1;
  }, [searchParams]);

  const [pagination, setPagination] = useState({
    size: 10, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈
    totalCount: 0,
    totalPages: 0,
    first: false,
    last: false,
  });

  const [paymentRequestList, setPaymentRequestList] = useState([]);
  const [paymentRequestCount, setPaymentRequestCount] = useState(0);
  const [keyword, setKeyword] = useState("");

  const getPaymentRequestList = async () => {
    try {
      const data = {
        keyword: searchParams.get("keyword"),
        page: searchParams.get("page"),
        size: pagination.size,
      };
      let responseData = (await CalculateApi.getList(data)).data.data;

      setPaymentRequestCount(responseData.totalCount);
      setPaymentRequestList(
        responseData.content.map((item) => {
          return {
            ...item,
            checked: false,
          };
        })
      );
      setPagination({
        ...pagination,
        totalCount: responseData.totalCount ? responseData.totalCount : 0,
        totalPages: responseData.totalPage
          ? responseData.totalPage
          : Math.ceil(responseData.totalCount / 10),
        first: responseData.isFirst ? responseData.isFirst : false,
        last: responseData.isLast ? responseData.isLast : false,
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  // -------------------------------- //
  // ---------- 구매 확정 ---------- //
  // ------------------------------ //
  const [calculateFilterOpen, setCalculateFilterOpen] = useState(-1);
  const [calculateId, setCalculateId] = useState(null);
  const [calculate, setCalculate] = useState(null);
  const [calculateModalTitle, setCalculateModalTitle] = useState("");
  const [calculateModalOpen, setCalculateModalOpen] = useState(false);

  const calculateFunc = async () => {
    try {
      const data = {
        calculateStatus: calculate,
      };
      await CalculateApi.updateCalculateStatus(calculateId, data);
      toast(toastMsg.create);
      setCalculateFilterOpen(-1);
      setCalculateModalOpen(false);
      getPaymentRequestList();
    } catch (error) {
      errorHandler(error);
    }
  };

  // -------------------------------- //
  // ---------- 체크 박스 ---------- //
  // ------------------------------ //

  const isAllCheck = useMemo(() => {
    const checkedList = paymentRequestList.filter((item) => item.checked);
    return (
      checkedList.length !== 0 &&
      checkedList.length === paymentRequestList.length
    );
  }, [paymentRequestList]);

  //------------엑셀 생성
  const downloadExcel = async (excelData) => {
    const Excel = require("exceljs");
    const workbook = new Excel.Workbook();
    workbook.addWorksheet("Sheet One");
    const sheetOne = workbook.getWorksheet("Sheet One");

    sheetOne.columns = [
      { header: "이메일", key: "email", width: 40 },
      { header: "기업명", key: "merchTitle", width: 40 },
      { header: "정산금액", key: "calculateAmount", width: 40 },
      { header: "요청일", key: "createdAt", width: 40 },
      { header: "정산진행", key: "calculateStatusTitle", width: 40 },
    ];

    excelData.map((item) => {
      // 행 추가
      sheetOne.addRow(item);
    });
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), "정산 요청 관리.xlsx");
  };

  const getExcelAll = async () => {
    try {
      const excelArr = [];
      const response = (
        await ExcelApi.GetCalculateExcel({
          ids: [],
          allFlag: true,
        })
      ).data.data.content;

      response.map((item) => {
        const createDate = moment(item.createdAt).format("YYYY/MM/DD");
        excelArr.unshift({
          ...item,
          createdAt: createDate,
        });
      });

      downloadExcel(excelArr);
    } catch (error) {
      errorHandler(error);
    }
  };

  const getExcelChecked = async () => {
    const filter = paymentRequestList.filter((item) => item.checked);
    if (filter.length <= 0) {
      return toast("항목을 선택해주세요");
    }

    try {
      const excelArr = [];
      const response = (
        await ExcelApi.GetCalculateExcel({
          ids: filter.map((item) => item.id),
          allFlag: false,
        })
      ).data.data.content;

      response.map((item) => {
        const createDate = moment(item.createdAt).format("YYYY/MM/DD");
        excelArr.unshift({
          ...item,
          createdAt: createDate,
        });
      });

      downloadExcel(excelArr);
    } catch (error) {
      errorHandler(error);
    }
  };
  //------------액셀 부분 끝

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <div style={{ marginTop: "40px" }}>
            <TableTitle>정산 요청 관리</TableTitle>
            <TableTop>
              <TableCount
                text={"항목"}
                count={
                  paymentRequestCount
                    ? paymentRequestCount
                    : paymentRequestList.length
                }
              />
              <TableSearchBtn>
                <SearchBar
                  value={keyword}
                  onChange={(e) => {
                    setKeyword(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      navigate(
                        `${route.payment_request}?page=1&keyword=${keyword}`,
                        {
                          replace: true,
                        }
                      );
                    }
                  }}
                  onClick={() => {
                    navigate(
                      `${route.payment_request}?page=1&keyword=${keyword}`,
                      {
                        replace: true,
                      }
                    );
                  }}
                />

                <ExcelBtn
                  onSelectClick={getExcelChecked}
                  onAllClick={getExcelAll}
                />
              </TableSearchBtn>
            </TableTop>
            <Table>
              <colgroup>
                {/* 체크박스 */}
                <col width="70px" />
                {/* 이메일 */}
                <col width="223px" />
                {/* 기업명 */}
                <col width="259px" />
                {/* 정산금액 */}
                <col width="133px" />
                {/* 요청일 */}
                <col width="166px" />
                {/* 정산 진행 */}
                <col width="118px" />
                {/* 상세보기 */}
                <col width="75px" />
              </colgroup>
              <thead>
                <Tr style={{ height: "41px", background: "#f2f4f5" }}>
                  <Th style={{ paddingLeft: "20px" }}>
                    <Checkbox
                      checked={isAllCheck}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setPaymentRequestList(
                            paymentRequestList.map((item) => {
                              return {
                                ...item,
                                checked: true,
                              };
                            })
                          );
                        } else {
                          setPaymentRequestList(
                            paymentRequestList.map((item) => {
                              return {
                                ...item,
                                checked: false,
                              };
                            })
                          );
                        }
                      }}
                    />
                  </Th>
                  <Th>
                    <TableText>이메일</TableText>
                  </Th>
                  <Th>
                    <TableText>기업명</TableText>
                  </Th>
                  <Th>
                    <TableText>정산금액</TableText>
                  </Th>
                  <Th>
                    <TableText>요청일</TableText>
                  </Th>
                  <Th>
                    <TableText>정산 진행</TableText>
                  </Th>
                  <Th>
                    <TableText>상세보기</TableText>
                  </Th>
                </Tr>
              </thead>
              <tbody>
                {paymentRequestList.map((item, index) => {
                  return (
                    <Tr
                      key={index}
                      style={{ height: "59px", background: "#fff" }}
                    >
                      <Td style={{ paddingLeft: "20px" }}>
                        <Checkbox
                          checked={item.checked}
                          onChange={() => {
                            item.checked = !item.checked;
                            setPaymentRequestList([...paymentRequestList]);
                          }}
                        />
                      </Td>
                      <Td>
                        <TableText>{item.email}</TableText>
                      </Td>
                      <Td>
                        <TableText>{item.companyName}</TableText>
                      </Td>
                      <Td>
                        <TableText>
                          {formatNumberWithCommas(item.calculateAmount)}
                        </TableText>
                      </Td>
                      <Td>
                        <TableText>
                          {moment.utc(item.createdAt).format("YYYY/MM/DD")}
                        </TableText>
                      </Td>
                      <Td>
                        <TableFilter
                          style={{
                            width: "90px",
                          }}
                          subStyle={{
                            width: "120px",
                          }}
                          onClick={() => {
                            calculateFilterOpen === index
                              ? setCalculateFilterOpen(-1)
                              : setCalculateFilterOpen(index);
                          }}
                          isOpen={calculateFilterOpen === index}
                          selectedValue={item.calculateStatus}
                          list={[
                            {
                              value: "REQUEST",
                              title: "정산요청",
                              onClick: () => {
                                setCalculateId(item.id);
                                setCalculate("REQUEST");
                                setCalculateModalTitle("정산요청");
                                setCalculateModalOpen(true);
                              },
                            },
                            {
                              value: "COMPLETE",
                              title: "정산완료",
                              onClick: () => {
                                setCalculateId(item.id);
                                setCalculate("COMPLETE");
                                setCalculateModalTitle("정산완료");
                                setCalculateModalOpen(true);
                              },
                            },
                          ]}
                        />
                      </Td>
                      <Td>
                        <DetailBtn
                          onClick={() => {
                            navigate(
                              route.payment_request_detail + `/${item.id}`
                            );
                          }}
                        />
                      </Td>
                    </Tr>
                  );
                })}
              </tbody>
            </Table>
            <Pagination
              route={route.payment_request}
              queryString={`keyword=${searchParams.get("keyword") || ""}`}
              currentPage={page}
              totalPages={pagination.totalPages}
              rangeSize={pagination.rangeSize}
              first={pagination.first}
              last={pagination.last}
            />
          </div>
        </DetailPageLayout>
      </MainLayout>

      {calculateModalOpen ? (
        <Modal
          title={`${calculateModalTitle} 하시겠습니까?`}
          setModalOpen={setCalculateModalOpen}
          onClick={() => {
            calculateFunc();
          }}
        />
      ) : null}
    </>
  );
};

export default PaymentRequest;
