import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { errorHandler } from "../../../util/errorHandler";
import { isNull } from "../../../util/check";
import { toast } from "react-toastify";
import { toastMsg } from "../../../util/toastMsg";
import Header from "../../../component/organisms/header";
import MainLayout from "./../../../component/atoms/layout/mainLayout";
import Sidebar from "./../../../component/organisms/sidebar";
import DetailPageLayout from "./../../../component/atoms/layout/detail/detailPageLayout";
import DetailTop from "./../../../component/atoms/layout/detail/detailTop";
import GoBack from "./../../../component/molecules/goBack";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import DetailPageTitle from "./../../../component/atoms/text/detail/detailPageTitle";
import DetailPageForm from "./../../../component/atoms/layout/detail/detailPageForm";
import DetailFormTitle from "./../../../component/atoms/text/detail/detailFormTitle";
import MainInput from "./../../../component/atoms/input/mainInput";
import Radio from "./../../../component/atoms/input/radio";
import SaveBtn from "./../../../component/atoms/button/saveBtn";
import Modal from "../../../component/templates/Modal";
import { modalMsg } from "../../../util/modalMsg";
import styled from "styled-components";
import DetailFormText from "./../../../component/atoms/text/detail/detailFormText";
import Checkbox from "./../../../component/atoms/input/checkbox";
import PretendardText from "./../../../component/atoms/text/pretendardText";
import MemberApi from "../../../api/member.api";
import TextFormEditor from "../../../component/atoms/input/textFormEditor";

const CompanyMemberEdit = () => {
  const navigate = useNavigate();
  let { id } = useParams();

  // ---------------------------------------------------- //
  // ----------------- 데이터 가져오기 ----------------- //
  // -------------------------------------------------- //
  useEffect(() => {
    getCompanyMemberInfo();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // --------------------------------------------- //
  // ----------------- 체크박스 ----------------- //
  // ------------------------------------------- //
  const handleCheckedChange = (e) => {
    const { id, checked } = e.target;
    setCompanyMemberInfo((prevState) => ({
      ...prevState,
      [id]: checked,
    }));
  };

  const [companyMemberInfo, setCompanyMemberInfo] = useState({});
  const [companyProfileInfo, setCompanyProfileInfo] = useState({});

  const getCompanyMemberInfo = async () => {
    try {
      const response = (await MemberApi.getById(id)).data.data.content;
      setCompanyMemberInfo(response);
      setCompanyProfileInfo(response.profile);
    } catch (error) {
      errorHandler(error);
    }
  };

  // --------------------------------------------- //
  // ----------------- 셀렉트박스 ----------------- //
  // ------------------------------------------- //
  const [sectorSelectBoxOpen, setSectorSelectBoxOpen] = useState(false);
  const [regionSelectBoxOpen, setRegionSelectBoxOpen] = useState(false);

  const sectorList = [
    { title: "없음", value: "NONE" },
    { title: "음식점업", value: "RESTAURANT" },
    { title: "부동산업", value: "REAL_ESTATE" },
    { title: "전문, 과학 및 기술 서비스업", value: "TECHNOLOGY" },
    { title: "통신판매업", value: "ONLINE" },
    { title: "예술, 스포츠 및 여가 관련 서비스업", value: "ART" },
    { title: "교육 서비스업", value: "EDUCATION" },
    { title: "수리 및 개인 서비스업", value: "PERSONAL" },
  ];

  const regionList = [
    { title: "서울", value: "SEOUL" },
    { title: "경기", value: "GYEONGGI" },
    { title: "인천", value: "INCHEON" },
    { title: "강원", value: "GANGWON" },
    { title: "충북", value: "CHUNGBUK" },
    { title: "충남", value: "CHUNGNAM" },
    { title: "대전", value: "DAEJEON" },
    { title: "세종", value: "SEJONG" },
    { title: "전북", value: "JEONBUK" },
    { title: "전남", value: "JEONNAM" },
    { title: "광주", value: "GWANGJU" },
    { title: "경북", value: "GYEONGBUK" },
    { title: "경남", value: "GYEONGNAM" },
    { title: "대구", value: "DAEGU" },
    { title: "울산", value: "ULSAN" },
    { title: "부산", value: "BUSAN" },
    { title: "제주", value: "JEJU" },
  ];

  // ---------------------------------------------------- //
  // ----------------- 기본정보 저장하기 ----------------- //
  // --------------------------------------------------- //
  const [saveModalOpen, setSaveModalOpen] = useState(false);

  const saveBtnHandler = () => {
    if (
      isNull(companyMemberInfo.name) ||
      isNull(companyMemberInfo.age) ||
      isNull(companyMemberInfo.sector) ||
      !companyMemberInfo.agreement ||
      !companyMemberInfo.privacy ||
      !companyMemberInfo.providePrivacy
    ) {
      return toast("필수사항을 모두 입력해주세요.");
    }

    setSaveModalOpen(true);
  };

  const saveFunc = async () => {
    try {
      const data = {
        name: companyMemberInfo.name,
        age: companyMemberInfo.age,
        sector: companyMemberInfo.sector,
        agreement: companyMemberInfo.agreement,
        privacy: companyMemberInfo.privacy,
        providePrivacy: companyMemberInfo.providePrivacy,
        advertisement: companyMemberInfo.advertisement,
        approval: companyMemberInfo.approval,
      };

      await MemberApi.update(id, data);
      setSaveModalOpen(false);
      navigate(-1);
      toast(toastMsg.update);
    } catch (error) {
      errorHandler(error);
    }
  };

  // ---------------------------------------------------- //
  // ----------------- 기업정보 저장하기 ----------------- //
  // --------------------------------------------------- //
  const [profileSaveModalOpen, setProfileSaveModalOpen] = useState(false);

  const profileSaveBtnHandler = () => {
    if (
      isNull(companyProfileInfo.companyName) ||
      isNull(companyProfileInfo.region) ||
      isNull(companyProfileInfo.companyRegion) ||
      isNull(companyProfileInfo.ceo) ||
      isNull(companyProfileInfo.tel) ||
      isNull(companyProfileInfo.companyNumber) ||
      isNull(companyProfileInfo.intro)
    ) {
      return toast("필수사항을 모두 입력해주세요.");
    }

    setProfileSaveModalOpen(true);
  };

  const profileSaveFunc = async () => {
    try {
      const data = {
        companyName: companyProfileInfo.companyName,
        region: companyProfileInfo.region,
        companyRegion: companyProfileInfo.companyRegion,
        ceo: companyProfileInfo.ceo,
        tel: companyProfileInfo.tel,
        intro: companyProfileInfo.intro,
        companyNumber: companyProfileInfo.companyNumber,
      };

      await MemberApi.updateProfile(companyProfileInfo.id, data);
      setProfileSaveModalOpen(false);
      navigate(-1);
      toast(toastMsg.update);
    } catch (error) {
      errorHandler(error);
    }
  };

  // 에디터
  const [position, setPosition] = useState(0);
  const [isBlurEditor, setIsBlurEditor] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <Header />

      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
          </DetailTop>

          {/* ------------------------------------- */}
          {/* ---------- 기본정보 수정하기 ---------- */}
          {/* ------------------------------------- */}
          <DetailPage style={{ minHeight: "750px" }}>
            <DetailPageTitle>기본정보 수정하기</DetailPageTitle>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "20px" }}
            >
              <DetailPageForm>
                <DetailFormTitle>이메일</DetailFormTitle>
                <MainInput
                  style={{
                    width: 338,
                  }}
                  type={"text"}
                  placeholder={"이메일을 입력해주세요"}
                  value={companyMemberInfo.email}
                  disabled={true}
                />
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>이름*</DetailFormTitle>
                <MainInput
                  style={{
                    width: 338,
                  }}
                  type={"text"}
                  placeholder={"이름을 입력해주세요"}
                  value={companyMemberInfo.name}
                  onChange={(e) =>
                    setCompanyMemberInfo({
                      ...companyMemberInfo,
                      name: e.target.value,
                    })
                  }
                />
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>전화번호</DetailFormTitle>
                <MainInput
                  style={{
                    width: 338,
                  }}
                  type={"text"}
                  placeholder={"전화번호를 입력해주세요"}
                  value={companyMemberInfo.phoneNumber}
                  disabled={true}
                />
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>나이*</DetailFormTitle>
                <MainInput
                  style={{
                    width: 338,
                  }}
                  type={"number"}
                  placeholder={"나이를 입력해주세요"}
                  value={companyMemberInfo.age}
                  onChange={(e) =>
                    setCompanyMemberInfo({
                      ...companyMemberInfo,
                      age: e.target.value,
                    })
                  }
                />
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>업종*</DetailFormTitle>
                <div>
                  <div style={{ position: "relative", width: "338px" }}>
                    <SelectBoxWrapper
                      onClick={() => {
                        setSectorSelectBoxOpen(!sectorSelectBoxOpen);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <PretendardText
                        style={{
                          color: "#262C31",
                          fontSize: "15px",
                          fontWeight: "400",
                          lineHeight: "21.25px",
                        }}
                      >
                        {companyMemberInfo.sectorTitle
                          ? companyMemberInfo.sectorTitle
                          : "업종을 선택해 주세요."}
                      </PretendardText>
                      <img
                        src="/assets/admin/icons/ico_select_arrow_down.svg"
                        alt=""
                      />
                    </SelectBoxWrapper>
                    {sectorSelectBoxOpen ? (
                      <SubMenuWrapper>
                        {sectorList.map((item, index) => {
                          return (
                            <SubMenuTextWrapper
                              key={index}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "318px",
                                height: "42px",
                                padding: "10px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setCompanyMemberInfo({
                                  ...companyMemberInfo,
                                  sectorTitle: item.title,
                                  sector: item.value,
                                });
                                setSectorSelectBoxOpen(false);
                              }}
                            >
                              <PretendardText
                                style={{
                                  color: "#262C31",
                                  fontSize: "15px",
                                  fontWeight: "500",
                                  lineHeight: "21.5px",
                                }}
                              >
                                {item.title}
                              </PretendardText>
                              {companyMemberInfo.sectorTitle === item.title ? (
                                <img
                                  src="/assets/admin/icons/ico_select_checked.svg"
                                  alt=""
                                />
                              ) : null}
                            </SubMenuTextWrapper>
                          );
                        })}
                      </SubMenuWrapper>
                    ) : null}
                  </div>
                </div>
              </DetailPageForm>

              {/* 약관 동의 */}
              <DetailPageForm
                style={{
                  alignItems: "flex-start",
                }}
              >
                <DetailFormTitle>약관 동의</DetailFormTitle>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                  }}
                >
                  <CheckboxWrapper>
                    <DetailFormText>이용약관 동의 (필수)</DetailFormText>
                    <Checkbox
                      id="agreement"
                      checked={companyMemberInfo.agreement}
                      onChange={handleCheckedChange}
                    />
                  </CheckboxWrapper>
                  <CheckboxWrapper>
                    <DetailFormText>
                      개인정보 수집 및 이용동의 (필수)
                    </DetailFormText>
                    <Checkbox
                      id="privacy"
                      checked={companyMemberInfo.privacy}
                      onChange={handleCheckedChange}
                    />
                  </CheckboxWrapper>
                  <CheckboxWrapper>
                    <DetailFormText>
                      개인정보 제 3자 제공 동의 (필수)
                    </DetailFormText>
                    <Checkbox
                      id="providePrivacy"
                      checked={companyMemberInfo.providePrivacy}
                      onChange={handleCheckedChange}
                    />
                  </CheckboxWrapper>
                </div>
              </DetailPageForm>

              {/* 알림 수신 동의 */}
              <DetailPageForm>
                <DetailFormTitle>알림 수신 동의</DetailFormTitle>
                <CheckboxWrapper>
                  <DetailFormText>광고성 정보 수신 동의 (선택)</DetailFormText>
                  <Checkbox
                    id="advertisement"
                    checked={companyMemberInfo.advertisement}
                    onChange={handleCheckedChange}
                  />
                </CheckboxWrapper>
              </DetailPageForm>

              {/* 승인 상태 */}
              <DetailPageForm>
                <DetailFormTitle>승인 상태*</DetailFormTitle>
                <div style={{ display: "flex", gap: "20px" }}>
                  <Radio
                    id={""}
                    valueText={"미승인"}
                    checked={!companyMemberInfo.approval}
                    onChange={() => {
                      setCompanyMemberInfo({
                        ...companyMemberInfo,
                        approval: false,
                      });
                    }}
                  />
                  <Radio
                    id={""}
                    valueText={"승인"}
                    checked={companyMemberInfo.approval}
                    onChange={() => {
                      setCompanyMemberInfo({
                        ...companyMemberInfo,
                        approval: true,
                      });
                    }}
                  />
                </div>
              </DetailPageForm>
            </div>
            <SaveBtn
              style={{ bottom: "40px", right: "40px" }}
              onClick={() => {
                saveBtnHandler();
              }}
            />
          </DetailPage>

          {/* ------------------------------------- */}
          {/* ---------- 기업정보 수정하기 ---------- */}
          {/* ------------------------------------- */}
          <DetailPage style={{ minHeight: "712px" }}>
            <DetailPageTitle>기업정보 수정하기</DetailPageTitle>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "20px" }}
            >
              {/* 기업명 */}
              <DetailPageForm>
                <DetailFormTitle>기업명*</DetailFormTitle>
                <MainInput
                  style={{
                    width: 338,
                  }}
                  type={"email"}
                  placeholder={"기업명을 입력해주세요"}
                  value={companyProfileInfo.companyName}
                  onChange={(e) =>
                    setCompanyProfileInfo({
                      ...companyProfileInfo,
                      companyName: e.target.value,
                    })
                  }
                />
              </DetailPageForm>

              {/* 지역 */}
              <DetailPageForm>
                <DetailFormTitle>지역*</DetailFormTitle>
                <div>
                  <div style={{ position: "relative", width: "338px" }}>
                    <SelectBoxWrapper
                      onClick={() => {
                        setRegionSelectBoxOpen(!regionSelectBoxOpen);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <PretendardText
                        style={{
                          color: "#262C31",
                          fontSize: "15px",
                          fontWeight: "400",
                          lineHeight: "21.25px",
                        }}
                      >
                        {companyProfileInfo.regionTitle
                          ? companyProfileInfo.regionTitle
                          : "지역을 선택해 주세요."}
                      </PretendardText>
                      <img
                        src="/assets/admin/icons/ico_select_arrow_down.svg"
                        alt=""
                      />
                    </SelectBoxWrapper>
                    {regionSelectBoxOpen ? (
                      <SubMenuWrapper>
                        {regionList.map((item, index) => {
                          return (
                            <SubMenuTextWrapper
                              key={index}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "318px",
                                height: "42px",
                                padding: "10px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setCompanyProfileInfo({
                                  ...companyProfileInfo,
                                  regionTitle: item.title,
                                  region: item.value,
                                });
                                setRegionSelectBoxOpen(false);
                              }}
                            >
                              <PretendardText
                                style={{
                                  color: "#262C31",
                                  fontSize: "15px",
                                  fontWeight: "500",
                                  lineHeight: "21.5px",
                                }}
                              >
                                {item.title}
                              </PretendardText>
                              {companyProfileInfo.regionTitle === item.title ? (
                                <img
                                  src="/assets/admin/icons/ico_select_checked.svg"
                                  alt=""
                                />
                              ) : null}
                            </SubMenuTextWrapper>
                          );
                        })}
                      </SubMenuWrapper>
                    ) : null}
                  </div>
                </div>
              </DetailPageForm>

              {/* 사업장 소재지 */}
              <DetailPageForm>
                <DetailFormTitle>사업장 소재지*</DetailFormTitle>
                <MainInput
                  style={{
                    width: 730,
                  }}
                  type={"text"}
                  placeholder={"사업장 소재지를 입력해주세요"}
                  value={companyProfileInfo.companyRegion}
                  onChange={(e) =>
                    setCompanyProfileInfo({
                      ...companyProfileInfo,
                      companyRegion: e.target.value,
                    })
                  }
                />
              </DetailPageForm>

              {/* 사업자번호 */}
              <DetailPageForm>
                <DetailFormTitle>사업자번호*</DetailFormTitle>
                <MainInput
                  style={{
                    width: 338,
                  }}
                  type={"text"}
                  placeholder={"사업자번호를 입력해주세요"}
                  value={companyProfileInfo.companyNumber}
                  onChange={(e) =>
                    setCompanyProfileInfo({
                      ...companyProfileInfo,
                      companyNumber: e.target.value,
                    })
                  }
                />
              </DetailPageForm>

              {/* 대표자명 */}
              <DetailPageForm>
                <DetailFormTitle>대표자명*</DetailFormTitle>
                <MainInput
                  style={{
                    width: 338,
                  }}
                  type={"email"}
                  placeholder={"대표자명을 입력해주세요"}
                  value={companyProfileInfo.ceo}
                  onChange={(e) =>
                    setCompanyProfileInfo({
                      ...companyProfileInfo,
                      ceo: e.target.value,
                    })
                  }
                />
              </DetailPageForm>

              {/* 전화번호 */}
              <DetailPageForm>
                <DetailFormTitle>전화번호</DetailFormTitle>
                <MainInput
                  style={{
                    width: 338,
                  }}
                  type={"text"}
                  placeholder={"전화번호를 입력해주세요"}
                  value={companyProfileInfo.tel}
                  onChange={(e) =>
                    setCompanyProfileInfo({
                      ...companyProfileInfo,
                      tel: e.target.value,
                    })
                  }
                />
              </DetailPageForm>

              {/* 기업소개 */}
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>기업 소개*</DetailFormTitle>
                {/* <QuillEditor /> */}
                <TextFormEditor
                  placeholder={"상세설명을 입력하세요"}
                  value={companyProfileInfo.intro}
                  onChange={(contents) => {
                    setCompanyProfileInfo((prevInfo) => ({
                      ...prevInfo,
                      intro: contents,
                    }));
                  }}
                  editorHeight={500}
                  style={{
                    width: "100%",
                    marginBottom: "100px",
                  }}
                  position={position}
                  isBlur={isBlurEditor}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                />
              </DetailPageForm>
            </div>
            <SaveBtn
              style={{ bottom: "40px", right: "40px" }}
              onClick={() => {
                profileSaveBtnHandler();
              }}
            />
          </DetailPage>
        </DetailPageLayout>
      </MainLayout>

      {saveModalOpen ? (
        <Modal
          title={modalMsg.update?.title}
          text={modalMsg.update?.content}
          setModalOpen={setSaveModalOpen}
          onClick={() => {
            saveFunc();
          }}
        />
      ) : null}

      {profileSaveModalOpen ? (
        <Modal
          title={modalMsg.update?.title}
          text={modalMsg.update?.content}
          setModalOpen={setProfileSaveModalOpen}
          onClick={() => {
            profileSaveFunc();
          }}
        />
      ) : null}
    </>
  );
};

export default CompanyMemberEdit;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const SelectBoxWrapper = styled.div.attrs((props) => {})`
  width: 338px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px 15px;
  border-radius: 4px;
  border: 1px solid #adb4ba;
  background: #fff;
`;

const SubMenuWrapper = styled.div.attrs((props) => {})`
  width: 338px;
  min-height: 356px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(10, 13, 20, 0.15);
  position: absolute;
  top: 50px;
  right: 0;
  padding: 10px;
  overflow-y: auto;
  z-index: 5;
`;

const SubMenuTextWrapper = styled.div.attrs((props) => {})`
  display: "flex";
  justify-content: "space-between";
  align-items: "center";
  width: "318px";
  height: "42px";
  padding: "10px";
  cursor: "pointer";
  border-radius: 3px;

  &:hover {
    background-color: #f2f4f5;
  }
`;
