import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { errorHandler } from "../../../util/errorHandler";
import { isNull } from "../../../util/check";
import { toast } from "react-toastify";
import { toastMsg } from "../../../util/toastMsg";
import Header from "../../../component/organisms/header";
import MainLayout from "./../../../component/atoms/layout/mainLayout";
import Sidebar from "./../../../component/organisms/sidebar";
import DetailPageLayout from "./../../../component/atoms/layout/detail/detailPageLayout";
import DetailTop from "./../../../component/atoms/layout/detail/detailTop";
import GoBack from "./../../../component/molecules/goBack";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import DetailPageTitle from "./../../../component/atoms/text/detail/detailPageTitle";
import DetailPageForm from "./../../../component/atoms/layout/detail/detailPageForm";
import DetailFormTitle from "./../../../component/atoms/text/detail/detailFormTitle";
import MainInput from "./../../../component/atoms/input/mainInput";
import SaveBtn from "./../../../component/atoms/button/saveBtn";
import Modal from "../../../component/templates/Modal";
import { modalMsg } from "../../../util/modalMsg";
import styled from "styled-components";
import DetailFormText from "./../../../component/atoms/text/detail/detailFormText";
import Checkbox from "./../../../component/atoms/input/checkbox";
import PretendardText from "./../../../component/atoms/text/pretendardText";
import MemberApi from "../../../api/member.api";
import { formatPhoneNumber } from "../../../util/formatNumber";

const CommonMemberEdit = () => {
  const navigate = useNavigate();
  let { id } = useParams();

  // ---------------------------------------------------- //
  // ----------------- 데이터 가져오기 ----------------- //
  // -------------------------------------------------- //
  useEffect(() => {
    getCommonMemberInfo();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // --------------------------------------------- //
  // ----------------- 체크박스 ----------------- //
  // ------------------------------------------- //
  const handleCheckedChange = (e) => {
    const { id, checked } = e.target;
    setCommonMemberInfo((prevState) => ({
      ...prevState,
      [id]: checked,
    }));
  };

  const [commonMemberInfo, setCommonMemberInfo] = useState({});

  const getCommonMemberInfo = async () => {
    try {
      const response = (await MemberApi.getById(id)).data.data.content;
      setCommonMemberInfo(response);
    } catch (error) {
      errorHandler(error);
    }
  };

  // --------------------------------------------- //
  // ----------------- 셀렉트박스 ----------------- //
  // ------------------------------------------- //
  const [selectBoxOpen, setSelectBoxOpen] = useState(false);

  const categoryList = [
    { title: "없음", value: "NONE" },
    { title: "음식점업", value: "RESTAURANT" },
    { title: "부동산업", value: "REAL_ESTATE" },
    { title: "전문, 과학 및 기술 서비스업", value: "TECHNOLOGY" },
    { title: "통신판매업", value: "ONLINE" },
    { title: "예술, 스포츠 및 여가 관련 서비스업", value: "ART" },
    { title: "교육 서비스업", value: "EDUCATION" },
    { title: "수리 및 개인 서비스업", value: "PERSONAL" },
  ];

  // --------------------------------------------- //
  // ----------------- 저장하기 ----------------- //
  // ------------------------------------------- //
  const [saveModalOpen, setSaveModalOpen] = useState(false);

  const saveBtnHandler = () => {
    if (
      isNull(commonMemberInfo.name) ||
      isNull(commonMemberInfo.age) ||
      isNull(commonMemberInfo.sector) ||
      !commonMemberInfo.agreement ||
      !commonMemberInfo.privacy ||
      !commonMemberInfo.providePrivacy
    ) {
      return toast("필수사항을 모두 입력해주세요.");
    }

    setSaveModalOpen(true);
  };

  const saveFunc = async () => {
    try {
      const data = {
        name: commonMemberInfo.name,
        age: commonMemberInfo.age,
        sector: commonMemberInfo.sector,
        agreement: commonMemberInfo.agreement,
        privacy: commonMemberInfo.privacy,
        providePrivacy: commonMemberInfo.providePrivacy,
        advertisement: commonMemberInfo.advertisement,
        approval: commonMemberInfo.approval,
      };

      await MemberApi.update(id, data);
      setSaveModalOpen(false);
      navigate(-1);
      toast(toastMsg.update);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />

      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
          </DetailTop>
          <DetailPage style={{ minHeight: "705px" }}>
            <DetailPageTitle>수정하기</DetailPageTitle>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "20px" }}
            >
              <DetailPageForm>
                <DetailFormTitle>이메일</DetailFormTitle>
                <MainInput
                  style={{
                    width: 338,
                  }}
                  type={"text"}
                  placeholder={"이메일을 입력해주세요"}
                  value={commonMemberInfo.email}
                  disabled={true}
                />
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>이름*</DetailFormTitle>
                <MainInput
                  style={{
                    width: 338,
                  }}
                  type={"text"}
                  placeholder={"이름을 입력해주세요"}
                  value={commonMemberInfo.name}
                  onChange={(e) =>
                    setCommonMemberInfo({
                      ...commonMemberInfo,
                      name: e.target.value,
                    })
                  }
                />
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>전화번호</DetailFormTitle>
                <MainInput
                  style={{
                    width: 338,
                  }}
                  type={"text"}
                  placeholder={"전화번호를 입력해주세요"}
                  value={formatPhoneNumber(commonMemberInfo.phoneNumber)}
                  disabled={true}
                />
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>나이*</DetailFormTitle>
                <MainInput
                  style={{
                    width: 338,
                  }}
                  type={"number"}
                  placeholder={"나이를 입력해주세요"}
                  value={commonMemberInfo.age}
                  onChange={(e) => {
                    setCommonMemberInfo({
                      ...commonMemberInfo,
                      age: Number(e.target.value),
                    });
                  }}
                />
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>업종*</DetailFormTitle>
                <div>
                  <div style={{ position: "relative", width: "338px" }}>
                    <SelectBoxWrapper
                      onClick={() => {
                        setSelectBoxOpen(!selectBoxOpen);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <PretendardText
                        style={{
                          color: "#262C31",
                          fontSize: "15px",
                          fontWeight: "400",
                          lineHeight: "21.25px",
                        }}
                      >
                        {commonMemberInfo.sectorTitle
                          ? commonMemberInfo.sectorTitle
                          : "업종을 선택해 주세요."}
                      </PretendardText>
                      <img
                        src="/assets/admin/icons/ico_select_arrow_down.svg"
                        alt=""
                      />
                    </SelectBoxWrapper>
                    {selectBoxOpen ? (
                      <SubMenuWrapper>
                        {categoryList.map((item, index) => {
                          return (
                            <SubMenuTextWrapper
                              key={index}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "318px",
                                height: "42px",
                                padding: "10px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setCommonMemberInfo({
                                  ...commonMemberInfo,
                                  sectorTitle: item.title,
                                  sector: item.value,
                                });
                                setSelectBoxOpen(false);
                              }}
                            >
                              <PretendardText
                                style={{
                                  color: "#262C31",
                                  fontSize: "15px",
                                  fontWeight: "500",
                                  lineHeight: "21.5px",
                                }}
                              >
                                {item.title}
                              </PretendardText>
                              {commonMemberInfo.sectorTitle === item.title ? (
                                <img
                                  src="/assets/admin/icons/ico_select_checked.svg"
                                  alt=""
                                />
                              ) : null}
                            </SubMenuTextWrapper>
                          );
                        })}
                      </SubMenuWrapper>
                    ) : null}
                  </div>
                </div>
              </DetailPageForm>

              {/* 약관 동의 */}
              <DetailPageForm
                style={{
                  alignItems: "flex-start",
                }}
              >
                <DetailFormTitle>약관 동의</DetailFormTitle>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                  }}
                >
                  <CheckboxWrapper>
                    <DetailFormText>이용약관 동의 (필수)</DetailFormText>
                    <Checkbox
                      id="agreement"
                      checked={commonMemberInfo.agreement}
                      onChange={handleCheckedChange}
                    />
                  </CheckboxWrapper>
                  <CheckboxWrapper>
                    <DetailFormText>
                      개인정보 수집 및 이용동의 (필수)
                    </DetailFormText>
                    <Checkbox
                      id="privacy"
                      checked={commonMemberInfo.privacy}
                      onChange={handleCheckedChange}
                    />
                  </CheckboxWrapper>
                  <CheckboxWrapper>
                    <DetailFormText>
                      개인정보 제 3자 제공 동의 (필수)
                    </DetailFormText>
                    <Checkbox
                      id="providePrivacy"
                      checked={commonMemberInfo.providePrivacy}
                      onChange={handleCheckedChange}
                    />
                  </CheckboxWrapper>
                </div>
              </DetailPageForm>

              {/* 알림 수신 동의 */}
              <DetailPageForm>
                <DetailFormTitle>알림 수신 동의</DetailFormTitle>
                <CheckboxWrapper>
                  <DetailFormText>광고성 정보 수신 동의 (선택)</DetailFormText>
                  <Checkbox
                    id="advertisement"
                    checked={commonMemberInfo.advertisement}
                    onChange={handleCheckedChange}
                  />
                </CheckboxWrapper>
              </DetailPageForm>
            </div>
            <SaveBtn
              style={{ bottom: "40px", right: "40px" }}
              onClick={() => {
                saveBtnHandler();
              }}
            />
          </DetailPage>
        </DetailPageLayout>
      </MainLayout>

      {saveModalOpen ? (
        <Modal
          title={modalMsg.update?.title}
          text={modalMsg.update?.content}
          setModalOpen={setSaveModalOpen}
          onClick={() => {
            saveFunc();
          }}
        />
      ) : null}
    </>
  );
};

export default CommonMemberEdit;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const SelectBoxWrapper = styled.div.attrs((props) => {})`
  width: 338px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px 15px;
  border-radius: 4px;
  border: 1px solid #adb4ba;
  background: #fff;
`;

const SubMenuWrapper = styled.div.attrs((props) => {})`
  width: 338px;
  min-height: 356px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(10, 13, 20, 0.15);
  position: absolute;
  top: 50px;
  right: 0;
  padding: 10px;
  overflow-y: auto;
  z-index: 5;
`;

const SubMenuTextWrapper = styled.div.attrs((props) => {})`
  display: "flex";
  justify-content: "space-between";
  align-items: "center";
  width: "318px";
  height: "42px";
  padding: "10px";
  cursor: "pointer";
  border-radius: 3px;

  &:hover {
    background-color: #f2f4f5;
  }
`;
