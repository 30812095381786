import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import MainLayout from "../../component/atoms/layout/mainLayout";
import PretendardText from "../../component/atoms/text/pretendardText";
import MainGraph from "../../component/molecules/mainGraph";
import Header from "../../component/organisms/header";
import Sidebar from "../../component/organisms/sidebar";
import { errorHandler } from "../../util/errorHandler";
import { route } from "../../router/route";
import HomeApi from "../../api/home.api";
import MemberApi from "../../api/member.api";

function Home(props) {
  const navigate = useNavigate();

  // -------------------------------------------------------------------- //
  // ------------------------------- 상태 ------------------------------- //
  // ------------------------------------------------------------------- //
  //방문자수, 날짜 정보
  const [totalVisitor, setTotalVisitor] = useState([]);
  const [graphMax, setGraphMax] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [totalMemberCount, setTotalMemberCount] = useState({
    memberActivationCount: 0,
    memberCount: 0,
    withdrawCount: 0,
  });
  const [visitLine, setVisitLine] = useState([]);

  // ---------------------------------------------------------------------- //
  // ------------------------------- 마운트 ------------------------------- //
  // --------------------------------------------------------------------- //

  // 로그인 안 되어있으면 로그인 페이지로 리다이렉션
  useEffect(() => {
    let token = sessionStorage.getItem("token");
    if (token === null || token === "" || token === undefined) {
      return navigate(route.login);
    }
    getMemberCount(); // 회원수 가져오기
    getVisitorCount(); // 방문자수 가져오기
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // 방문자수 페이지 넘길 때마다 가져오기
  useEffect(() => {
    getVisitorCount();
  }, [currentPage]); // eslint-disable-line react-hooks/exhaustive-deps

  // 회원수 가져오기
  const getMemberCount = async () => {
    try {
      const response = (await MemberApi.getMemberCount()).data.data;
      setTotalMemberCount(response);
    } catch (error) {
      errorHandler(error);
    }
  };

  // 방문자수 가져오기
  const getVisitorCount = async () => {
    try {
      const data = {
        page: currentPage,
        size: 10,
      };
      const response = (await HomeApi.getVisitorCount(data)).data.data;
      setTotalVisitor(response.content);
      //totalPage 구하기
      setTotalPages(Math.ceil(response.totalCount / 10));
    } catch (error) {
      errorHandler(error);
    }
  };

  // ---------------------------------------------------------------------- //
  // ------------------------------- 그래프 ------------------------------- //
  // --------------------------------------------------------------------- //

  useEffect(() => {
    let line = [];
    let max = 0;
    for (let i = 0; i < totalVisitor.length; i++) {
      if (max <= totalVisitor[i].count) {
        max = totalVisitor[i].count;
      }
    }
    setGraphMax(max);
    let per = max / 3;
    for (let i = 0; i < 3; i++) {
      line.push(Math.round(max - per * i));
    }
    setVisitLine(line);
  }, [totalVisitor]);

  // -------------------------------------------------------------------- //
  // ------------------------------- 메뉴 ------------------------------- //
  // ------------------------------------------------------------------- //
  const quickMenu = [
    {
      category: "사용자 관리",
      title: "일반회원 관리",
      route: route.user_common_member + "?page=1",
    },
    {
      category: "서비스 관리",
      title: "전문가 서비스 관리",
      route: route.service_company + "?page=1",
    },
    {
      category: "결제 관리",
      title: "결제 내역 관리",
      route: route.payment_history + "?page=1",
    },
    {
      category: "채팅 관리",
      title: "채팅 관리",
      route: route.chat_history + "?page=1",
    },
    {
      category: "큐레이션 관리",
      title: "큐레이션 관리",
      route: route.curation_post + "?page=1",
    },
    {
      category: "일반 관리",
      title: "일반 관리",
      route: route.general_alarm + "?page=1",
    },
  ];

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <HomeWrapper>
          <HomeHeader>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <PretendardText
                style={{
                  color: "#808991",
                  fontSize: "20px",
                  fontWeight: "600",
                  lineHeight: "34px",
                  letterSpacing: "-0.06px",
                }}
              >
                Welcome!
              </PretendardText>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <PretendardText
                  style={{
                    color: "#262C31",
                    fontSize: "40px",
                    fontWeight: "700",
                    lineHeight: "56px",
                    letterSpacing: "-0.28px",
                  }}
                >
                  안녕하세요. 관리자님 :)
                </PretendardText>
                <TotalMemberCountWrapper>
                  {/* 전체 사용자 수 */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: 14,
                    }}
                  >
                    <PretendardText
                      style={{
                        fontWeight: 700,
                        fontSize: 16,
                        lineHeight: "28px",
                        letterSpacing: "-0.3%",
                        color: "#636c73",
                      }}
                    >
                      전체 사용자 수
                    </PretendardText>
                    <TextDivider />
                    <PretendardText
                      style={{
                        fontWeight: 700,
                        fontSize: 16,
                        lineHeight: "28px",
                        letterSpacing: "-0.3%",
                        color: "#636c73",
                      }}
                    >
                      {totalMemberCount?.memberActivationCount}명
                    </PretendardText>
                  </div>

                  {/* 회원(가입/탈퇴) */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: 14,
                    }}
                  >
                    <PretendardText
                      style={{
                        fontWeight: 700,
                        fontSize: 16,
                        lineHeight: "28px",
                        letterSpacing: "-0.3%",
                        color: "#636c73",
                      }}
                    >
                      회원(가입/탈퇴)
                    </PretendardText>
                    <TextDivider />
                    <PretendardText
                      style={{
                        fontWeight: 700,
                        fontSize: 16,
                        lineHeight: "28px",
                        letterSpacing: "-0.3%",
                        color: "#636c73",
                      }}
                    >
                      {totalMemberCount?.memberCount}/
                      {totalMemberCount?.withdrawCount}
                    </PretendardText>
                  </div>
                </TotalMemberCountWrapper>
              </div>
            </div>
          </HomeHeader>
          <HomePretendardText style={{ marginBottom: "10px" }}>
            방문자 통계
          </HomePretendardText>
          <MainGraph
            visit={totalVisitor}
            graphMax={graphMax}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={totalPages}
            visitLine={visitLine}
          />
          <HomePretendardText style={{ marginBottom: "10px" }}>
            퀵메뉴
          </HomePretendardText>
          <QuickMenuWrapper>
            {quickMenu.map((item, index) => {
              return (
                <QuickMenu key={index}>
                  <QuickMenuCaption>{item.category}</QuickMenuCaption>
                  <QuickMenuTitle onClick={() => navigate(`${item.route}`)}>
                    {item.title}
                  </QuickMenuTitle>
                </QuickMenu>
              );
            })}
          </QuickMenuWrapper>
        </HomeWrapper>
      </MainLayout>
    </>
  );
}

export default Home;

const HomeWrapper = styled.div.attrs((props) => {})`
  width: 1044px;
  height: auto;
  margin-top: 190px;
`;

const HomeHeader = styled.div.attrs((props) => {})`
  width: 1044px;
  height: 90px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 40px;
`;

const TotalMemberCountWrapper = styled.div.attrs((props) => {})`
  padding: 14px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
  background: #f2f4f5;
  border-radius: 6px;
`;

const HomePretendardText = styled(PretendardText).attrs((props) => {})`
  color: #262c31;
  font-size: 16px;
  font-weight: 700;
  line-height: 28px; /* 175% */
  letter-spacing: -0.048px;
`;

const QuickMenuWrapper = styled.div.attrs((props) => {})`
  width: 1044px;
  display: flex;
  align-items: center;
  gap: 10px 21px;
  flex-wrap: wrap;
  margin-bottom: 103px;
`;

const QuickMenu = styled.div.attrs((props) => {})`
  width: 334px;
  height: 113px;
  border-radius: 8px;
  border: 1px solid var(--gray-2, #d7dbe0);
  padding: 27px 32px;
  cursor: pointer;
`;

const QuickMenuCaption = styled(PretendardText).attrs((props) => {})`
  color: #27beff;
  font-size: 14px;
  font-weight: 600;
  line-height: 22.75px; /* 163.333% */
  margin-bottom: 4px;
`;

const QuickMenuTitle = styled(PretendardText).attrs((props) => {})`
  color: #333;
  font-size: 18px;
  font-weight: 700;
  line-height: 25px; /* 170% */
  letter-spacing: -0.054px;
  cursor: pointer;

  &::after {
    content: url("/assets/admin/icons/ico_quick_arrow.svg");
    margin-left: 6px;
    width: 20px;
    height: 20px;
    position: relative;
    top: 4px;
  }
`;

const TextDivider = styled.span`
  height: 10px;
  width: 1px;
  background-color: #b8bfc4;
`;
