import api from "./api.js";

export default class CalculateApi {
  static async getList(params) {
    return await api.get(`admin/calculates`, params);
  }

  static async getById(calculateId, params) {
    return await api.get(`admin/calculate/${calculateId}`, params);
  }
  static async delete(data) {
    return await api.del(`admin/calculates`, data);
  }
  static async updateCalculateStatus(calculateId, data) {
    return await api.post(`admin/calculate/status/${calculateId}`, data);
  }
}
