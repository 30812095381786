import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { isNull } from "../../../util/check";
import { toast } from "react-toastify";
import { toastMsg } from "../../../util/toastMsg";
import { errorHandler } from "../../../util/errorHandler";
import Header from "../../../component/organisms/header";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Sidebar from "../../../component/organisms/sidebar";
import SaveBtn from "../../../component/atoms/button/saveBtn";
import DetailPageLayout from "../../../component/atoms/layout/detail/detailPageLayout";
import DetailTop from "../../../component/atoms/layout/detail/detailTop";
import GoBack from "../../../component/molecules/goBack";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import DetailPageTitle from "../../../component/atoms/text/detail/detailPageTitle";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import MainInput from "../../../component/atoms/input/mainInput";
import Radio from "../../../component/atoms/input/radio";
import Modal from "../../../component/templates/Modal";
import { modalMsg } from "../../../util/modalMsg";
import MarketingApi from "../../../api/marketing.api";

function MarketingTypeCreate() {
  const navigate = useNavigate();

  const [marketingTypeInfo, setMarketingTypeInfo] = useState({});

  //저장하기
  const [saveModalOpen, setSaveModalOpen] = useState(false);

  const saveBtnHandler = () => {
    if (isNull(marketingTypeInfo.title)) {
      return toast("필수사항을 모두 입력해주세요.");
    }

    setSaveModalOpen(true);
  };

  const saveFunc = async () => {
    try {
      const data = {
        title: marketingTypeInfo.title,
        activation: marketingTypeInfo.activation,
      };

      await MarketingApi.create(data);
      toast(toastMsg.create);
      setSaveModalOpen(false);
      navigate(-1);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
          </DetailTop>
          <DetailPage style={{ minHeight: "375px" }}>
            <DetailPageTitle>추가하기</DetailPageTitle>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "20px" }}
            >
              <DetailPageForm>
                <DetailFormTitle>마케팅명*</DetailFormTitle>
                <MainInput
                  style={{
                    width: "338px",
                  }}
                  type={"text"}
                  placeholder={"마케팅명을 입력해주세요"}
                  value={marketingTypeInfo.title}
                  onChange={(e) =>
                    setMarketingTypeInfo({
                      ...marketingTypeInfo,
                      title: e.target.value,
                    })
                  }
                />
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>게시 상태*</DetailFormTitle>
                <div style={{ display: "flex", gap: "20px" }}>
                  <Radio
                    id={""}
                    valueText={"활성"}
                    checked={marketingTypeInfo.activation}
                    onChange={() => {
                      setMarketingTypeInfo({
                        ...marketingTypeInfo,
                        activation: true,
                      });
                    }}
                  />
                  <Radio
                    id={""}
                    valueText={"비활성"}
                    checked={!marketingTypeInfo.activation}
                    onChange={() => {
                      setMarketingTypeInfo({
                        ...marketingTypeInfo,
                        activation: false,
                      });
                    }}
                  />
                </div>
              </DetailPageForm>
            </div>
            <SaveBtn
              text="등록하기"
              style={{ bottom: "40px", right: "40px" }}
              onClick={() => {
                saveBtnHandler();
              }}
            />
          </DetailPage>
        </DetailPageLayout>
      </MainLayout>
      {saveModalOpen ? (
        <Modal
          title={modalMsg.update?.title}
          text={modalMsg.update?.content}
          setModalOpen={setSaveModalOpen}
          onClick={() => {
            saveFunc();
          }}
        />
      ) : null}
    </>
  );
}

export default MarketingTypeCreate;
