import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../../component/organisms/header";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Sidebar from "../../../component/organisms/sidebar";
import DetailPageLayout from "../../../component/atoms/layout/detail/detailPageLayout";
import DetailTop from "../../../component/atoms/layout/detail/detailTop";
import DetailPageTitle from "../../../component/atoms/text/detail/detailPageTitle";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import DetailFormText from "../../../component/atoms/text/detail/detailFormText";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import EditBtn from "./../../../component/atoms/button/editBtn";
import { route } from "../../../router/route";
import TableTitle from "./../../../component/atoms/text/table/tableTitle";
import BasicApi from "../../../api/basic.api";
import { errorHandler } from "../../../util/errorHandler";
import {
  formatBusinessNumber,
  formatTelNumber,
  formatReportNumber,
} from "./../../../util/formatNumber";

const GeneralBasic = () => {
  const navigate = useNavigate();

  // mounted
  useEffect(() => {
    getBasicInfo();
  }, []);

  const [basicInfo, setBasicInfo] = useState({});

  const getBasicInfo = async () => {
    try {
      let responseData = (await BasicApi.getBasicInfo()).data.data.content;
      setBasicInfo(responseData);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <TableTitle>기본 관리</TableTitle>
            <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
              <EditBtn
                onClick={() => {
                  navigate(route.general_basic_edit);
                }}
              />
            </div>
          </DetailTop>

          <DetailPage style={{ minHeight: "295px" }}>
            <DetailPageTitle>기본정보</DetailPageTitle>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "20px" }}
            >
              <DetailPageForm>
                <DetailFormTitle>상호명</DetailFormTitle>
                <DetailFormText>{basicInfo.companyName}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>대표자</DetailFormTitle>
                <DetailFormText>{basicInfo.ceo}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>사업자등록번호</DetailFormTitle>
                <DetailFormText>
                  {formatBusinessNumber(basicInfo.companyNumber)}
                </DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>통신판매업신고번호</DetailFormTitle>
                <DetailFormText>
                  {formatReportNumber(basicInfo.reportNumber)}
                </DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>주소</DetailFormTitle>
                <DetailFormText>{basicInfo.address}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>전화번호</DetailFormTitle>
                <DetailFormText>
                  {formatTelNumber(basicInfo.tel)}
                </DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>이메일</DetailFormTitle>
                <DetailFormText>{basicInfo.email}</DetailFormText>
              </DetailPageForm>
            </div>
          </DetailPage>
        </DetailPageLayout>
      </MainLayout>
    </>
  );
};

export default GeneralBasic;
