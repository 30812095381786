import api from "./api.js";

export default class PopupApi {
  static async create(data) {
    return await api.post(`admin/popup`, data);
  }
  static async getList(params) {
    return await api.get(`admin/popups`, params);
  }
  static async getById(popupId, params) {
    return await api.get(`admin/popup/${popupId}`, params);
  }
  static async update(popupId, data) {
    return await api.patch(`admin/popup/${popupId}`, data);
  }
  static async delete(data) {
    return await api.del(`admin/popups`, data);
  }
  static async updateActivation(popupId, data) {
    return await api.post(`admin/popup/activation/${popupId}`, data);
  }
}
