import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import PretendardText from "../atoms/text/pretendardText";
import { fileDownload } from "../../util/fileManagement";

function FileImgPreview({ fileUrl, fileName, serverFileName }) {
  const navigate = useNavigate();
  return (
    <>
      <FileImgPreviewWrapper>
        {fileUrl || fileName ? (
          <>
            <img
              style={{ width: "90px", height: "90px", borderRadius: "3px" }}
              src={fileUrl}
              alt=""
            />
            <FileDownloadBtn
              onClick={async () => {
                await fileDownload(serverFileName, fileName);
              }}
            >
              <img src="/assets/admin/icons/ico_file_download.svg" alt="" />
              <PretendardText
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  letterSpacing: "-0.048px",
                  color: "#636c73",
                }}
              >
                {fileName}
              </PretendardText>
            </FileDownloadBtn>
          </>
        ) : (
          ""
        )}
      </FileImgPreviewWrapper>
    </>
  );
}

export default FileImgPreview;

const FileImgPreviewWrapper = styled.div.attrs((props) => {})`
  display: flex;
  align-items: flex-end;
  gap: 12px;
`;

const FileDownloadBtn = styled.button.attrs((props) => {})`
  display: flex;
  padding: 10px 20px;
  align-items: center;
  gap: 4px;
  border-radius: 23px;
  border: 1px solid #808991;
  background: #fff;
`;
