import api from "./api.js";

export default class TermsApi {
  static async create(data) {
    return await api.post(`admin/terms`, data);
  }
  static async getList(params) {
    return await api.get(`admin/terms`, params);
  }
  static async getById(termsId, params) {
    return await api.get(`admin/terms/${termsId}`, params);
  }
  static async update(termsId, data) {
    return await api.patch(`admin/terms/${termsId}`, data);
  }
  static async delete(idList) {
    return await api.del(`admin/terms/${idList}`);
  }
}
