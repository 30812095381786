import axios from "axios";
import { route } from "../router/route";

export const url = "https://api.alting.co.kr";
export const fileUrl = "https://media.alting.co.kr";
const baseURL = `${url}/`;

export const _axios = axios.create({
  baseURL,
  withCredentials: true,
});

let alertFlag = true;

_axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const { response, config } = error;

    // 액세스 토큰 만료
    if (response.status === 403) {
      try {
        const response = await api.post(`auth/token/refresh/manager`);
        const newToken = response.data.data.accessToken;
        // new 액세스 토큰 변수 저장
        sessionStorage.setItem("token", newToken);

        // new 액세스 토큰 헤더 설정
        config.headers["authorization"] = `Bearer ${newToken}`;
        return await _axios(config);
      } catch (error) {
        // window.location.href = route.login + "?refresh=true";
        // window.location.href = route.login + "?refresh=true";
        // if (alertFlag) {
        //   alert("로그인이 만료되었습니다. 다시 로그인해주세요.");
        //   alertFlag = false;
        // }
      }
    }
    // 리프레쉬 토큰 만료
    else if (response.status === 401) {
      sessionStorage.clear();
      window.location.href = route.login + "?refresh=true";

      if (alertFlag) {
        alert("로그인이 만료되었습니다. 다시 로그인해주세요.");
        alertFlag = false;
      }
    }
    return Promise.reject(error);
  }
);

export default class api {
  static async getAuthorizeHeader() {
    const token = sessionStorage.getItem("token");

    return token ? { authorization: `Bearer ${token}` } : {};
  }

  static async send({ method, url, data, params, headers }) {
    try {
      const res = await _axios.request({
        url,
        method,
        params,
        data,
        headers: {
          "Access-Control-Allow-Origin": "*",
          ...(await api.getAuthorizeHeader()),
          ...headers,
        },
      });

      return { data: res.data, status: res.status };
    } catch (ex) {
      throw ex;
    }
  }

  static async get(url, params, headers) {
    return await api.send({ method: "GET", url, params, headers });
  }

  static async post(url, data, headers) {
    return await api.send({ method: "POST", url, data, headers });
  }

  static async put(url, data, headers) {
    return await api.send({ method: "PUT", url, data, headers });
  }

  static async patch(url, data, headers) {
    return await api.send({ method: "PATCH", url, data, headers });
  }

  static async del(url, data, headers) {
    return await api.send({ method: "DELETE", url, data, headers });
  }

  static async fileForm(url, file, method, params) {
    let formData = new FormData();
    formData.append("file", file);

    return await api.send({
      url,
      method: method || "PUT",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
      params: params,
    });
  }
}
