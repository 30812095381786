import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "./../../component/organisms/header";
import MainLayout from "./../../component/atoms/layout/mainLayout";
import Sidebar from "./../../component/organisms/sidebar";
import { route } from "../../router/route";
import DetailPageLayout from "../../component/atoms/layout/detail/detailPageLayout";
import DetailTop from "../../component/atoms/layout/detail/detailTop";
import GoBack from "../../component/molecules/goBack";
import DelBtn from "../../component/atoms/button/delBtn";
import EditBtn from "../../component/atoms/button/editBtn";
import DetailPage from "./../../component/atoms/layout/detail/detailPage";
import DetailPageTitle from "./../../component/atoms/text/detail/detailPageTitle";
import DetailPageForm from "./../../component/atoms/layout/detail/detailPageForm";
import DetailFormText from "./../../component/atoms/text/detail/detailFormText";
import DetailFormTitle from "./../../component/atoms/text/detail/detailFormTitle";
import FileImgPreview from "./../../component/molecules/fileImgPreview";
import CurationApi from "../../api/curation.api";
import { errorHandler } from "../../util/errorHandler";
import { toast } from "react-toastify";
import { toastMsg } from "../../util/toastMsg";
import Modal from "../../component/templates/Modal";
import { modalMsg } from "../../util/modalMsg";
import { fileUrl } from "./../../api/api";
import moment from "moment";

const CurationPostDetail = () => {
  const navigate = useNavigate();
  let { id } = useParams();

  // mounted
  useEffect(() => {
    getCurationInfo();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // ----- 정보 가져오기
  const [curationInfo, setCurationInfo] = useState({
    title: "제목제목제목제목제목제목제목제목제목제목제목제목제목제목",
    endDate: "YYYY/MM/DD",
    sectorTitle: "",
    regionTitle: "",
    age: "",
    content: "",
    link: "",
    activation: "",
    fileUrl: "",
    imageOriginFileName: "",
    imageServerFileName: "",
  });

  const getCurationInfo = async () => {
    try {
      const response = (await CurationApi.getById(id)).data.data.content;
      setCurationInfo({
        ...response,
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  // ----- 삭제
  const [delModalOpen, setDelModalOpen] = useState(false);

  const deleteFunc = async () => {
    try {
      const idData = {
        ids: [id],
      };
      await CurationApi.delete(idData);
      toast(toastMsg.delete);
      setDelModalOpen(false);
      navigate(-1);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
            <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
              <DelBtn
                onClick={() => {
                  setDelModalOpen(true);
                }}
              />
              <EditBtn
                onClick={() => {
                  navigate(route.curation_post_edit + `/${id}`);
                }}
              />
            </div>
          </DetailTop>
          <DetailPage style={{ minHeight: "495px" }}>
            <DetailPageTitle>기본정보</DetailPageTitle>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "20px" }}
            >
              {/* 제목 */}
              <DetailPageForm>
                <DetailFormTitle>제목</DetailFormTitle>
                <DetailFormText>{curationInfo.title}</DetailFormText>
              </DetailPageForm>

              {/* 이미지 */}
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>이미지</DetailFormTitle>
                <FileImgPreview
                  fileUrl={`${fileUrl}/${curationInfo.imageServerFileName}`}
                  fileName={curationInfo.imageOriginFileName}
                  serverFileName={curationInfo.imageServerFileName}
                />
              </DetailPageForm>

              {/* 모집마감 */}
              <DetailPageForm>
                <DetailFormTitle>모집마감</DetailFormTitle>
                <DetailFormText>
                  {moment(curationInfo.endDate).format("YYYY/MM/DD")}
                </DetailFormText>
              </DetailPageForm>

              {/* 업종 */}
              <DetailPageForm>
                <DetailFormTitle>업종</DetailFormTitle>
                <DetailFormText>{curationInfo.sectorTitle}</DetailFormText>
              </DetailPageForm>

              {/* 지역 */}
              <DetailPageForm>
                <DetailFormTitle>지역</DetailFormTitle>
                <DetailFormText>{curationInfo.regionTitle}</DetailFormText>
              </DetailPageForm>

              {/* 나이 */}
              <DetailPageForm>
                <DetailFormTitle>나이</DetailFormTitle>
                <DetailFormText>{curationInfo.ageTitle}</DetailFormText>
              </DetailPageForm>

              {/* 내용 */}
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>내용</DetailFormTitle>
                <DetailFormText>{curationInfo.content}</DetailFormText>
              </DetailPageForm>

              {/* 링크 */}
              <DetailPageForm>
                <DetailFormTitle>링크</DetailFormTitle>
                <DetailFormText>{curationInfo.link}</DetailFormText>
              </DetailPageForm>
              {/* 게시 상태 */}
              <DetailPageForm>
                <DetailFormTitle>게시 상태</DetailFormTitle>
                <DetailFormText>
                  {curationInfo.activation ? "활성" : "비활성"}
                </DetailFormText>
              </DetailPageForm>
            </div>
          </DetailPage>
        </DetailPageLayout>
      </MainLayout>

      {delModalOpen ? (
        <Modal
          title={modalMsg.delete?.title}
          text={modalMsg.delete?.content}
          setModalOpen={setDelModalOpen}
          onClick={() => {
            deleteFunc();
          }}
        />
      ) : null}
    </>
  );
};

export default CurationPostDetail;
