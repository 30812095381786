import React from "react";
import styled from "styled-components";

function Checkbox({ id, checked, onChange, disabled, style }) {
  return (
    <>
      <CheckboxWrapper
        id={id}
        type="checkbox"
        disabled={disabled}
        checked={checked}
        onChange={onChange}
        style={{
          ...style,
        }}
      />
    </>
  );
}

export default Checkbox;

const CheckboxWrapper = styled.input.attrs((props) => {})`
  appearance: none;
  padding: 0;
  width: 20px;
  height: 20px;
  border-radius: 2px;
  border: 1px solid #b8bfc4;
  outline: none;
  background: #fff;

  &:checked {
    background: url("/assets/admin/icons/ico_checkbox.svg") center center
      no-repeat;
    border: none;
  }
`;
