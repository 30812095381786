import React, { useState } from "react";
import styled from "styled-components";
import TableTitle from "../../component/atoms/text/table/tableTitle";
import DetailPageForm from "./../../component/atoms/layout/detail/detailPageForm";
import DetailFormTitle from "./../../component/atoms/text/detail/detailFormTitle";
import DetailFormText from "./../../component/atoms/text/detail/detailFormText";
import SaveBtn from "./../../component/atoms/button/saveBtn";
import MainInput from "./../../component/atoms/input/mainInput";
import { toast } from "react-toastify";
import { toastMsg } from "../../util/toastMsg";
import Modal from "../../component/templates/Modal";
import { modalMsg } from "../../util/modalMsg";
import TextArea from "./../../component/atoms/input/textarea";
import { isNull } from "../../util/check";
import ServiceFaqApi from "../../api/serviceFaq.api";
import { errorHandler } from "../../util/errorHandler";
import moment from "moment";

function FaqModal({ data, setModalOpen }) {
  const [faqData, setFaqData] = useState(data);
  const [editFaqData, setEditFaqData] = useState(faqData);

  const [isShowEdit, setIsShowEdit] = useState(false);

  const [saveModalOpen, setSaveModalOpen] = useState(false);

  const saveBtnHandler = () => {
    if (isNull(editFaqData.title || editFaqData.content)) {
      return toast("필수사항을 모두 입력해주세요.");
    }

    setSaveModalOpen(true);
  };

  const saveFunc = async () => {
    try {
      const data = {
        title: editFaqData.title,
        content: editFaqData.content,
      };
      await ServiceFaqApi.update(faqData.id, data);
      setFaqData(editFaqData);
      setSaveModalOpen(false);
      setIsShowEdit(false);
      toast(toastMsg.update);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <ModalWrapper>
        <ModalBox>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "30px",
            }}
          >
            <TableTitle style={{ marginBottom: "0px" }}>
              자주 묻는 질문 상세
            </TableTitle>
            <img
              style={{
                cursor: "pointer",
              }}
              src="/assets/admin/icons/ico_popup_close.svg"
              alt=""
              onClick={() => setModalOpen(false)}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              minHeight: "223px",
              overflowY: "auto",
            }}
          >
            {!isShowEdit && (
              <>
                <DetailPageForm>
                  <DetailFormTitle>제목</DetailFormTitle>
                  <DetailFormText>{faqData.title}</DetailFormText>
                </DetailPageForm>
                <DetailPageForm>
                  <DetailFormTitle>작성일</DetailFormTitle>
                  <DetailFormText>
                    {moment.utc(faqData.createAt).format("YYYY/MM/DD")}
                  </DetailFormText>
                </DetailPageForm>
                <DetailPageForm>
                  <DetailFormTitle>내용</DetailFormTitle>
                  <DetailFormText
                    style={{
                      maxHeight: "400px",
                    }}
                  >
                    {faqData.content}
                  </DetailFormText>
                </DetailPageForm>
              </>
            )}
            {isShowEdit && (
              <>
                {/* 제목 */}
                <DetailPageForm>
                  <DetailFormTitle>제목*</DetailFormTitle>
                  <MainInput
                    style={{
                      width: 338,
                    }}
                    type={"text"}
                    placeholder={"제목을 입력해주세요"}
                    value={editFaqData.title}
                    onChange={(e) =>
                      setEditFaqData({
                        ...editFaqData,
                        title: e.target.value,
                      })
                    }
                  />
                </DetailPageForm>

                {/* 내용 */}
                <DetailPageForm
                  style={{
                    alignItems: "flex-start",
                  }}
                >
                  <DetailFormTitle>내용*</DetailFormTitle>

                  <TextArea
                    type={"text"}
                    placeholder={"내용을 입력해주세요"}
                    value={editFaqData.content}
                    onChange={(e) => {
                      setEditFaqData({
                        ...editFaqData,
                        content: e.target.value,
                      });
                    }}
                    style={{
                      minHeight: "92px",
                    }}
                  />
                </DetailPageForm>
              </>
            )}
          </div>
          <SaveBtn
            text={isShowEdit ? "저장하기" : "수정하기"}
            style={{ bottom: "40px", right: "40px" }}
            disabled={editFaqData.title === "" || editFaqData.content === ""}
            onClick={() => {
              if (!isShowEdit) {
                setIsShowEdit(!isShowEdit);
              } else {
                saveBtnHandler();
              }
            }}
          />
        </ModalBox>
      </ModalWrapper>
      {saveModalOpen ? (
        <Modal
          title={modalMsg.update?.title}
          text={modalMsg.update?.content}
          setModalOpen={setSaveModalOpen}
          onClick={() => {
            saveFunc();
          }}
        />
      ) : null}
    </>
  );
}

export default FaqModal;

const ModalWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00000060;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 30;
`;

const ModalBox = styled.div.attrs((props) => {})`
  width: 1064px;
  min-height: 397px;
  padding: 50px;
  border: 1px solid #d7dbe0;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
  position: relative;
`;
