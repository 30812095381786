import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Header from "../../../component/organisms/header";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Sidebar from "../../../component/organisms/sidebar";
import DetailPageLayout from "../../../component/atoms/layout/detail/detailPageLayout";
import { route } from "../../../router/route";
import TableTitle from "../../../component/atoms/text/table/tableTitle";
import TableTop from "../../../component/atoms/layout/table/tableTop";
import TableCount from "../../../component/molecules/tableCount";
import TableSearchBtn from "../../../component/atoms/layout/table/tableSearchBtn";
import SearchBar from "../../../component/atoms/input/searchBar";
import Table from "../../../component/atoms/layout/table/table";
import Tr from "../../../component/atoms/layout/table/tr";
import Th from "../../../component/atoms/layout/table/th";
import Checkbox from "../../../component/atoms/input/checkbox";
import TableText from "../../../component/atoms/text/table/tableText";
import Td from "../../../component/atoms/layout/table/td";
import DetailBtn from "../../../component/atoms/button/detailBtn";
import Pagination from "../../../component/molecules/pagination";
import DelBtn from "./../../../component/atoms/button/delBtn";
import AddBtn from "./../../../component/atoms/button/addBtn";
import NoticeApi from "../../../api/notice.api";
import { toast } from "react-toastify";
import { toastMsg } from "../../../util/toastMsg";
import { errorHandler } from "../../../util/errorHandler";
import Modal from "../../../component/templates/Modal";
import { modalMsg } from "../../../util/modalMsg";
import moment from "moment";

const GeneralNotice = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  // ----------------------------------- //
  // ---------- 마운트가 되면 ---------- //
  // --------------------------------- //
  useEffect(() => {
    setKeyword(searchParams.get("keyword") ? searchParams.get("keyword") : "");
    getNoticeList();
  }, [location]);

  // ----- 목록 가져오기
  const page = useMemo(() => {
    return searchParams.get("page") ? searchParams.get("page") : 1;
  }, [searchParams]);

  const [pagination, setPagination] = useState({
    size: 10, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈

    totalCount: 0,
    totalPages: 0,
    first: false,
    last: false,
  });

  const [noticeList, setNoticeList] = useState([]);
  const [noticeCount, setNoticeCount] = useState(0);
  const [keyword, setKeyword] = useState("");

  const getNoticeList = async () => {
    try {
      const data = {
        keyword: searchParams.get("keyword"),
        page: searchParams.get("page"),
        size: pagination.size,
      };
      let responseData = (await NoticeApi.getList(data)).data.data;
      setNoticeCount(responseData.totalCount);
      setNoticeList(
        responseData.content.map((item) => {
          return {
            ...item,
            checked: false,
          };
        })
      );
      setPagination({
        ...pagination,
        totalCount: responseData.totalCount ? responseData.totalCount : 0,
        totalPages: responseData.totalPage
          ? responseData.totalPage
          : Math.ceil(responseData.totalCount / 10),
        first: responseData.isFirst ? responseData.isFirst : false,
        last: responseData.isLast ? responseData.isLast : false,
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  // ----- 삭제
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [delIdList, setDelIdList] = useState([]);

  const isAllCheck = useMemo(() => {
    const checkedList = noticeList.filter((item) => item.checked);
    return checkedList.length !== 0 && checkedList.length === noticeList.length;
  }, [noticeList]);

  const deleteFunc = async () => {
    try {
      let data = {
        ids: delIdList,
      };
      await NoticeApi.delete(data);
      toast(toastMsg.delete);
      setDeleteModalOpen(false);
      setDelIdList([]);
      getNoticeList();
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <div style={{ marginTop: "40px" }}>
            <TableTitle>공지사항 관리</TableTitle>
            <TableTop>
              <TableCount text={"항목"} count={noticeCount} />
              <TableSearchBtn>
                <SearchBar
                  value={keyword}
                  onChange={(e) => {
                    setKeyword(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      navigate(
                        `${route.general_notice}?page=1&keyword=${keyword}`,
                        {
                          replace: true,
                        }
                      );
                    }
                  }}
                  onClick={() => {
                    navigate(
                      `${route.general_notice}?page=1&keyword=${keyword}`,
                      {
                        replace: true,
                      }
                    );
                  }}
                />

                <DelBtn
                  text={"선택항목 삭제"}
                  onClick={() => {
                    const delList = noticeList.filter((item) => item.checked);
                    if (delList.length <= 0) {
                      return;
                    }
                    setDelIdList(delList.map((item) => item.id));
                    setDeleteModalOpen(true);
                  }}
                />
                <AddBtn onClick={() => navigate(route.general_notice_create)} />
              </TableSearchBtn>
            </TableTop>
            <Table>
              <colgroup>
                {/* 체크박스 */}
                <col width="70px" />
                {/* 제목 */}
                <col width="523px" />
                {/* 관리자 이메일 */}
                <col width="209px" />
                {/* 작성일 */}
                <col width="144px" />
                {/* 상세보기 */}
                <col width="75px" />
              </colgroup>
              <thead>
                <Tr style={{ height: "41px", background: "#f2f4f5" }}>
                  <Th style={{ paddingLeft: "20px" }}>
                    <Checkbox
                      checked={isAllCheck}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setNoticeList(
                            noticeList.map((item) => {
                              return {
                                ...item,
                                checked: true,
                              };
                            })
                          );
                        } else {
                          setNoticeList(
                            noticeList.map((item) => {
                              return {
                                ...item,
                                checked: false,
                              };
                            })
                          );
                        }
                      }}
                    />
                  </Th>
                  <Th>
                    <TableText>제목</TableText>
                  </Th>
                  <Th>
                    <TableText>관리자 이메일</TableText>
                  </Th>
                  <Th>
                    <TableText>작성일</TableText>
                  </Th>
                  <Th>
                    <TableText>상세보기</TableText>
                  </Th>
                </Tr>
              </thead>
              <tbody>
                {noticeList.map((item, index) => {
                  return (
                    <Tr
                      key={index}
                      style={{ height: "59px", background: "#fff" }}
                    >
                      <Td style={{ paddingLeft: "20px" }}>
                        <Checkbox
                          checked={item.checked}
                          onChange={() => {
                            item.checked = !item.checked;
                            setNoticeList([...noticeList]);
                          }}
                        />
                      </Td>
                      <Td>
                        <TableText>{item.title}</TableText>
                      </Td>
                      <Td>
                        <TableText>{item.adminEmail}</TableText>
                      </Td>
                      <Td>
                        <TableText>
                          {moment.utc(item.createdAt).format("YYYY/MM/DD")}
                        </TableText>
                      </Td>
                      <Td>
                        <DetailBtn
                          onClick={() => {
                            navigate(
                              route.general_notice_detail + `/${item.id}`
                            );
                          }}
                        />
                      </Td>
                    </Tr>
                  );
                })}
              </tbody>
            </Table>
            <Pagination
              route={route.general_notice}
              queryString={`keyword=${searchParams.get("keyword") || ""}`}
              currentPage={page}
              totalPages={pagination.totalPages}
              rangeSize={pagination.rangeSize}
              first={pagination.first}
              last={pagination.last}
            />
          </div>
        </DetailPageLayout>
      </MainLayout>
      {deleteModalOpen ? (
        <Modal
          title={modalMsg.delete?.title}
          text={modalMsg.delete?.content}
          btnTitle={"삭제하기"}
          setModalOpen={setDeleteModalOpen}
          onClick={() => {
            deleteFunc();
          }}
        />
      ) : null}
    </>
  );
};

export default GeneralNotice;
