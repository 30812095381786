import React, { useEffect, useState } from "react";
import { errorHandler } from "../../../util/errorHandler";
import { isNull } from "../../../util/check";
import { toast } from "react-toastify";
import { toastMsg } from "../../../util/toastMsg";
import Header from "../../../component/organisms/header";
import MainLayout from "./../../../component/atoms/layout/mainLayout";
import Sidebar from "./../../../component/organisms/sidebar";
import DetailPageLayout from "./../../../component/atoms/layout/detail/detailPageLayout";
import DetailTop from "./../../../component/atoms/layout/detail/detailTop";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import DetailPageTitle from "./../../../component/atoms/text/detail/detailPageTitle";
import DetailPageForm from "./../../../component/atoms/layout/detail/detailPageForm";
import DetailFormTitle from "./../../../component/atoms/text/detail/detailFormTitle";
import MainInput from "./../../../component/atoms/input/mainInput";
import SaveBtn from "./../../../component/atoms/button/saveBtn";
import Modal from "../../../component/templates/Modal";
import { modalMsg } from "../../../util/modalMsg";
import styled from "styled-components";
import DetailFormText from "./../../../component/atoms/text/detail/detailFormText";
import Checkbox from "./../../../component/atoms/input/checkbox";
import PretendardText from "./../../../component/atoms/text/pretendardText";
import TableTitle from "./../../../component/atoms/text/table/tableTitle";
import PaymentApi from "../../../api/payment.api";

const PaymentMethod = () => {
  // ---------------------------------------------- //
  // ----------------- 마운트 시 ----------------- //
  // -------------------------------------------- //
  useEffect(() => {
    getPaymentMethodInfo();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // ---------------------------------------------------- //
  // ----------------- 데이터 가져오기 ----------------- //
  // -------------------------------------------------- //
  const [paymentMethodInfo, setPaymentMethodInfo] = useState({});

  const getPaymentMethodInfo = async () => {
    try {
      const response = (await PaymentApi.getMethod()).data.data.content;
      if (!response.authCancelFlag) {
        setPaymentMethodInfo({
          ...response,
          autoCancelHour: "",
        });
      } else {
        setPaymentMethodInfo(response);
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  // --------------------------------------------- //
  // ----------------- 체크박스 ----------------- //
  // ------------------------------------------- //
  const [paymentMethodCheckboxValue, setPaymentMethodCheckboxValue] = useState({
    bank: true,
  });

  const handlePaymentMethodCheckedChange = (e) => {
    const { id, checked } = e.target;
    setPaymentMethodCheckboxValue({
      ...paymentMethodCheckboxValue,
      [id]: checked,
    });
  };

  // --------------------------------------------- //
  // ----------------- 저장하기 ----------------- //
  // ------------------------------------------- //
  const [saveModalOpen, setSaveModalOpen] = useState(false);

  const saveBtnHandler = () => {
    if (
      isNull(paymentMethodInfo.bankName) ||
      isNull(paymentMethodInfo.accountNumber) ||
      isNull(paymentMethodInfo.accountName) ||
      paymentMethodCheckboxValue.bank === false
    ) {
      return toast("필수사항을 모두 입력해주세요.");
    }

    setSaveModalOpen(true);
  };

  // 숫자만 남기기
  const removeNonNumeric = (data) => {
    return data.replace(/[^0-9]/g, "");
  };

  const saveFunc = async () => {
    if (
      paymentMethodInfo.authCancelFlag &&
      paymentMethodInfo.autoCancelHour < 1
    ) {
      toast("1시간 이상 입력해주세요.");
      setSaveModalOpen(false);
      return;
    }

    try {
      let data = {
        bankName: paymentMethodInfo.bankName,
        accountNumber: removeNonNumeric(paymentMethodInfo.accountNumber),
        accountName: paymentMethodInfo.accountName,
        authCancelFlag: paymentMethodInfo.authCancelFlag,
      };

      if (paymentMethodInfo.authCancelFlag) {
        data = {
          ...data,
          autoCancelHour: paymentMethodInfo.autoCancelHour,
        };
      }

      await PaymentApi.updateMethod(data);
      setSaveModalOpen(false);
      getPaymentMethodInfo();
      toast(toastMsg.update);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <TableTitle>결제 수단 관리</TableTitle>
          </DetailTop>
          <DetailPage style={{ minHeight: "705px" }}>
            <DetailPageTitle>결제 수단</DetailPageTitle>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "20px" }}
            >
              {/* 결제수단 */}
              <DetailPageForm>
                <DetailFormTitle>결제수단*</DetailFormTitle>
                <CheckboxWrapper>
                  <Checkbox
                    id="bank"
                    checked={paymentMethodCheckboxValue.bank}
                    onChange={handlePaymentMethodCheckedChange}
                  />
                  <DetailFormText>무통장 입금</DetailFormText>
                </CheckboxWrapper>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>은행명*</DetailFormTitle>
                <MainInput
                  style={{
                    width: 338,
                  }}
                  type={"text"}
                  placeholder={"은행명을 입력해주세요"}
                  value={paymentMethodInfo.bankName}
                  onChange={(e) =>
                    setPaymentMethodInfo({
                      ...paymentMethodInfo,
                      bankName: e.target.value,
                    })
                  }
                />
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>계좌번호*</DetailFormTitle>
                <MainInput
                  style={{
                    width: 338,
                  }}
                  type={"text"}
                  placeholder={"계좌번호를 입력해주세요"}
                  value={paymentMethodInfo.accountNumber}
                  onChange={(e) =>
                    setPaymentMethodInfo({
                      ...paymentMethodInfo,
                      accountNumber: e.target.value,
                    })
                  }
                />
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>예금주명*</DetailFormTitle>
                <MainInput
                  style={{
                    width: 338,
                  }}
                  type={"text"}
                  placeholder={"예금주명을 입력해주세요"}
                  value={paymentMethodInfo.accountName}
                  onChange={(e) =>
                    setPaymentMethodInfo({
                      ...paymentMethodInfo,
                      accountName: e.target.value,
                    })
                  }
                />
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>자동 취소</DetailFormTitle>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 7,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: 3,
                      alignItems: "center",
                    }}
                  >
                    <MainInput
                      style={{
                        width: 140,
                      }}
                      type={"number"}
                      placeholder={"시간"}
                      value={paymentMethodInfo.autoCancelHour}
                      onChange={(e) =>
                        setPaymentMethodInfo({
                          ...paymentMethodInfo,
                          autoCancelHour: e.target.value,
                        })
                      }
                    />
                    <PretendardText
                      style={{
                        fontWeight: 400,
                        fontSize: 15,
                        lineHeight: "21.25px",
                        color: "#5a636a",
                      }}
                    >
                      시간 이내 미입금 시 자동 취소
                    </PretendardText>
                  </div>
                  <CheckboxWrapper>
                    <Checkbox
                      id="authCancelFlag"
                      checked={paymentMethodInfo.authCancelFlag}
                      onChange={() =>
                        setPaymentMethodInfo({
                          ...paymentMethodInfo,
                          authCancelFlag: !paymentMethodInfo.authCancelFlag,
                        })
                      }
                    />
                    <DetailFormText>미입금 시 자동 취소 설정</DetailFormText>
                  </CheckboxWrapper>
                </div>
              </DetailPageForm>
            </div>
            <SaveBtn
              style={{ bottom: "40px", right: "40px" }}
              onClick={() => {
                saveBtnHandler();
              }}
            />
          </DetailPage>
        </DetailPageLayout>
      </MainLayout>

      {saveModalOpen ? (
        <Modal
          title={modalMsg.update?.title}
          text={modalMsg.update?.content}
          setModalOpen={setSaveModalOpen}
          onClick={() => {
            saveFunc();
          }}
        />
      ) : null}
    </>
  );
};

export default PaymentMethod;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;
