import React, { useEffect, useMemo, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import Header from "./../../../component/organisms/header";
import { errorHandler } from "../../../util/errorHandler";
import { toastMsg } from "../../../util/toastMsg";
import { toast } from "react-toastify";
import MainLayout from "./../../../component/atoms/layout/mainLayout";
import Sidebar from "./../../../component/organisms/sidebar";
import TableLayout from "./../../../component/atoms/layout/table/tableLayout";
import TableTop from "./../../../component/atoms/layout/table/tableTop";
import TableCount from "./../../../component/molecules/tableCount";
import TableSearchBtn from "./../../../component/atoms/layout/table/tableSearchBtn";
import SearchBar from "./../../../component/atoms/input/searchBar";
import { route } from "./../../../router/route";
import DelBtn from "./../../../component/atoms/button/delBtn";
import Table from "../../../component/atoms/layout/table/table";
import Tr from "./../../../component/atoms/layout/table/tr";
import Th from "./../../../component/atoms/layout/table/th";
import Checkbox from "./../../../component/atoms/input/checkbox";
import TableText from "./../../../component/atoms/text/table/tableText";
import Td from "./../../../component/atoms/layout/table/td";
import moment from "moment";
import TableDelBtn from "./../../../component/atoms/button/tableDelBtn";
import DetailBtn from "./../../../component/atoms/button/detailBtn";
import Pagination from "./../../../component/molecules/pagination";
import Modal from "../../../component/templates/Modal";
import { modalMsg } from "./../../../util/modalMsg";
import TableTab from "./../../../component/organisms/tableTab";
import ReviewModal from "./../reviewModal";
import ReviewApi from "../../../api/review.api";
import TableFilter from "../../../component/atoms/layout/table/tableFilter";

const CompanyServiceManagementReview = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams(); // eslint-disable-line no-unused-vars
  let { id } = useParams();

  // ----------------------------------- //
  // ---------- 마운트가 되면 ---------- //
  // --------------------------------- //
  useEffect(() => {
    setKeyword(searchParams.get("keyword") ? searchParams.get("keyword") : "");
    getReviewList();
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  const page = useMemo(() => {
    return searchParams.get("page") ? searchParams.get("page") : 1;
  }, [searchParams]);

  const [pagination, setPagination] = useState({
    size: 10, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈

    totalCount: 0,
    totalPages: 0,
    first: false,
    last: false,
  });

  const [reviewList, setReviewList] = useState([]);
  const [reviewCount, setReviewCount] = useState(0);
  const [keyword, setKeyword] = useState("");

  const getReviewList = async () => {
    try {
      const data = {
        keyword: searchParams.get("keyword"),
        page: searchParams.get("page"),
        size: pagination.size,
      };
      let responseData = (await ReviewApi.getListById(id, data)).data.data;
      setReviewCount(responseData.totalCount);
      setReviewList(
        responseData.content.map((item) => {
          return {
            ...item,
            activation: item.activation,
            checked: false,
          };
        })
      );
      setPagination({
        ...pagination,
        totalCount: responseData.totalCount ? responseData.totalCount : 0,
        totalPages: responseData.totalPage
          ? responseData.totalPage
          : Math.ceil(responseData.totalCount / 10),
        first: responseData.isFirst ? responseData.isFirst : false,
        last: responseData.isLast ? responseData.isLast : false,
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  // ----- 활성/비활성
  const [filterOpen, setFilterOpen] = useState(-1);
  const [activationId, setActivationId] = useState(null);
  const [activation, setActivation] = useState(null);
  const [activationModalTitle, setActivationModalTitle] = useState("");
  const [activationModalOpen, setActivationModalOpen] = useState(false);

  const activationFunc = async () => {
    try {
      const data = {
        activation: activation,
      };
      await ReviewApi.updateActivation(activationId, data);
      toast(toastMsg.create);
      setFilterOpen(-1);
      setActivationModalOpen(false);
      getReviewList();
    } catch (error) {
      errorHandler(error);
    }
  };

  // ----- 삭제
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [delIdList, setDelIdList] = useState([]);

  const isAllCheck = useMemo(() => {
    const checkedList = reviewList.filter((item) => item.checked);
    return checkedList.length !== 0 && checkedList.length === reviewList.length;
  }, [reviewList]);

  const deleteFunc = async () => {
    try {
      let data = {
        ids: delIdList,
      };
      await ReviewApi.delete(data);
      toast(toastMsg.delete);
      setDeleteModalOpen(false);
      setDelIdList([]);
      getReviewList();
    } catch (error) {
      errorHandler(error);
    }
  };

  const [subTitle, setSubTitle] = useState("항목");

  const [selectedData, setSelectedData] = useState({});
  const [detailModalOpen, setDetailModalOpen] = useState(false);

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <TableLayout>
          <TableTab
            leftTitle="서비스 정보"
            rightTitle="리뷰 관리"
            route={route.service_company_service_detail}
            anotherRoute={route.service_company_service_review}
            id={id}
            isExpert={false}
          />
          <TableTop>
            <TableCount
              text={subTitle}
              count={reviewCount ? reviewCount : reviewList.length}
            />
            <TableSearchBtn>
              <SearchBar
                value={keyword}
                onChange={(e) => {
                  setKeyword(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    navigate(
                      `${route.service_company_service_review}/${id}?page=1&keyword=${keyword}`,
                      {
                        replace: true,
                      }
                    );
                    setSubTitle("검색 결과");
                  }
                }}
                onClick={() => {
                  navigate(
                    `${route.service_company_service_review}/${id}?page=1&keyword=${keyword}`,
                    {
                      replace: true,
                    }
                  );
                  setSubTitle("검색 결과");
                }}
              />
              <DelBtn
                text="선택항목 삭제"
                onClick={() => {
                  const delList = reviewList.filter((item) => item.checked);
                  if (delList.length <= 0) {
                    return;
                  }
                  setDelIdList(delList.map((item) => item.id));
                  setDeleteModalOpen(true);
                }}
              />
            </TableSearchBtn>
          </TableTop>
          <Table>
            <colgroup>
              {/* 체크박스 영역 */}
              <col width="90px" />
              {/* 이메일 */}
              <col width="187px" />
              {/* 내용 */}
              <col width="308px" />
              {/* 별점 */}
              <col width="67px" />
              {/* 작성일 */}
              <col width="127px" />
              {/* 게시 상태 */}
              <col width="102px" />
              {/* 삭제하기 */}
              <col width="86px" />
              {/* 상세보기 */}
              <col width="74px" />
            </colgroup>
            <thead>
              <Tr style={{ height: "40px", background: "#f2f4f5" }}>
                <Th style={{ paddingLeft: "20px" }}>
                  <Checkbox
                    checked={isAllCheck}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setReviewList(
                          reviewList.map((item) => {
                            return {
                              ...item,
                              checked: true,
                            };
                          })
                        );
                      } else {
                        setReviewList(
                          reviewList.map((item) => {
                            return {
                              ...item,
                              checked: false,
                            };
                          })
                        );
                      }
                    }}
                  />
                </Th>
                <Th>
                  <TableText>이메일</TableText>
                </Th>
                <Th>
                  <TableText>내용</TableText>
                </Th>
                <Th>
                  <TableText>별점</TableText>
                </Th>
                <Th>
                  <TableText>작성일</TableText>
                </Th>
                <Th>
                  <TableText>게시 상태</TableText>
                </Th>
                <Th>
                  <TableText>삭제하기</TableText>
                </Th>
                <Th>
                  <TableText>상세보기</TableText>
                </Th>
              </Tr>
            </thead>
            <tbody>
              {reviewList.map((item, index) => {
                return (
                  <Tr
                    key={index}
                    style={{ height: "59px", background: "#fff" }}
                  >
                    <Td style={{ paddingLeft: "20px" }}>
                      <Checkbox
                        checked={item.checked}
                        onChange={() => {
                          item.checked = !item.checked;
                          setReviewList([...reviewList]);
                        }}
                      />
                    </Td>
                    <Td>
                      <TableText>{item.member.email}</TableText>
                    </Td>
                    <Td>
                      <TableText>{item.content}</TableText>
                    </Td>
                    <Td>
                      <TableText>{Math.floor(item.rating)}</TableText>
                    </Td>
                    <Td>
                      <TableText>
                        {moment.utc(item.createAt).format("YYYY/MM/DD")}
                      </TableText>
                    </Td>
                    <Td>
                      <TableFilter
                        style={{
                          width: "fit-content",
                          gap: 10,
                        }}
                        onClick={() => {
                          filterOpen === index
                            ? setFilterOpen(-1)
                            : setFilterOpen(index);
                        }}
                        isOpen={filterOpen === index}
                        selectedValue={item.activation}
                        list={[
                          {
                            value: true,
                            title: "활성",
                            onClick: () => {
                              setActivationId(item.id);
                              setActivation(true);
                              setActivationModalTitle("활성화");
                              setActivationModalOpen(true);
                            },
                          },
                          {
                            value: false,
                            title: "비활성",
                            onClick: () => {
                              setActivationId(item.id);
                              setActivation(false);
                              setActivationModalTitle("비활성화");
                              setActivationModalOpen(true);
                            },
                          },
                        ]}
                      />
                    </Td>
                    <Td>
                      <TableDelBtn
                        style={{ marginLeft: "6px" }}
                        onClick={() => {
                          item.checked = !item.checked;
                          setReviewList([...reviewList]);
                          const delList = [item];
                          setDelIdList(delList.map((item) => item.id));
                          setDeleteModalOpen(true);
                        }}
                      />
                    </Td>
                    <Td>
                      <DetailBtn
                        onClick={() => {
                          setSelectedData(item);
                          setDetailModalOpen(true);
                        }}
                      />
                    </Td>
                  </Tr>
                );
              })}
            </tbody>
          </Table>
          <Pagination
            route={`${route.service_company_service_review}/${id}`}
            queryString={`keyword=${searchParams.get("keyword")}` || ""}
            currentPage={page}
            totalPages={pagination.totalPages ? pagination.totalPages : 0}
            rangeSize={pagination.rangeSize}
            first={pagination.first}
            last={pagination.last}
          />
        </TableLayout>
      </MainLayout>
      {activationModalOpen ? (
        <Modal
          title={`${activationModalTitle} 하시겠습니까?`}
          setModalOpen={setActivationModalOpen}
          onClick={() => {
            activationFunc();
          }}
        />
      ) : null}

      {deleteModalOpen ? (
        <Modal
          title={modalMsg.delete?.title}
          text={modalMsg.delete?.content}
          btnTitle={"삭제하기"}
          setModalOpen={setDeleteModalOpen}
          onClick={() => {
            deleteFunc();
          }}
        />
      ) : null}

      {detailModalOpen ? (
        <ReviewModal data={selectedData} setModalOpen={setDetailModalOpen} />
      ) : (
        ""
      )}
    </>
  );
};

export default CompanyServiceManagementReview;
