import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { isNull } from "../../../util/check";
import { toast } from "react-toastify";
import { toastMsg } from "../../../util/toastMsg";
import { errorHandler } from "../../../util/errorHandler";
import Header from "../../../component/organisms/header";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Sidebar from "../../../component/organisms/sidebar";
import SaveBtn from "../../../component/atoms/button/saveBtn";
import DetailPageLayout from "../../../component/atoms/layout/detail/detailPageLayout";
import DetailTop from "../../../component/atoms/layout/detail/detailTop";
import GoBack from "../../../component/molecules/goBack";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import DetailPageTitle from "../../../component/atoms/text/detail/detailPageTitle";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import MainInput from "../../../component/atoms/input/mainInput";
import Modal from "../../../component/templates/Modal";
import { modalMsg } from "../../../util/modalMsg";
import DetailFormText from "./../../../component/atoms/text/detail/detailFormText";
import moment from "moment";
import TextArea from "./../../../component/atoms/input/textarea";
import FaqApi from "../../../api/faq.api";

function GeneralFaqEdit() {
  const navigate = useNavigate();
  let { id } = useParams();

  // mounted
  useEffect(() => {
    getFaqInfo();
  }, []);

  // ----- 정보 가져오기
  const [faqInfo, setFaqInfo] = useState({
    title: "제목제목제목제목제목제목제목제목",
    adminEmail: "",
    createdAt: "",
    id: "",
    content: "",
  });

  const getFaqInfo = async () => {
    try {
      const response = (await FaqApi.getById(id)).data.data.content;
      setFaqInfo(response);
    } catch (error) {
      errorHandler(error);
    }
  };

  //저장하기
  const [saveModalOpen, setSaveModalOpen] = useState(false);

  const saveBtnHandler = () => {
    if (isNull(faqInfo.title) || isNull(faqInfo.content)) {
      return toast("필수사항을 모두 입력해주세요.");
    }

    setSaveModalOpen(true);
  };

  const saveFunc = async () => {
    try {
      const data = {
        title: faqInfo.title,
        content: faqInfo.content,
      };

      await FaqApi.update(id, data);
      setSaveModalOpen(false);
      navigate(-1);
      toast(toastMsg.update);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
          </DetailTop>
          <DetailPage style={{ minHeight: "462px" }}>
            <DetailPageTitle>수정하기</DetailPageTitle>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "20px" }}
            >
              <DetailPageForm>
                <DetailFormTitle>제목*</DetailFormTitle>
                <MainInput
                  style={{
                    width: "338px",
                  }}
                  type={"text"}
                  placeholder={"제목을 입력해주세요"}
                  value={faqInfo.title}
                  onChange={(e) =>
                    setFaqInfo({ ...faqInfo, title: e.target.value })
                  }
                />
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>관리자 이메일</DetailFormTitle>
                <DetailFormText>이메일이메일이메일이메일</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>게시일</DetailFormTitle>
                <DetailFormText>
                  {moment.utc().format("YYYY/MM/DD")}
                </DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>내용*</DetailFormTitle>
                <TextArea
                  type={"text"}
                  placeholder={"내용을 입력해주세요"}
                  value={faqInfo.content}
                  onChange={(e) =>
                    setFaqInfo({ ...faqInfo, content: e.target.value })
                  }
                  style={{
                    minHeight: "92px",
                  }}
                />
              </DetailPageForm>
            </div>
            <SaveBtn
              text="등록하기"
              style={{ bottom: "40px", right: "40px" }}
              onClick={() => {
                saveBtnHandler();
              }}
            />
          </DetailPage>
        </DetailPageLayout>
      </MainLayout>

      {saveModalOpen ? (
        <Modal
          title={modalMsg.update?.title}
          text={modalMsg.update?.content}
          setModalOpen={setSaveModalOpen}
          onClick={() => {
            saveFunc();
          }}
        />
      ) : null}
    </>
  );
}

export default GeneralFaqEdit;
