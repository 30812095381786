import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Header from "../../../component/organisms/header";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Sidebar from "../../../component/organisms/sidebar";
import DetailPageLayout from "../../../component/atoms/layout/detail/detailPageLayout";
import { route } from "../../../router/route";
import TableTitle from "../../../component/atoms/text/table/tableTitle";
import TableTop from "../../../component/atoms/layout/table/tableTop";
import TableCount from "../../../component/molecules/tableCount";
import TableSearchBtn from "../../../component/atoms/layout/table/tableSearchBtn";
import SearchBar from "../../../component/atoms/input/searchBar";
import Table from "../../../component/atoms/layout/table/table";
import Tr from "../../../component/atoms/layout/table/tr";
import Th from "../../../component/atoms/layout/table/th";
import Checkbox from "../../../component/atoms/input/checkbox";
import TableText from "../../../component/atoms/text/table/tableText";
import Td from "../../../component/atoms/layout/table/td";
import TableFilter from "../../../component/atoms/layout/table/tableFilter";
import DetailBtn from "../../../component/atoms/button/detailBtn";
import Pagination from "../../../component/molecules/pagination";
import ExcelBtn from "../../../component/atoms/button/excelBtn";
import PaymentApi from "../../../api/payment.api";
import { errorHandler } from "../../../util/errorHandler";
import { toast } from "react-toastify";
import { toastMsg } from "../../../util/toastMsg";
import { formatNumberWithCommas } from "./../../../util/formatNumber";
import moment from "moment";
import Modal from "../../../component/templates/Modal";
import { modalMsg } from "./../../../util/modalMsg";
import { saveAs } from "file-saver";
import ExcelApi from "../../../api/excel.api";

const PaymentHistory = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams(); // eslint-disable-line no-unused-vars

  // ------------------------------------ //
  // ---------- 마운트가 되면 ---------- //
  // ---------------------------------- //

  useEffect(() => {
    setKeyword(searchParams.get("keyword") ? searchParams.get("keyword") : "");
    getPaymentHistoryList();
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  // ----- 목록 가져오기
  const page = useMemo(() => {
    return searchParams.get("page") ? searchParams.get("page") : 1;
  }, [searchParams]);

  const [pagination, setPagination] = useState({
    size: 10, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈
    totalCount: 0,
    totalPages: 0,
    first: false,
    last: false,
  });

  const [paymentHistoryList, setPaymentHistoryList] = useState([]);
  const [paymentHistoryCount, setPaymentHistoryCount] = useState(0);
  const [keyword, setKeyword] = useState("");

  const getPaymentHistoryList = async () => {
    try {
      const data = {
        keyword: searchParams.get("keyword"),
        page: searchParams.get("page"),
        size: pagination.size,
      };
      let responseData = (await PaymentApi.getList(data)).data.data;
      console.log(responseData.content);
      setPaymentHistoryCount(responseData.totalCount);
      setPaymentHistoryList(
        responseData.content.map((item) => {
          return {
            ...item,
            checked: false,
          };
        })
      );
      setPagination({
        ...pagination,
        totalCount: responseData.totalCount ? responseData.totalCount : 0,
        totalPages: responseData.totalPage
          ? responseData.totalPage
          : Math.ceil(responseData.totalCount / 10),
        first: responseData.isFirst ? responseData.isFirst : false,
        last: responseData.isLast ? responseData.isLast : false,
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  // -------------------------------- //
  // ---------- 구매 확정 ---------- //
  // ------------------------------ //
  const [completeFilterOpen, setCompleteFilterOpen] = useState(-1);
  const [completeId, setCompleteId] = useState(null);
  const [complete, setComplete] = useState(null);
  const [completeModalTitle, setCompleteModalTitle] = useState("");
  const [completeModalOpen, setCompleteModalOpen] = useState(false);
  const [completeFailModalOpen, setCompleteFailModalOpen] = useState(false);
  const [completeFailModalTitle, setCompleteFailModalTitle] = useState("");
  const [completeFailModalText, setCompleteFailModalText] = useState("");

  const completeFunc = async () => {
    try {
      const data = {
        completeStatus: complete,
      };
      await PaymentApi.updateComplete(completeId, data);
      toast(toastMsg.create);
      setCompleteFilterOpen(-1);
      setCompleteModalOpen(false);
      getPaymentHistoryList();
    } catch (error) {
      errorHandler(error);
    }
  };

  // -------------------------------- //
  // ---------- 환불요청 ---------- //
  // ------------------------------ //
  const [refundFilterOpen, setRefundFilterOpen] = useState(-1);
  const [refundId, setRefundId] = useState(null);
  const [refund, setRefund] = useState(null);
  const [refundModalTitle, setRefundModalTitle] = useState("");
  const [refundModalOpen, setRefundModalOpen] = useState(false);
  const [refundFailModalOpen, setRefundFailModalOpen] = useState(false);

  const refundFunc = async () => {
    try {
      const data = {
        refundStatus: refund,
      };
      await PaymentApi.updateRefund(refundId, data);
      toast(toastMsg.create);
      setRefundFilterOpen(-1);
      setRefundModalOpen(false);
      getPaymentHistoryList();
    } catch (error) {
      errorHandler(error);
    }
  };

  // -------------------------------- //
  // ---------- 체크 박스 ---------- //
  // ------------------------------ //

  const isAllCheck = useMemo(() => {
    const checkedList = paymentHistoryList.filter((item) => item.checked);
    return (
      checkedList.length !== 0 &&
      checkedList.length === paymentHistoryList.length
    );
  }, [paymentHistoryList]);

  //------------엑셀 생성
  const downloadExcel = async (excelData) => {
    const Excel = require("exceljs");
    const workbook = new Excel.Workbook();
    workbook.addWorksheet("Sheet One");
    const sheetOne = workbook.getWorksheet("Sheet One");

    sheetOne.columns = [
      { header: "이메일", key: "email", width: 40 },
      { header: "상품명", key: "merchTitle", width: 40 },
      { header: "결제금액", key: "price", width: 40 },
      { header: "결제일", key: "createdAt", width: 40 },
      { header: "구매 확정", key: "completeStatusTitle", width: 40 },
      { header: "환불요청", key: "refundStatusTitle", width: 40 },
    ];

    excelData.map((item) => {
      // 행 추가
      sheetOne.addRow(item);
    });
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), "결제 내역 관리.xlsx");
  };

  const getExcelAll = async () => {
    try {
      const excelArr = [];
      const response = (
        await ExcelApi.GetPaymentExcel({
          ids: [],
          allFlag: true,
        })
      ).data.data.content;

      response.map((item) => {
        const createDate = moment(item.createdAt).format("YYYY/MM/DD");
        excelArr.unshift({
          ...item,
          createdAt: createDate,
          completeStatus:
            item.completeStatus === "COMPLETE"
              ? "확정완료"
              : item.completeStatus === "PROGRESS"
              ? "확정이전"
              : item.completeStatus === "CANCEL"
              ? "결제취소"
              : "입금대기",
        });
      });

      downloadExcel(excelArr);
    } catch (error) {
      errorHandler(error);
    }
  };

  const getExcelChecked = async () => {
    const filter = paymentHistoryList.filter((item) => item.checked);
    if (filter.length <= 0) {
      return toast("항목을 선택해주세요");
    }

    try {
      const excelArr = [];
      const response = (
        await ExcelApi.GetPaymentExcel({
          ids: filter.map((item) => item.id),
          allFlag: false,
        })
      ).data.data.content;

      response.map((item) => {
        const createDate = moment(item.createdAt).format("YYYY/MM/DD");
        excelArr.unshift({
          ...item,
          createdAt: createDate,
          completeStatus:
            item.completeStatus === "COMPLETE"
              ? "확정완료"
              : item.completeStatus === "PROGRESS"
              ? "확정이전"
              : item.completeStatus === "CANCEL"
              ? "결제취소"
              : "입금대기",
        });
      });

      downloadExcel(excelArr);
    } catch (error) {
      errorHandler(error);
    }
  };
  //------------액셀 부분 끝

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <div style={{ marginTop: "40px" }}>
            <TableTitle>결제 내역 관리</TableTitle>
            <TableTop>
              <TableCount
                text={"항목"}
                count={
                  paymentHistoryCount
                    ? paymentHistoryCount
                    : paymentHistoryList.length
                }
              />
              <TableSearchBtn>
                <SearchBar
                  value={keyword}
                  onChange={(e) => {
                    setKeyword(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      navigate(
                        `${route.payment_history}?page=1&keyword=${keyword}`,
                        {
                          replace: true,
                        }
                      );
                    }
                  }}
                  onClick={() => {
                    navigate(
                      `${route.payment_history}?page=1&keyword=${keyword}`,
                      {
                        replace: true,
                      }
                    );
                  }}
                />
                <ExcelBtn
                  onSelectClick={getExcelChecked}
                  onAllClick={getExcelAll}
                />
              </TableSearchBtn>
            </TableTop>
            <Table>
              <colgroup>
                {/* 체크박스 */}
                <col width="70px" />
                {/* 이메일 */}
                <col width="175px" />
                {/* 상품명 */}
                <col width="210px" />
                {/* 결제금액 */}
                <col width="100px" />
                {/* 결제수단 */}
                <col width="81px" />
                {/* 결제일 */}
                <col width="121px" />
                {/* 구매 확정 */}
                <col width="102px" />
                {/* 환불요청 */}
                <col width="111px" />
                {/* 상세보기 */}
                <col width="75px" />
              </colgroup>
              <thead>
                <Tr style={{ height: "41px", background: "#f2f4f5" }}>
                  <Th style={{ paddingLeft: "20px" }}>
                    <Checkbox
                      checked={isAllCheck}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setPaymentHistoryList(
                            paymentHistoryList.map((item) => {
                              return {
                                ...item,
                                checked: true,
                              };
                            })
                          );
                        } else {
                          setPaymentHistoryList(
                            paymentHistoryList.map((item) => {
                              return {
                                ...item,
                                checked: false,
                              };
                            })
                          );
                        }
                      }}
                    />
                  </Th>
                  <Th>
                    <TableText>이메일</TableText>
                  </Th>
                  <Th>
                    <TableText>상품명</TableText>
                  </Th>
                  <Th>
                    <TableText>결제금액</TableText>
                  </Th>
                  <Th>
                    <TableText>결제수단</TableText>
                  </Th>
                  <Th>
                    <TableText>결제일</TableText>
                  </Th>
                  <Th>
                    <TableText>구매 확정</TableText>
                  </Th>
                  <Th>
                    <TableText>환불요청</TableText>
                  </Th>
                  <Th>
                    <TableText>상세보기</TableText>
                  </Th>
                </Tr>
              </thead>
              <tbody>
                {paymentHistoryList.map((item, index) => {
                  return (
                    <Tr
                      key={index}
                      style={{ height: "59px", background: "#fff" }}
                    >
                      <Td style={{ paddingLeft: "20px" }}>
                        <Checkbox
                          checked={item.checked}
                          onChange={() => {
                            item.checked = !item.checked;
                            setPaymentHistoryList([...paymentHistoryList]);
                          }}
                        />
                      </Td>
                      <Td>
                        <TableText
                          style={{
                            width: 145,
                          }}
                        >
                          {item.email}
                        </TableText>
                      </Td>
                      <Td>
                        <TableText
                          style={{
                            width: 168,
                          }}
                        >
                          {item.merchTitle}
                        </TableText>
                      </Td>

                      <Td>
                        <TableText
                          style={{
                            width: 55,
                          }}
                        >
                          {formatNumberWithCommas(item.price)}
                        </TableText>
                      </Td>
                      <Td>
                        <TableText
                          style={{
                            width: 49,
                          }}
                        >
                          {item.payMethodTitle}
                        </TableText>
                      </Td>
                      <Td>
                        <TableText>
                          {moment.utc(item.createdAt).format("YYYY/MM/DD")}
                        </TableText>
                      </Td>
                      <Td>
                        <TableFilter
                          style={{
                            width: "90px",
                          }}
                          subStyle={{
                            width: "120px",
                          }}
                          onClick={() => {
                            completeFilterOpen === index
                              ? setCompleteFilterOpen(-1)
                              : setCompleteFilterOpen(index);
                          }}
                          isOpen={completeFilterOpen === index}
                          selectedValue={item.completeStatus}
                          list={[
                            {
                              value: "COMPLETE",
                              title: "확정완료",
                              onClick: () => {
                                setCompleteId(item.id);
                                setComplete("COMPLETE");
                                setCompleteModalTitle("확정완료");
                                setCompleteModalOpen(true);
                              },
                            },
                            {
                              value: "PROGRESS",
                              title: "확정이전",
                              onClick: () => {
                                if (item.completeStatus === "COMPLETE") {
                                  setCompleteFailModalOpen(true);
                                  setCompleteFailModalTitle(
                                    "확정이전으로 변경이 불가합니다."
                                  );
                                  setCompleteFailModalText(
                                    <>
                                      구매확정 시 확정이전으로 변경이
                                      불가합니다.
                                      <br />
                                      구매 상태를 확인해주세요.
                                    </>
                                  );
                                  return;
                                }
                                setCompleteId(item.id);
                                setComplete("PROGRESS");
                                setCompleteModalTitle("확정이전");
                                setCompleteModalOpen(true);
                              },
                            },
                            {
                              value: "AWAIT",
                              title: "입금대기",
                              onClick: () => {
                                if (item.completeStatus === "COMPLETE") {
                                  setCompleteFailModalOpen(true);
                                  setCompleteFailModalTitle(
                                    "입금대기로 변경이 불가합니다."
                                  );
                                  setCompleteFailModalText(
                                    <>
                                      구매확정 시 입금대기로 변경이 불가합니다.
                                      <br />
                                      구매 상태를 확인해주세요.
                                    </>
                                  );
                                  return;
                                }
                                setCompleteId(item.id);
                                setComplete("AWAIT");
                                setCompleteModalTitle("입금대기");
                                setCompleteModalOpen(true);
                              },
                            },
                            {
                              value: "CANCEL",
                              title: "결제취소",
                              onClick: () => {
                                if (item.completeStatus === "COMPLETE") {
                                  setCompleteFailModalOpen(true);
                                  setCompleteFailModalTitle(
                                    "결제취소로 변경이 불가합니다."
                                  );
                                  setCompleteFailModalText(
                                    <>
                                      구매확정 시 결제취소로 변경이 불가합니다.
                                      <br />
                                      구매 상태를 확인해주세요.
                                    </>
                                  );
                                  return;
                                }
                                setCompleteId(item.id);
                                setComplete("CANCEL");
                                setCompleteModalTitle("결제취소");
                                setCompleteModalOpen(true);
                              },
                            },
                          ]}
                        />
                      </Td>
                      <Td>
                        <TableFilter
                          style={{
                            width: "90px",
                          }}
                          subStyle={{
                            width: "120px",
                          }}
                          onClick={() => {
                            refundFilterOpen === index
                              ? setRefundFilterOpen(-1)
                              : setRefundFilterOpen(index);
                          }}
                          isOpen={refundFilterOpen === index}
                          selectedValue={item.refundStatus}
                          list={[
                            {
                              value: "REQUEST",
                              title: "환불요청",
                              onClick: () => {
                                if (item.completeStatus !== "PROGRESS") {
                                  setRefundFailModalOpen(true);
                                } else {
                                  setRefundId(item.id);
                                  setRefund("REQUEST");
                                  setRefundModalTitle("환불요청");
                                  setRefundModalOpen(true);
                                }
                              },
                            },
                            {
                              value: "NONE",
                              title: "해당없음",
                              onClick: () => {
                                setRefundId(item.id);
                                setRefund("NONE");
                                setRefundModalTitle("해당없음");
                                setRefundModalOpen(true);
                              },
                            },
                          ]}
                        />
                      </Td>
                      <Td>
                        <DetailBtn
                          onClick={() => {
                            navigate(
                              route.payment_history_detail + `/${item.id}`
                            );
                          }}
                        />
                      </Td>
                    </Tr>
                  );
                })}
              </tbody>
            </Table>
            <Pagination
              route={route.payment_history}
              queryString={`keyword=${searchParams.get("keyword") || ""}`}
              currentPage={page}
              totalPages={pagination.totalPages}
              rangeSize={pagination.rangeSize}
              first={pagination.first}
              last={pagination.last}
            />
          </div>
        </DetailPageLayout>
      </MainLayout>
      {completeModalOpen ? (
        <Modal
          title={`${completeModalTitle} 하시겠습니까?`}
          text={complete === "COMPLETE" && modalMsg.complete?.content}
          setModalOpen={setCompleteModalOpen}
          onClick={() => {
            completeFunc();
          }}
        />
      ) : null}
      {completeFailModalOpen ? (
        <Modal
          title={completeFailModalTitle}
          text={completeFailModalText}
          setModalOpen={setCompleteFailModalOpen}
          onClick={() => {
            setCompleteFailModalOpen(false);
            setCompleteFilterOpen(false);
          }}
        />
      ) : null}
      {refundModalOpen ? (
        <Modal
          title={`${refundModalTitle} 하시겠습니까?`}
          setModalOpen={setRefundModalOpen}
          onClick={() => {
            refundFunc();
          }}
        />
      ) : null}
      {refundFailModalOpen ? (
        <Modal
          title={modalMsg.refundFail?.title}
          text={modalMsg.refundFail?.content}
          setModalOpen={setRefundFailModalOpen}
          onClick={() => {
            setRefundFailModalOpen(false);
            setRefundFilterOpen(false);
          }}
        />
      ) : null}
    </>
  );
};

export default PaymentHistory;
