import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PopupApi from "../../../api/popup.api";
import { errorHandler } from "../../../util/errorHandler";
import Header from "../../../component/organisms/header";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Sidebar from "../../../component/organisms/sidebar";
import DetailPageLayout from "../../../component/atoms/layout/detail/detailPageLayout";
import DetailTop from "../../../component/atoms/layout/detail/detailTop";
import GoBack from "../../../component/molecules/goBack";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import DetailPageTitle from "../../../component/atoms/text/detail/detailPageTitle";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import ImgPreview from "../../../component/molecules/imgPreview";
import ImgInput from "../../../component/atoms/input/imgInput";
import MainInput from "../../../component/atoms/input/mainInput";
import DateInput from "../../../component/atoms/input/dateInput";
import SaveBtn from "../../../component/atoms/button/saveBtn";
import { isNull } from "../../../util/check";
import { toast } from "react-toastify";
import { toastMsg } from "../../../util/toastMsg";
import moment from "moment";
import Modal from "../../../component/templates/Modal";
import { modalMsg } from "../../../util/modalMsg";
import { fileUrl } from "./../../../api/api";

const GeneralPopupEdit = () => {
  const navigate = useNavigate();
  let { id } = useParams();

  // mounted
  useEffect(() => {
    getPopupInfo();
  }, []);

  // ----- 정보 가져오기
  const [popupInfo, setPopupInfo] = useState({
    title: "제목제목제목제목제목제목제목제목",
    startDate: "",
    endDate: "",
    fileUrl: "",
    imageOriginFileName: "",
    imageServerFileName: "",
    imageDeleteFlag: false,
  });

  const getPopupInfo = async () => {
    try {
      const response = (await PopupApi.getById(id)).data.data.content;
      setPopupInfo({
        ...response,
        startDate: moment(response.startDate).format("YYYY-MM-DD"),
        endDate: moment(response.endDate).format("YYYY-MM-DD"),
        imageDeleteFlag: false,
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  //이미지 추가, 삭제
  const inputFile = (e) => {
    const file = e.target.files[0];

    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = (value) => {
      setPopupInfo({
        ...popupInfo,
        file: file,
        fileUrl: value.target.result,
        imageDeleteFlag: true,
      });
    };
    e.target.value = "";
  };

  const deleteFile = () => {
    setPopupInfo({
      ...popupInfo,
      file: "",
      fileUrl: "",
      imageServerFileName: "",
      imageDeleteFlag: true,
    });
  };

  //저장하기
  const [saveModalOpen, setSaveModalOpen] = useState(false);

  const saveBtnHandler = () => {
    if (
      isNull(popupInfo.title) ||
      isNull(popupInfo.startDate) ||
      isNull(popupInfo.endDate)
    ) {
      return toast("필수사항을 모두 입력해주세요.");
    }

    setSaveModalOpen(true);
  };

  const saveFunc = async () => {
    try {
      const formData = new FormData();
      formData.append("title", popupInfo.title);
      formData.append("startDate", popupInfo.startDate);
      formData.append("endDate", popupInfo.endDate);
      formData.append("imageDeleteFlag", popupInfo.imageDeleteFlag);
      if (popupInfo.file) {
        formData.append("image", popupInfo.file);
      }

      await PopupApi.update(id, formData);
      setSaveModalOpen(false);
      navigate(-1);
      toast(toastMsg.update);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
          </DetailTop>
          <DetailPage style={{ minHeight: "440px" }}>
            <DetailPageTitle>수정하기</DetailPageTitle>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "20px" }}
            >
              <DetailPageForm>
                <DetailFormTitle>팝업명*</DetailFormTitle>
                <MainInput
                  style={{
                    width: "338px",
                  }}
                  type={"text"}
                  placeholder={"팝업명 을(를) 입력해주세요"}
                  value={popupInfo.title}
                  onChange={(e) =>
                    setPopupInfo({ ...popupInfo, title: e.target.value })
                  }
                />
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>게시기간*</DetailFormTitle>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <DateInput
                    value={popupInfo.startDate}
                    onChange={(e) =>
                      setPopupInfo({ ...popupInfo, startDate: e.target.value })
                    }
                  />
                  <img src="/assets/admin/icons/ico_date_devider.svg" alt="" />
                  <DateInput
                    value={popupInfo.endDate}
                    onChange={(e) =>
                      setPopupInfo({ ...popupInfo, endDate: e.target.value })
                    }
                  />
                </div>
              </DetailPageForm>
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>이미지*</DetailFormTitle>
                {popupInfo.imageServerFileName || popupInfo.fileUrl ? (
                  <ImgPreview
                    fileUrl={
                      popupInfo.fileUrl
                        ? popupInfo.fileUrl
                        : `${fileUrl}/${popupInfo.imageServerFileName}`
                    }
                    onClick={() => {
                      deleteFile();
                    }}
                  />
                ) : (
                  <ImgInput
                    id={"file"}
                    accept={"image/*"}
                    onChange={(e) => {
                      inputFile(e);
                    }}
                  />
                )}
              </DetailPageForm>
            </div>
            <SaveBtn
              style={{ bottom: "40px", right: "40px" }}
              onClick={() => {
                saveBtnHandler();
              }}
            />
          </DetailPage>
        </DetailPageLayout>
      </MainLayout>

      {saveModalOpen ? (
        <Modal
          title={modalMsg.update?.title}
          text={modalMsg.update?.content}
          setModalOpen={setSaveModalOpen}
          onClick={() => {
            saveFunc();
          }}
        />
      ) : null}
    </>
  );
};

export default GeneralPopupEdit;
