import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "./../../../component/organisms/header";
import MainLayout from "./../../../component/atoms/layout/mainLayout";
import Sidebar from "./../../../component/organisms/sidebar";
import DetailPageLayout from "./../../../component/atoms/layout/detail/detailPageLayout";
import DetailTop from "./../../../component/atoms/layout/detail/detailTop";
import GoBack from "./../../../component/molecules/goBack";
import DelBtn from "./../../../component/atoms/button/delBtn";
import EditBtn from "./../../../component/atoms/button/editBtn";
import { route } from "./../../../router/route";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import DetailPageTitle from "./../../../component/atoms/text/detail/detailPageTitle";
import DetailPageForm from "./../../../component/atoms/layout/detail/detailPageForm";
import DetailFormText from "./../../../component/atoms/text/detail/detailFormText";
import DetailFormTitle from "./../../../component/atoms/text/detail/detailFormTitle";
import MarketingApi from "../../../api/marketing.api";
import { errorHandler } from "../../../util/errorHandler";
import { toast } from "react-toastify";
import { toastMsg } from "../../../util/toastMsg";
import Modal from "../../../component/templates/Modal";
import { modalMsg } from "../../../util/modalMsg";

const MarketingTypeDetail = () => {
  const navigate = useNavigate();
  let { id } = useParams();

  // mounted
  useEffect(() => {
    getMarketingTypeInfo();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // ----- 정보 가져오기
  const [marketingTypeInfo, setMarketingTypeInfo] = useState({});

  const getMarketingTypeInfo = async () => {
    try {
      const response = (await MarketingApi.getById(id)).data.data.content;
      setMarketingTypeInfo({
        ...response,
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  // ----- 삭제
  const [delModalOpen, setDelModalOpen] = useState(false);

  const deleteFunc = async () => {
    try {
      const idData = {
        ids: [id],
      };
      await MarketingApi.delete(idData);
      toast(toastMsg.delete);
      setDelModalOpen(false);
      navigate(-1);
    } catch (error) {
      if (error.response?.status !== 400) {
        errorHandler(error);
      } else {
        toast(
          <>
            연동된 서비스가 있는 경우
            <br />
            삭제가 불가능합니다.
          </>
        );
      }
      setDelModalOpen(false);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
            <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
              <DelBtn onClick={() => setDelModalOpen(true)} />
              <EditBtn
                onClick={() => {
                  navigate(route.service_marketing_edit + `/${id}`);
                }}
              />
            </div>
          </DetailTop>
          <DetailPage style={{ minHeight: "205px" }}>
            <DetailPageTitle>기본정보</DetailPageTitle>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "20px" }}
            >
              {/* 마케팅명 */}
              <DetailPageForm>
                <DetailFormTitle>마케팅명</DetailFormTitle>
                <DetailFormText>{marketingTypeInfo.title}</DetailFormText>
              </DetailPageForm>

              {/* 게시 상태 */}
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>게시 상태</DetailFormTitle>
                <DetailFormText>
                  {marketingTypeInfo.activation ? "활성" : "비활성"}
                </DetailFormText>
              </DetailPageForm>
            </div>
          </DetailPage>
        </DetailPageLayout>
      </MainLayout>
      {delModalOpen ? (
        <Modal
          title={modalMsg.delete?.title}
          text={modalMsg.delete?.content}
          setModalOpen={setDelModalOpen}
          onClick={() => {
            deleteFunc();
          }}
        />
      ) : null}
    </>
  );
};

export default MarketingTypeDetail;
