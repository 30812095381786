// 자주사용문구 - 한국어
export const modalMsg = {
  update: {
    title: "저장하시겠습니까?",
    content: (
      <>
        이전 데이터는 복구가 불가합니다.
        <br />
        저장을 진행하시겠습니까?
      </>
    ),
  },
  delete: {
    title: "삭제하시겠습니까?",
    content: (
      <>
        삭제된 데이터는 복구가 불가합니다.
        <br />
        그래도 삭제하시겠습니까?
      </>
    ),
  },
  create: { title: "등록하시겠습니까?" },
  approve: { title: "승인하시겠습니까?" },
  activate: { title: "활성화하시겠습니까?" },
  deactivate: { title: "비활성화하시겠습니까?" },
  deleteAllCompanyService: {
    title: "삭제하시겠습니까?",
    content: (
      <>
        <b style={{ color: "#ef594b" }}>모든 서비스</b>가 삭제됩니다.
        <br />
        삭제된 데이터는 복구가 불가합니다.
        <br />
        그래도 삭제하시겠습니까?
      </>
    ),
  },
  complete: {
    title: "구매확정 하시겠습니까?",
    content: (
      <>
        구매확정 시 자동으로 정산요청이 등록됩니다.
        <br />
        그래도 진행하시겠습니까?
      </>
    ),
  },
  refund: {
    title: "환불요청 하시겠습니까?",
  },
  refundFail: {
    title: "환불요청이 불가합니다.",
    content: (
      <>
        확정이전 단계는 환불 진행이 불가합니다.
        <br />
        구매 상태를 확인해 주세요.
      </>
    ),
  },
};
