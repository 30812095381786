import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { errorHandler } from "../../../util/errorHandler";
import PopupApi from "../../../api/popup.api";
import Header from "../../../component/organisms/header";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Sidebar from "../../../component/organisms/sidebar";
import DetailPageLayout from "../../../component/atoms/layout/detail/detailPageLayout";
import DetailTop from "../../../component/atoms/layout/detail/detailTop";
import GoBack from "../../../component/molecules/goBack";
import EditBtn from "../../../component/atoms/button/editBtn";
import { route } from "../../../router/route";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import DetailPageTitle from "../../../component/atoms/text/detail/detailPageTitle";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import DetailFormText from "../../../component/atoms/text/detail/detailFormText";
import moment from "moment";
import FileImgPreview from "../../../component/molecules/fileImgPreview";
import DelBtn from "../../../component/atoms/button/delBtn";
import Modal from "../../../component/templates/Modal";
import { modalMsg } from "../../../util/modalMsg";
import { toast } from "react-toastify";
import { toastMsg } from "../../../util/toastMsg";
import { fileUrl } from "./../../../api/api";

const GeneralPopupDetail = () => {
  const navigate = useNavigate();
  let { id } = useParams();

  // mounted
  useEffect(() => {
    getPopupInfo();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // ----- 정보 가져오기
  const [popupInfo, setPopupInfo] = useState({
    title: "제목제목제목제목제목제목제목제목제목제목제목제목제목제목",
    createdAt: "YYYY/MM/DD",
    startDate: "YYYY/MM/DD",
    endDate: "YYYY/MM/DD",
    fileUrl: "",
    imageOriginFileName: "",
    imageServerFileName: "",
  });

  const getPopupInfo = async () => {
    try {
      const response = (await PopupApi.getById(id)).data.data.content;
      setPopupInfo({
        ...response,
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  // ----- 삭제
  const [delModalOpen, setDelModalOpen] = useState(false);

  const deleteFunc = async () => {
    try {
      const idData = {
        ids: [id],
      };
      await PopupApi.delete(idData);
      toast(toastMsg.delete);
      setDelModalOpen(false);
      navigate(-1);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
            <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
              <DelBtn onClick={() => setDelModalOpen(true)} />
              <EditBtn
                onClick={() => {
                  navigate(route.general_popup_edit + `/${id}`);
                }}
              />
            </div>
          </DetailTop>
          <DetailPage style={{ minHeight: "360px" }}>
            <DetailPageTitle>상세보기</DetailPageTitle>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "20px" }}
            >
              <DetailPageForm>
                <DetailFormTitle>팝업명</DetailFormTitle>
                <DetailFormText>{popupInfo.title}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>게시일</DetailFormTitle>
                <DetailFormText>
                  {moment(popupInfo.startDate).format("YYYY/MM/DD")}
                </DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>게시기간</DetailFormTitle>
                <DetailFormText>
                  {moment(popupInfo.startDate).format("YYYY/MM/DD")} ~{" "}
                  {moment(popupInfo.endDate).format("YYYY/MM/DD")}
                </DetailFormText>
              </DetailPageForm>
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>이미지</DetailFormTitle>
                <FileImgPreview
                  fileUrl={`${fileUrl}/${popupInfo.imageServerFileName}`}
                  fileName={popupInfo.imageOriginFileName}
                  serverFileName={popupInfo.imageServerFileName}
                />
              </DetailPageForm>
            </div>
          </DetailPage>
        </DetailPageLayout>
      </MainLayout>

      {delModalOpen ? (
        <Modal
          title={modalMsg.delete?.title}
          text={modalMsg.delete?.content}
          setModalOpen={setDelModalOpen}
          onClick={() => {
            deleteFunc();
          }}
        />
      ) : null}
    </>
  );
};

export default GeneralPopupDetail;
