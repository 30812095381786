import api from "./api.js";

export default class CurationApi {
  static async create(data) {
    return await api.post(`admin/curation`, data);
  }
  static async getList(params) {
    return await api.get(`admin/curations`, params);
  }
  static async getById(curationId, params) {
    return await api.get(`admin/curation/${curationId}`, params);
  }
  static async update(curationId, data) {
    return await api.patch(`admin/curation/${curationId}`, data);
  }
  static async delete(data) {
    return await api.del(`admin/curations`, data);
  }
  static async updateActivation(curationId, data) {
    return await api.post(`admin/curation/activation/${curationId}`, data);
  }
}
