import api from "./api.js";

export default class ChatReportApi {
  static async getList(params) {
    return await api.get(`admin/reports`, params);
  }
  static async getById(chatReportId, params) {
    return await api.get(`admin/report/${chatReportId}`, params);
  }
  static async delete(chatReportId, params) {
    return await api.del(`admin/report/${chatReportId}`, params);
  }
}
