import api from "./api.js";

export default class ReviewApi {
  static async getListById(merchId, params) {
    return await api.get(`admin/reviews/${merchId}`, params); // 리뷰 목록 조회
  }
  static async getById(reviewId, params) {
    return await api.get(`admin/review/${reviewId}`, params); // 리뷰 단일 조회
  }
  static async update(marketingId, data) {
    return await api.patch(`admin/marketing-type/${marketingId}`, data);
  }
  static async delete(data) {
    return await api.del(`admin/reviews`, data);
  }
  static async updateActivation(reviewId, data) {
    return await api.post(`admin/review/activation/${reviewId}`, data);
  }
}
