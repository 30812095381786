import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../../component/organisms/header";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Sidebar from "../../../component/organisms/sidebar";
import DetailPageLayout from "../../../component/atoms/layout/detail/detailPageLayout";
import DetailTop from "../../../component/atoms/layout/detail/detailTop";
import GoBack from "../../../component/molecules/goBack";
import DelBtn from "../../../component/atoms/button/delBtn";
import DetailPageTitle from "../../../component/atoms/text/detail/detailPageTitle";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import DetailFormText from "../../../component/atoms/text/detail/detailFormText";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import moment from "moment";
import AlarmApi from "../../../api/alarm.api";
import { toast } from "react-toastify";
import { toastMsg } from "../../../util/toastMsg";
import { errorHandler } from "../../../util/errorHandler";
import Modal from "../../../component/templates/Modal";
import { modalMsg } from "../../../util/modalMsg";

const GeneralAlarmDetail = () => {
  const navigate = useNavigate();
  let { id } = useParams();

  // mounted
  useEffect(() => {
    getAlarmInfo();
  }, []);

  // ----- 정보 가져오기
  const [alarmInfo, setAlarmInfo] = useState({
    title: "",
    createdAt: "YYYY/MM/DD",
    adminEmail: "",
    content: "",
  });

  const getAlarmInfo = async () => {
    try {
      const response = (await AlarmApi.getById(id)).data.data.content;
      setAlarmInfo(response);
    } catch (error) {
      errorHandler(error);
    }
  };

  // ----- 삭제
  const [delModalOpen, setDelModalOpen] = useState(false);

  const deleteFunc = async () => {
    try {
      const idData = {
        ids: [id],
      };
      await AlarmApi.delete(idData);
      toast(toastMsg.delete);
      setDelModalOpen(false);
      navigate(-1);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
            <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
              <DelBtn onClick={() => setDelModalOpen(true)} />
            </div>
          </DetailTop>

          <DetailPage style={{ minHeight: "295px" }}>
            <DetailPageTitle>상세보기</DetailPageTitle>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "20px" }}
            >
              <DetailPageForm>
                <DetailFormTitle>제목</DetailFormTitle>
                <DetailFormText>{alarmInfo.title}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>게시일</DetailFormTitle>
                <DetailFormText>
                  {moment.utc(alarmInfo.createdAt).format("YYYY/MM/DD")}
                </DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>유형</DetailFormTitle>
                <DetailFormText>{alarmInfo.alarmType}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>내용</DetailFormTitle>
                <DetailFormText>{alarmInfo.content}</DetailFormText>
              </DetailPageForm>
            </div>
          </DetailPage>
        </DetailPageLayout>
      </MainLayout>

      {delModalOpen ? (
        <Modal
          title={modalMsg.delete?.title}
          text={modalMsg.delete?.content}
          setModalOpen={setDelModalOpen}
          onClick={() => {
            deleteFunc();
          }}
        />
      ) : null}
    </>
  );
};

export default GeneralAlarmDetail;
