import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import Header from "../../component/organisms/header";
import MainLayout from "../../component/atoms/layout/mainLayout";
import Sidebar from "../../component/organisms/sidebar";
import DetailTop from "../../component/atoms/layout/detail/detailTop";
import DetailPage from "../../component/atoms/layout/detail/detailPage";
import DetailPageTitle from "../../component/atoms/text/detail/detailPageTitle";
import DetailPageLayout from "../../component/atoms/layout/detail/detailPageLayout";
import GoBack from "../../component/molecules/goBack";
import DetailPageForm from "../../component/atoms/layout/detail/detailPageForm";
import DetailFormTitle from "../../component/atoms/text/detail/detailFormTitle";
import MainInput from "../../component/atoms/input/mainInput";
import SaveBtn from "../../component/atoms/button/saveBtn";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { toastMsg } from "../../util/toastMsg";
import Radio from "../../component/atoms/input/radio";
import DetailFormText from "../../component/atoms/text/detail/detailFormText";
import { errorHandler } from "../../util/errorHandler";
import { isNull } from "../../util/check";
import { route } from "../../router/route";
import { regExpression } from "../../util/regExpresstion";
import PretendardText from "../../component/atoms/text/pretendardText";
import Modal from "../../component/templates/Modal";
import { modalMsg } from "../../util/modalMsg";
import ManagerApi from "../../api/manager.api";
import AuthorityCheckbox from "./_component/authorityCheckbox";

function RootEdit(props) {
  // import
  const navigate = useNavigate();
  let { id } = useParams();

  // mounted
  useEffect(() => {
    getManagerInfo();
  }, []);

  // ----- 정보 가져오기
  const [managerInfo, setManagerInfo] = useState({
    name: "",
    email: "",
    password: "",
    passwordCheck: "",
    role: "",
    accessMenu: [],
    passwordChangeFlag: false,
  });

  const getManagerInfo = async () => {
    try {
      const response = await ManagerApi.getById(id);
      const data = response.data.data.content;
      setManagerInfo({
        ...data,
        accessMenu: data.accessMenu.split(","),
        passwordChangeFlag: false,
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  // ----- 비밀번호 변경
  const [pwEdit, setPwEdit] = useState(false);

  // 비밀번호 체크
  const pwErr = useMemo(() => {
    if (
      !isNull(managerInfo.password) &&
      !regExpression.password.test(managerInfo.password)
    ) {
      return true;
    }
    return false;
  }, [managerInfo.password]);

  // 비밀번호 확인 체크
  const pwCheckErr = useMemo(() => {
    if (
      !isNull(managerInfo.password) &&
      !isNull(managerInfo.passwordCheck) &&
      managerInfo.password !== managerInfo.passwordCheck
    ) {
      return true;
    }
    return false;
  }, [managerInfo.password, managerInfo.passwordCheck]);

  // 저장하기
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const saveFunc = async () => {
    try {
      if (isNull(managerInfo.name)) {
        setSaveModalOpen(false);
        return toast("이름을 입력해주세요.");
      }
      if (pwEdit) {
        if (isNull(managerInfo.password)) {
          setSaveModalOpen(false);
          return toast("비밀번호를 입력해주세요");
        }
        if (isNull(managerInfo.passwordCheck)) {
          setSaveModalOpen(false);
          return toast("비밀번호를 입력해주세요");
        }
        if (pwErr) {
          setSaveModalOpen(false);
          return toast("비밀번호를 올바르게 입력해주세요");
        }
        if (pwCheckErr) {
          setSaveModalOpen(false);
          return toast("비밀번호가 일치하지 않습니다");
        }
      }

      const requestDto = {
        email: managerInfo.email,
        name: managerInfo.name,
        role: managerInfo.role,
        accessMenu: String(managerInfo.accessMenu),
        passwordChangeFlag: pwEdit ? true : false,
        password: managerInfo.password,
      };

      await ManagerApi.update(id, requestDto);
      toast(toastMsg.update);
      navigate(-1);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
          </DetailTop>
          <DetailPage style={{ paddingBottom: "83px", minHeight: "762px" }}>
            <DetailPageTitle>수정하기</DetailPageTitle>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "20px" }}
            >
              <DetailPageForm>
                <DetailFormTitle>이름*</DetailFormTitle>
                <MainInput
                  style={{ width: "338px" }}
                  type={"text"}
                  placeholder={"이름을 입력해주세요"}
                  value={managerInfo.name}
                  onChange={(e) => {
                    setManagerInfo({ ...managerInfo, name: e.target.value });
                  }}
                />
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>이메일</DetailFormTitle>
                <DetailFormText>{managerInfo.email}</DetailFormText>
              </DetailPageForm>
              {pwEdit ? (
                <>
                  <DetailPageForm>
                    <DetailFormTitle>비밀번호*</DetailFormTitle>
                    <MainInput
                      style={{ width: "338px" }}
                      type={"password"}
                      placeholder={"새로운 비밀번호를 입력해 주세요."}
                      helperText={
                        "영문/숫자/특수문자 중 2가지 이상 포함, 8자 ~ 16자"
                      }
                      value={managerInfo.password}
                      onError={pwErr ? () => {} : undefined}
                      onChange={(e) => {
                        setManagerInfo({
                          ...managerInfo,
                          password: e.target.value,
                        });
                      }}
                    />
                  </DetailPageForm>
                  <DetailPageForm>
                    <DetailFormTitle>비밀번호 재입력*</DetailFormTitle>
                    <MainInput
                      style={{ width: "338px" }}
                      type={"password"}
                      placeholder={"새로운 비밀번호를 한 번 더 입력해 주세요."}
                      helperText={"비밀번호가 일치하지 않습니다"}
                      value={managerInfo.passwordCheck}
                      onError={pwCheckErr ? () => {} : undefined}
                      onChange={(e) => {
                        setManagerInfo({
                          ...managerInfo,
                          passwordCheck: e.target.value,
                        });
                      }}
                    />
                  </DetailPageForm>
                </>
              ) : (
                <DetailPageForm>
                  <DetailFormTitle>비밀번호</DetailFormTitle>
                  <PwEditBtn
                    onClick={() => {
                      setPwEdit(true);
                      setManagerInfo({
                        ...managerInfo,
                        passwordChangeFlag: true,
                      });
                    }}
                  >
                    비밀번호 변경
                  </PwEditBtn>
                </DetailPageForm>
              )}

              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>관리자 유형*</DetailFormTitle>
                <div style={{ display: "flex", gap: "19px" }}>
                  <Radio
                    id={"ROOTADMIN"}
                    valueText={"ROOTADMIN"}
                    name={"author"}
                    checked={managerInfo.role === "ROOTADMIN"}
                    onChange={() => {
                      setManagerInfo({
                        ...managerInfo,
                        role: "ROOTADMIN",
                      });
                    }}
                  />
                  <Radio
                    id={"ADMIN"}
                    valueText={"ADMIN"}
                    name={"author"}
                    checked={managerInfo.role === "ADMIN"}
                    onChange={() => {
                      setManagerInfo({
                        ...managerInfo,
                        role: "ADMIN",
                      });
                    }}
                  />
                </div>
              </DetailPageForm>
              <PretendardText
                style={{
                  color: "#FF4975",
                  fontSize: "16px",
                  fontWeight: "600",
                  lineHeight: "22.4px",
                  letterSpacing: "-0.408px",
                }}
              >
                *ROOT일 경우 아래 접근권한 설정에 관계없이 모든 메뉴 접근이
                가능합니다.
              </PretendardText>

              {/* ***************************** */}
              {/* ********** 접근권한 ********** */}
              {/* ***************************** */}
              <DetailPageForm
                style={{ alignItems: "flex-start", marginBottom: "79px" }}
              >
                <DetailFormTitle>접근권한*</DetailFormTitle>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "22px",
                  }}
                >
                  {/* 사용자 관리 */}
                  <div>
                    <CheckboxListTitle>사용자 관리</CheckboxListTitle>
                    <div style={{ display: "flex", gap: "20px" }}>
                      <AuthorityCheckbox
                        checked={managerInfo.accessMenu.includes(
                          route.user_common_member
                        )}
                        onChange={(checked) => {
                          if (checked) {
                            setManagerInfo({
                              ...managerInfo,
                              accessMenu: [
                                ...managerInfo.accessMenu,
                                route.user_common_member,
                              ],
                            });
                          } else {
                            setManagerInfo({
                              ...managerInfo,
                              accessMenu: managerInfo.accessMenu.filter(
                                (item) => item !== route.user_common_member
                              ),
                            });
                          }
                        }}
                        text={"일반회원 관리"}
                      />
                      <AuthorityCheckbox
                        checked={managerInfo.accessMenu.includes(
                          route.user_company_member
                        )}
                        onChange={(checked) => {
                          if (checked) {
                            setManagerInfo({
                              ...managerInfo,
                              accessMenu: [
                                ...managerInfo.accessMenu,
                                route.user_company_member,
                              ],
                            });
                          } else {
                            setManagerInfo({
                              ...managerInfo,
                              accessMenu: managerInfo.accessMenu.filter(
                                (item) => item !== route.user_company_member
                              ),
                            });
                          }
                        }}
                        text={"전문가 회원 관리"}
                      />

                      <AuthorityCheckbox
                        checked={managerInfo.accessMenu.includes(
                          route.user_leave_member
                        )}
                        onChange={(checked) => {
                          if (checked) {
                            setManagerInfo({
                              ...managerInfo,
                              accessMenu: [
                                ...managerInfo.accessMenu,
                                route.user_leave_member,
                              ],
                            });
                          } else {
                            setManagerInfo({
                              ...managerInfo,
                              accessMenu: managerInfo.accessMenu.filter(
                                (item) => item !== route.user_leave_member
                              ),
                            });
                          }
                        }}
                        text={"탈퇴 회원 관리"}
                      />
                    </div>
                  </div>

                  {/* 서비스 관리 영역 */}
                  <div>
                    <CheckboxListTitle>서비스 관리</CheckboxListTitle>
                    <div style={{ display: "flex", gap: "20px" }}>
                      <AuthorityCheckbox
                        checked={managerInfo.accessMenu.includes(
                          route.service_company
                        )}
                        onChange={(checked) => {
                          if (checked) {
                            setManagerInfo({
                              ...managerInfo,
                              accessMenu: [
                                ...managerInfo.accessMenu,
                                route.service_company,
                              ],
                            });
                          } else {
                            setManagerInfo({
                              ...managerInfo,
                              accessMenu: managerInfo.accessMenu.filter(
                                (item) => item !== route.service_company
                              ),
                            });
                          }
                        }}
                        text={"전문가 서비스 관리"}
                      />

                      <AuthorityCheckbox
                        checked={managerInfo.accessMenu.includes(
                          route.service_delete
                        )}
                        onChange={(checked) => {
                          if (checked) {
                            setManagerInfo({
                              ...managerInfo,
                              accessMenu: [
                                ...managerInfo.accessMenu,
                                route.service_delete,
                              ],
                            });
                          } else {
                            setManagerInfo({
                              ...managerInfo,
                              accessMenu: managerInfo.accessMenu.filter(
                                (item) => item !== route.service_delete
                              ),
                            });
                          }
                        }}
                        text={"삭제된 서비스 관리"}
                      />

                      <AuthorityCheckbox
                        checked={managerInfo.accessMenu.includes(
                          route.service_marketing
                        )}
                        onChange={(checked) => {
                          if (checked) {
                            setManagerInfo({
                              ...managerInfo,
                              accessMenu: [
                                ...managerInfo.accessMenu,
                                route.service_marketing,
                              ],
                            });
                          } else {
                            setManagerInfo({
                              ...managerInfo,
                              accessMenu: managerInfo.accessMenu.filter(
                                (item) => item !== route.service_marketing
                              ),
                            });
                          }
                        }}
                        text={"마케팅 종류 관리"}
                      />
                    </div>
                  </div>

                  {/* 결제 관리 영역 */}
                  <div>
                    <CheckboxListTitle>결제 관리</CheckboxListTitle>
                    <div style={{ display: "flex", gap: "20px" }}>
                      <AuthorityCheckbox
                        checked={managerInfo.accessMenu.includes(
                          route.payment_history
                        )}
                        onChange={(checked) => {
                          if (checked) {
                            setManagerInfo({
                              ...managerInfo,
                              accessMenu: [
                                ...managerInfo.accessMenu,
                                route.payment_history,
                              ],
                            });
                          } else {
                            setManagerInfo({
                              ...managerInfo,
                              accessMenu: managerInfo.accessMenu.filter(
                                (item) => item !== route.payment_history
                              ),
                            });
                          }
                        }}
                        text={"결제 내역 관리"}
                      />

                      <AuthorityCheckbox
                        checked={managerInfo.accessMenu.includes(
                          route.payment_cancel
                        )}
                        onChange={(checked) => {
                          if (checked) {
                            setManagerInfo({
                              ...managerInfo,
                              accessMenu: [
                                ...managerInfo.accessMenu,
                                route.payment_cancel,
                              ],
                            });
                          } else {
                            setManagerInfo({
                              ...managerInfo,
                              accessMenu: managerInfo.accessMenu.filter(
                                (item) => item !== route.payment_cancel
                              ),
                            });
                          }
                        }}
                        text={"취소/환불 내역 관리"}
                      />

                      <AuthorityCheckbox
                        checked={managerInfo.accessMenu.includes(
                          route.payment_request
                        )}
                        onChange={(checked) => {
                          if (checked) {
                            setManagerInfo({
                              ...managerInfo,
                              accessMenu: [
                                ...managerInfo.accessMenu,
                                route.payment_request,
                              ],
                            });
                          } else {
                            setManagerInfo({
                              ...managerInfo,
                              accessMenu: managerInfo.accessMenu.filter(
                                (item) => item !== route.payment_request
                              ),
                            });
                          }
                        }}
                        text={"정산 요청 관리"}
                      />
                    </div>
                  </div>

                  {/* 채팅 관리 영역 */}
                  <div>
                    <CheckboxListTitle>채팅 관리</CheckboxListTitle>
                    <div style={{ display: "flex", gap: "20px" }}>
                      <AuthorityCheckbox
                        checked={managerInfo.accessMenu.includes(
                          route.chat_history
                        )}
                        onChange={(checked) => {
                          if (checked) {
                            setManagerInfo({
                              ...managerInfo,
                              accessMenu: [
                                ...managerInfo.accessMenu,
                                route.chat_history,
                              ],
                            });
                          } else {
                            setManagerInfo({
                              ...managerInfo,
                              accessMenu: managerInfo.accessMenu.filter(
                                (item) => item !== route.chat_history
                              ),
                            });
                          }
                        }}
                        text={"채팅 내역 관리"}
                      />

                      <AuthorityCheckbox
                        checked={managerInfo.accessMenu.includes(
                          route.chat_report
                        )}
                        onChange={(checked) => {
                          if (checked) {
                            setManagerInfo({
                              ...managerInfo,
                              accessMenu: [
                                ...managerInfo.accessMenu,
                                route.chat_report,
                              ],
                            });
                          } else {
                            setManagerInfo({
                              ...managerInfo,
                              accessMenu: managerInfo.accessMenu.filter(
                                (item) => item !== route.chat_report
                              ),
                            });
                          }
                        }}
                        text={"신고 내역 관리"}
                      />
                    </div>
                  </div>

                  {/* 큐레이션 관리 영역 */}
                  <div>
                    <CheckboxListTitle>큐레이션 관리</CheckboxListTitle>
                    <div style={{ display: "flex", gap: "20px" }}>
                      <AuthorityCheckbox
                        checked={managerInfo.accessMenu.includes(
                          route.curation_post
                        )}
                        onChange={(checked) => {
                          if (checked) {
                            setManagerInfo({
                              ...managerInfo,
                              accessMenu: [
                                ...managerInfo.accessMenu,
                                route.curation_post,
                              ],
                            });
                          } else {
                            setManagerInfo({
                              ...managerInfo,
                              accessMenu: managerInfo.accessMenu.filter(
                                (item) => item !== route.curation_post
                              ),
                            });
                          }
                        }}
                        text={"게시글 관리"}
                      />
                    </div>
                  </div>

                  {/* 일반 관리 영역 */}
                  <div>
                    <CheckboxListTitle>일반 관리</CheckboxListTitle>
                    <div
                      style={{
                        display: "flex",
                        gap: "20px",
                        marginBottom: 20,
                      }}
                    >
                      <AuthorityCheckbox
                        checked={managerInfo.accessMenu.includes(
                          route.general_alarm
                        )}
                        onChange={(checked) => {
                          if (checked) {
                            setManagerInfo({
                              ...managerInfo,
                              accessMenu: [
                                ...managerInfo.accessMenu,
                                route.general_alarm,
                              ],
                            });
                          } else {
                            setManagerInfo({
                              ...managerInfo,
                              accessMenu: managerInfo.accessMenu.filter(
                                (item) => item !== route.general_alarm
                              ),
                            });
                          }
                        }}
                        text={"알람 관리"}
                      />

                      <AuthorityCheckbox
                        checked={managerInfo.accessMenu.includes(
                          route.general_ask
                        )}
                        onChange={(checked) => {
                          if (checked) {
                            setManagerInfo({
                              ...managerInfo,
                              accessMenu: [
                                ...managerInfo.accessMenu,
                                route.general_ask,
                              ],
                            });
                          } else {
                            setManagerInfo({
                              ...managerInfo,
                              accessMenu: managerInfo.accessMenu.filter(
                                (item) => item !== route.general_ask
                              ),
                            });
                          }
                        }}
                        text={"1:1 문의 관리"}
                      />

                      <AuthorityCheckbox
                        checked={managerInfo.accessMenu.includes(
                          route.general_notice
                        )}
                        onChange={(checked) => {
                          if (checked) {
                            setManagerInfo({
                              ...managerInfo,
                              accessMenu: [
                                ...managerInfo.accessMenu,
                                route.general_notice,
                              ],
                            });
                          } else {
                            setManagerInfo({
                              ...managerInfo,
                              accessMenu: managerInfo.accessMenu.filter(
                                (item) => item !== route.general_notice
                              ),
                            });
                          }
                        }}
                        text={"공지사항 관리"}
                      />

                      <AuthorityCheckbox
                        checked={managerInfo.accessMenu.includes(
                          route.general_faq
                        )}
                        onChange={(checked) => {
                          if (checked) {
                            setManagerInfo({
                              ...managerInfo,
                              accessMenu: [
                                ...managerInfo.accessMenu,
                                route.general_faq,
                              ],
                            });
                          } else {
                            setManagerInfo({
                              ...managerInfo,
                              accessMenu: managerInfo.accessMenu.filter(
                                (item) => item !== route.general_faq
                              ),
                            });
                          }
                        }}
                        text={"자주 묻는 질문 관리"}
                      />
                    </div>
                    <div style={{ display: "flex", gap: "20px" }}>
                      <AuthorityCheckbox
                        checked={managerInfo.accessMenu.includes(
                          route.general_popup
                        )}
                        onChange={(checked) => {
                          if (checked) {
                            setManagerInfo({
                              ...managerInfo,
                              accessMenu: [
                                ...managerInfo.accessMenu,
                                route.general_popup,
                              ],
                            });
                          } else {
                            setManagerInfo({
                              ...managerInfo,
                              accessMenu: managerInfo.accessMenu.filter(
                                (item) => item !== route.general_popup
                              ),
                            });
                          }
                        }}
                        text={"팝업 관리"}
                      />

                      <AuthorityCheckbox
                        checked={managerInfo.accessMenu.includes(
                          route.general_terms
                        )}
                        onChange={(checked) => {
                          if (checked) {
                            setManagerInfo({
                              ...managerInfo,
                              accessMenu: [
                                ...managerInfo.accessMenu,
                                route.general_terms,
                              ],
                            });
                          } else {
                            setManagerInfo({
                              ...managerInfo,
                              accessMenu: managerInfo.accessMenu.filter(
                                (item) => item !== route.general_terms
                              ),
                            });
                          }
                        }}
                        text={"약관 관리"}
                      />

                      <AuthorityCheckbox
                        checked={managerInfo.accessMenu.includes(
                          route.general_open_source
                        )}
                        onChange={(checked) => {
                          if (checked) {
                            setManagerInfo({
                              ...managerInfo,
                              accessMenu: [
                                ...managerInfo.accessMenu,
                                route.general_open_source,
                              ],
                            });
                          } else {
                            setManagerInfo({
                              ...managerInfo,
                              accessMenu: managerInfo.accessMenu.filter(
                                (item) => item !== route.general_open_source
                              ),
                            });
                          }
                        }}
                        text={"오픈소스 관리"}
                      />

                      <AuthorityCheckbox
                        checked={managerInfo.accessMenu.includes(
                          route.general_basic
                        )}
                        onChange={(checked) => {
                          if (checked) {
                            setManagerInfo({
                              ...managerInfo,
                              accessMenu: [
                                ...managerInfo.accessMenu,
                                route.general_basic,
                              ],
                            });
                          } else {
                            setManagerInfo({
                              ...managerInfo,
                              accessMenu: managerInfo.accessMenu.filter(
                                (item) => item !== route.general_basic
                              ),
                            });
                          }
                        }}
                        text={"기본 관리"}
                      />
                    </div>
                  </div>

                  {/* 관리자 회원 관리 영역 */}
                  <div>
                    <CheckboxListTitle>관리자 회원 관리</CheckboxListTitle>
                    <div style={{ display: "flex", gap: "20px" }}>
                      <AuthorityCheckbox
                        checked={managerInfo.accessMenu.includes(route.manager)}
                        onChange={(checked) => {
                          if (checked) {
                            setManagerInfo({
                              ...managerInfo,
                              accessMenu: [
                                ...managerInfo.accessMenu,
                                route.manager,
                              ],
                            });
                          } else {
                            setManagerInfo({
                              ...managerInfo,
                              accessMenu: managerInfo.accessMenu.filter(
                                (item) => item !== route.manager
                              ),
                            });
                          }
                        }}
                        text={"관리자 회원 관리"}
                      />
                    </div>
                  </div>
                </div>
              </DetailPageForm>
            </div>
            <SaveBtn
              style={{ bottom: "40px", right: "40px" }}
              onClick={() => {
                setSaveModalOpen(true);
              }}
            />
          </DetailPage>
        </DetailPageLayout>
      </MainLayout>

      {saveModalOpen ? (
        <Modal
          title={modalMsg.update?.title}
          text={modalMsg.update?.content}
          setModalOpen={setSaveModalOpen}
          onClick={saveFunc}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default RootEdit;

const CheckboxWrapper = styled.div.attrs((props) => {})`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const PwEditBtn = styled.button.attrs((props) => {})`
  width: 338px;
  height: 48px;
  display: flex;
  padding: 13px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  border: 1px solid #b8bfc4;
  background-color: transparent;
  color: #808991;
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
`;

const CheckboxListTitle = styled(PretendardText).attrs((props) => {})`
  color: #808991;
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.039px;
  margin-bottom: 5px;
`;
