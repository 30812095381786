const prefix = "";

export const route = {
  default: prefix,
  login: prefix + "/login", // 로그인
  join: prefix + "/join", // 회원가입
  join_complete: prefix + "/join/complete", // 회원가입 완료

  // ------------------------------------------------------------------------------------------------- //
  // -------------------------------------------- 홈 영역 -------------------------------------------- //
  // ------------------------------------------------------------------------------------------------ //
  home: prefix + "/home",

  // ---------------------------------------------------------------------------------------------------------- //
  // -------------------------------------------- 사용자 관리 영역 -------------------------------------------- //
  // -------------------------------------------------------------------------------------------------------- //
  user: prefix + "/user",
  // 일반회원 관리
  user_common_member: prefix + "/user/common",
  user_common_member_detail: prefix + "/user/common/detail",
  user_common_member_edit: prefix + "/user/common/edit",
  // 전문가 회원 관리
  user_company_member: prefix + "/user/company",
  user_company_member_detail: prefix + "/user/company/detail",
  user_company_member_edit: prefix + "/user/company/edit",
  // 탈퇴 회원 관리
  user_leave_member: prefix + "/user/leave",

  // ---------------------------------------------------------------------------------------------------------- //
  // -------------------------------------------- 서비스 관리 영역 -------------------------------------------- //
  // -------------------------------------------------------------------------------------------------------- //
  service: prefix + "/service",
  // 전문가 서비스 관리
  service_company: prefix + "/service/company", // 전문가 서비스 관리 목록
  service_company_detail: prefix + "/service/company/detail", // 전문가 정보 상세
  service_company_service: prefix + "/service/company/service", // 서비스 관리 목록
  service_company_service_detail: prefix + "/service/company/service/detail", // 서비스 관리 상세
  service_company_service_edit: prefix + "/service/company/service/edit", // 서비스 관리 수정
  service_company_service_review: prefix + "/service/company/service/review", // 리뷰 관리
  // 삭제된 서비스 관리
  service_delete: prefix + "/service/delete", // 삭제된 서비스 관리 목록
  service_delete_detail: prefix + "/service/delete/detail", // 삭제된 서비스 상세
  service_delete_service: prefix + "/service/delete/service", // 서비스 관리 목록
  service_delete_service_detail: prefix + "/service/delete/service/detail", // 서비스 관리 상세
  service_delete_service_review: prefix + "/service/delete/service/review", // 리뷰 관리
  // 마케팅 종류 관리
  service_marketing: prefix + "/service/marketing",
  service_marketing_detail: prefix + "/service/marketing/detail",
  service_marketing_edit: prefix + "/service/marketing/edit",
  service_marketing_create: prefix + "/service/marketing/create",

  // -------------------------------------------------------------------------------------------------------- //
  // -------------------------------------------- 결제 관리 영역 -------------------------------------------- //
  // ------------------------------------------------------------------------------------------------------ //
  payment: prefix + "/payment",
  // 결제 수단 관리
  payment_method: prefix + "/payment/method",
  // 결제 내역 관리
  payment_history: prefix + "/payment/history",
  payment_history_detail: prefix + "/payment/history/detail",
  // 취소/환불 내역 관리
  payment_cancel: prefix + "/payment/cancel",
  payment_cancel_detail: prefix + "/payment/cancel/detail",
  // 정산 요청 관리
  payment_request: prefix + "/payment/request",
  payment_request_detail: prefix + "/payment/request/detail",

  // -------------------------------------------------------------------------------------------------------- //
  // -------------------------------------------- 채팅 관리 영역 -------------------------------------------- //
  // ------------------------------------------------------------------------------------------------------ //
  chat: prefix + "/chat",
  // 채팅 내역 관리
  chat_history: prefix + "/chat/history",
  chat_history_detail: prefix + "/chat/history/detail",
  // 신고 내역 관리
  chat_report: prefix + "/chat/report",

  // ------------------------------------------------------------------------------------------------------------ //
  // -------------------------------------------- 큐레이션 관리 영역 -------------------------------------------- //
  // --------------------------------------------------------------------------------------------------------- //
  curation: prefix + "/curation",
  // 게시글 관리
  curation_post: prefix + "/curation/post",
  curation_post_detail: prefix + "/curation/post/detail",
  curation_post_edit: prefix + "/curation/post/edit",
  curation_post_create: prefix + "/curation/post/create",

  // -------------------------------------------------------------------------------------------------------- //
  // -------------------------------------------- 일반 관리 영역 -------------------------------------------- //
  // ------------------------------------------------------------------------------------------------------ //
  general: prefix + "/general",
  // 알림 관리
  general_alarm: prefix + "/general/alarm",
  general_alarm_detail: prefix + "/general/alarm/detail",
  general_alarm_create: prefix + "/general/alarm/create",
  // 1:1 문의 관리
  general_ask: prefix + "/general/ask",
  // 공지사항 관리
  general_notice: prefix + "/general/notice",
  general_notice_detail: prefix + "/general/notice/detail",
  general_notice_create: prefix + "/general/notice/create",
  // 자주 묻는 질문 관리
  general_faq: prefix + "/general/faq",
  general_faq_detail: prefix + "/general/faq/detail",
  general_faq_edit: prefix + "/general/faq/edit",
  general_faq_create: prefix + "/general/faq/create",
  // 팝업 관리
  general_popup: prefix + "/general/popup",
  general_popup_detail: prefix + "/general/popup/detail",
  general_popup_edit: prefix + "/general/popup/edit",
  general_popup_create: prefix + "/general/popup/create",
  // 약관 관리
  general_terms: prefix + "/general/terms",
  general_terms_detail: prefix + "/general/terms/detail",
  general_terms_edit: prefix + "/general/terms/edit",
  // 오픈소스 관리
  general_open_source: prefix + "/general/openSource",
  general_open_source_detail: prefix + "/general/openSource/detail",
  general_open_source_edit: prefix + "/general/openSource/edit",
  // 기본 관리
  general_basic: prefix + "/general/basic",
  general_basic_edit: prefix + "/general/basic/edit",

  // -------------------------------------------------------------------------------------------------------------- //
  // -------------------------------------------- 관리자 회원 관리 영역 -------------------------------------------- //
  // ------------------------------------------------------------------------------------------------------------ //
  manager: prefix + "/manager",
  manager_detail: prefix + "/manager/detail",
  manager_edit_root: prefix + "/manager/edit/root",
  manager_edit_admin: prefix + "/manager/edit/admin",
};
