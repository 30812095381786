import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Header from "../../../component/organisms/header";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Sidebar from "../../../component/organisms/sidebar";
import DetailPageLayout from "../../../component/atoms/layout/detail/detailPageLayout";
import { route } from "../../../router/route";
import TableTitle from "../../../component/atoms/text/table/tableTitle";
import TableTop from "../../../component/atoms/layout/table/tableTop";
import TableCount from "../../../component/molecules/tableCount";
import TableSearchBtn from "../../../component/atoms/layout/table/tableSearchBtn";
import SearchBar from "../../../component/atoms/input/searchBar";
import Table from "../../../component/atoms/layout/table/table";
import Tr from "../../../component/atoms/layout/table/tr";
import Th from "../../../component/atoms/layout/table/th";
import Checkbox from "../../../component/atoms/input/checkbox";
import TableText from "../../../component/atoms/text/table/tableText";
import Td from "../../../component/atoms/layout/table/td";
import TableFilter from "../../../component/atoms/layout/table/tableFilter";
import DetailBtn from "../../../component/atoms/button/detailBtn";
import Pagination from "../../../component/molecules/pagination";
import DelBtn from "./../../../component/atoms/button/delBtn";
import TableDelBtn from "../../../component/atoms/button/tableDelBtn";
import AddBtn from "./../../../component/atoms/button/addBtn";
import TermsApi from "./../../../api/terms.api";
import { errorHandler } from "../../../util/errorHandler";
import moment from "moment";

const GeneralTerms = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  // ----------------------------------- //
  // ---------- 마운트가 되면 ---------- //
  // --------------------------------- //
  useEffect(() => {
    setKeyword(searchParams.get("keyword") ? searchParams.get("keyword") : "");
    getTermsList();
  }, [location]);

  // ----- 목록 가져오기
  const page = useMemo(() => {
    return searchParams.get("page") ? searchParams.get("page") : 1;
  }, [searchParams]);

  const [pagination, setPagination] = useState({
    size: 10, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈
    totalCount: 0,
    totalPages: 1,
    first: false,
    last: false,
  });

  const [termsList, setTermsList] = useState([]);
  const [termCount, setTermCount] = useState(0);
  const [keyword, setKeyword] = useState("");

  const getTermsList = async () => {
    try {
      const data = {
        keyword: searchParams.get("keyword"),
        page: searchParams.get("page"),
        size: pagination.size,
      };
      let responseData = (await TermsApi.getList(data)).data.data;
      setTermCount(responseData.totalCount);
      setTermsList(
        responseData.content.map((item) => {
          return {
            ...item,
            checked: false,
          };
        })
      );
      setPagination({
        ...pagination,
        totalCount: responseData.totalCount ? responseData.totalCount : 0,
        totalPages: responseData.totalPage
          ? responseData.totalPage
          : Math.ceil(responseData.totalCount / 10),
        first: responseData.isFirst ? responseData.isFirst : false,
        last: responseData.isLast ? responseData.isLast : false,
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <div style={{ marginTop: "40px" }}>
            <TableTitle>약관 관리</TableTitle>
            <TableTop>
              <TableCount text={"항목"} count={termCount} />
            </TableTop>
            <Table>
              <colgroup>
                {/* 약관 종류 */}
                <col width="737px" />
                {/* 마지막 수정일 */}
                <col width="144px" />
                {/* 상세보기 */}
                <col width="75px" />
              </colgroup>
              <thead>
                <Tr style={{ height: "41px", background: "#f2f4f5" }}>
                  <Th style={{ paddingLeft: "90px" }}>
                    <TableText>약관 종류</TableText>
                  </Th>
                  <Th>
                    <TableText>마지막 수정일</TableText>
                  </Th>
                  <Th>
                    <TableText>상세보기</TableText>
                  </Th>
                </Tr>
              </thead>
              <tbody>
                {termsList.map((item, index) => {
                  return (
                    <Tr
                      key={index}
                      style={{
                        height: "59px",
                        background: "#fff",
                      }}
                    >
                      <Td>
                        <TableText
                          style={{
                            paddingLeft: "90px",
                          }}
                        >
                          {item.title}
                        </TableText>
                      </Td>
                      <Td>
                        <TableText>
                          {moment(item.updatedAt).format("YYYY/MM/DD")}
                        </TableText>
                      </Td>
                      <Td>
                        <DetailBtn
                          onClick={() => {
                            navigate(
                              route.general_terms_detail + `/${item.id}`
                            );
                          }}
                        />
                      </Td>
                    </Tr>
                  );
                })}
              </tbody>
            </Table>
            <Pagination
              route={route.general_terms}
              queryString={`keyword=${searchParams.get("keyword") || ""}`}
              currentPage={page}
              totalPages={pagination.totalPages}
              rangeSize={pagination.rangeSize}
              first={pagination.first}
              last={pagination.last}
            />
          </div>
        </DetailPageLayout>
      </MainLayout>
    </>
  );
};

export default GeneralTerms;
