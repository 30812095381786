import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./../../../component/organisms/header";
import MainLayout from "./../../../component/atoms/layout/mainLayout";
import Sidebar from "./../../../component/organisms/sidebar";
import DetailPageLayout from "./../../../component/atoms/layout/detail/detailPageLayout";
import DetailTop from "./../../../component/atoms/layout/detail/detailTop";
import GoBack from "./../../../component/molecules/goBack";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import DetailPageTitle from "./../../../component/atoms/text/detail/detailPageTitle";
import DetailPageForm from "./../../../component/atoms/layout/detail/detailPageForm";
import DetailFormTitle from "./../../../component/atoms/text/detail/detailFormTitle";
import { toast } from "react-toastify";
import { toastMsg } from "../../../util/toastMsg";
import Modal from "../../../component/templates/Modal";
import { modalMsg } from "../../../util/modalMsg";
import MainInput from "./../../../component/atoms/input/mainInput";
import SaveBtn from "./../../../component/atoms/button/saveBtn";
import { errorHandler } from "../../../util/errorHandler";
import BasicApi from "../../../api/basic.api";

const GeneralBasicEdit = () => {
  // mounted
  useEffect(() => {
    getBasicInfo();
  }, []);

  const getBasicInfo = async () => {
    try {
      let responseData = (await BasicApi.getBasicInfo()).data.data.content;
      setGeneralBasicInfo(responseData);
    } catch (error) {
      errorHandler(error);
    }
  };

  const [generalBasicInfo, setGeneralBasicInfo] = useState({});

  const navigate = useNavigate();

  const [saveModalOpen, setSaveModalOpen] = useState(false);

  const saveBtnHandler = () => {
    setSaveModalOpen(true);
  };

  const saveFunc = async () => {
    const data = {
      companyName: generalBasicInfo.companyName,
      ceo: generalBasicInfo.ceo,
      companyNumber: generalBasicInfo.companyNumber,
      reportNumber: generalBasicInfo.reportNumber,
      address: generalBasicInfo.address,
      tel: generalBasicInfo.tel,
      email: generalBasicInfo.email,
    };
    try {
      await BasicApi.updateBasicInfo(data);
    } catch (error) {
      errorHandler(error);
    }
    setSaveModalOpen(false);
    navigate(-1);
    toast(toastMsg.update);
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
          </DetailTop>
          <DetailPage style={{ minHeight: "307px" }}>
            <DetailPageTitle>기본정보</DetailPageTitle>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "20px" }}
            >
              {/* 상호명 */}
              <DetailPageForm>
                <DetailFormTitle>상호명*</DetailFormTitle>
                <MainInput
                  style={{
                    width: 338,
                  }}
                  type={"text"}
                  placeholder={"상호명을 입력해주세요"}
                  value={generalBasicInfo.companyName}
                  onChange={(e) =>
                    setGeneralBasicInfo({
                      ...generalBasicInfo,
                      companyName: e.target.value,
                    })
                  }
                />
              </DetailPageForm>

              {/* 대표자 */}
              <DetailPageForm>
                <DetailFormTitle>대표자*</DetailFormTitle>
                <MainInput
                  style={{
                    width: 338,
                  }}
                  type={"text"}
                  placeholder={"대표자명을 입력해주세요"}
                  value={generalBasicInfo.ceo}
                  onChange={(e) =>
                    setGeneralBasicInfo({
                      ...generalBasicInfo,
                      ceo: e.target.value,
                    })
                  }
                />
              </DetailPageForm>

              {/* 사업자등록번호 */}
              <DetailPageForm>
                <DetailFormTitle>사업자등록번호*</DetailFormTitle>
                <MainInput
                  style={{
                    width: 338,
                  }}
                  type={"text"}
                  placeholder={"사업자등록번호을 입력해주세요"}
                  value={generalBasicInfo.companyNumber}
                  onChange={(e) =>
                    setGeneralBasicInfo({
                      ...generalBasicInfo,
                      companyNumber: e.target.value,
                    })
                  }
                />
              </DetailPageForm>

              {/* 통신판매업신고번호 */}
              <DetailPageForm>
                <DetailFormTitle>통신판매업신고번호*</DetailFormTitle>
                <MainInput
                  style={{
                    width: 338,
                  }}
                  type={"text"}
                  placeholder={"통신판매업신고번호를 입력해주세요"}
                  value={generalBasicInfo.reportNumber}
                  onChange={(e) =>
                    setGeneralBasicInfo({
                      ...generalBasicInfo,
                      reportNumber: e.target.value,
                    })
                  }
                />
              </DetailPageForm>

              {/* 주소 */}
              <DetailPageForm>
                <DetailFormTitle>주소*</DetailFormTitle>
                <MainInput
                  style={{
                    width: 338,
                  }}
                  type={"text"}
                  placeholder={"주소를 입력해주세요"}
                  value={generalBasicInfo.address}
                  onChange={(e) =>
                    setGeneralBasicInfo({
                      ...generalBasicInfo,
                      address: e.target.value,
                    })
                  }
                />
              </DetailPageForm>

              {/* 전화번호 */}
              <DetailPageForm>
                <DetailFormTitle>전화번호*</DetailFormTitle>
                <MainInput
                  style={{
                    width: 338,
                  }}
                  type={"text"}
                  placeholder={"전화번호를 입력해주세요"}
                  value={generalBasicInfo.tel}
                  onChange={(e) =>
                    setGeneralBasicInfo({
                      ...generalBasicInfo,
                      tel: e.target.value,
                    })
                  }
                />
              </DetailPageForm>

              {/* 이메일 */}
              <DetailPageForm>
                <DetailFormTitle>이메일*</DetailFormTitle>
                <MainInput
                  style={{
                    width: 338,
                  }}
                  type={"text"}
                  placeholder={"이메일을 입력해주세요"}
                  value={generalBasicInfo.email}
                  onChange={(e) =>
                    setGeneralBasicInfo({
                      ...generalBasicInfo,
                      email: e.target.value,
                    })
                  }
                />
              </DetailPageForm>
            </div>

            <SaveBtn
              style={{ bottom: "40px", right: "40px" }}
              onClick={() => {
                saveBtnHandler();
              }}
            />
          </DetailPage>
        </DetailPageLayout>
      </MainLayout>

      {saveModalOpen ? (
        <Modal
          title={modalMsg.update?.title}
          text={modalMsg.update?.content}
          setModalOpen={setSaveModalOpen}
          onClick={() => {
            saveFunc();
          }}
        />
      ) : null}
    </>
  );
};

export default GeneralBasicEdit;
