import api from "./api.js";

export default class AlarmApi {
  static async create(data) {
    return await api.post(`admin/alarm`, data);
  }
  static async getList(params) {
    return await api.get(`admin/alarms`, params);
  }
  static async getById(alarmId, params) {
    return await api.get(`admin/alarm/${alarmId}`, params);
  }
  static async delete(data) {
    return await api.del(`alarm/notices`, data);
  }
}
