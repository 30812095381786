import api from "./api.js";

export default class ChatApi {
  static async getList(params) {
    return await api.get(`admin/chats`, params);
  }
  static async getById(chatId, params) {
    return await api.get(`admin/chat/${chatId}`, params);
  }
  static async delete(data) {
    return await api.del(`admin/chats`, data);
  }
}
